import React, { FC } from 'react';
import { VirtualizedListChildrenProps } from '@shared/modules/range';
import { Zone } from '@modules/zones/model';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Card, Group, Stack, Title } from '@mantine/core';
import Type = Zone.Type;
import { IconGolf, IconTree } from '@tabler/icons-react';

const ZonesListRow: FC<VirtualizedListChildrenProps<Zone.Range.Item>> = ({ item }) => {
  return (
    <Card component={Link} to={item.id} withBorder={false}>
      <Group spacing="xs" noWrap>
        {item.type === Type.Game ? (
          <Avatar color="blue.8" radius="xl">
            <IconGolf stroke={1.5} />
          </Avatar>
        ) : (
          <Avatar color="green.7" radius="xl">
            <IconTree stroke={1.5} />
          </Avatar>
        )}
        <Stack spacing={8}>
          <Title size="h3">{item.name}</Title>
          <Badge color="gray" w="fit-content" tt="none" fw="normal">
            {Zone.typeLabel[item.type]}
          </Badge>
        </Stack>
      </Group>
    </Card>
  );
};

export default ZonesListRow;

import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as ZonesService from '@modules/zones/service';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { Zone } from '@modules/zones/model';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import ZoneForm from '@modules/zones/components/form/ZoneForm';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { zoneFormBodySchema, zoneParamsSchema } from '@modules/zones/schema';
import { defineAction, useAction } from '@core/router/action';
import { Group } from '@mantine/core';
import { SharedButton } from '@styles/shared/Button';
import * as EI from 'fp-ts/Either';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { getZoneInitialProps, mapZoneFormBodyToParams } from '@modules/zones/utils';
import { useHasRole } from '@modules/auth/hooks';
import { CompanyUserRole } from '@modules/auth/model';

const zoneIdSchema = z.object({ id: createNewTypeStringSchema<Zone.Id>() });

const loader = defineLoader({
  params: zoneIdSchema,
  handler: ({ params }) => httpTaskToResponseTask(ZonesService.getZone(params.id)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params: zoneIdSchema,
    payload: zoneParamsSchema,
    handler: ({ payload, params }) => ZonesService.updateZone(params.id, payload),
    flashOptions: {
      success: () => "Zone d'entretien modifiée avec succès",
    },
  }),
  delete: defineAction({
    type: 'delete',
    params: zoneIdSchema,
    handler: ({ params }) => ZonesService.deleteZone(params.id),
    flashOptions: {
      success: () => "Zone d'entretien supprimée avec succès",
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/zones' : null),
  }),
};

const ZoneDetailPage: FC = () => {
  const zone = useLoader<typeof loader>();
  const isAdmin = useHasRole(CompanyUserRole.Admin);
  const [updateLoading, updateZone] = useAction(actions.update);
  const [deleteLoading, deleteZone] = useAction(actions.delete);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Zone.FormBody>({
    resolver: zodResolver(zoneFormBodySchema),
    defaultValues: getZoneInitialProps(zone),
  });

  const pageProps: PageProps = {
    seoTitle: "Votre zone d'entretien",
    back: {
      title: "Votre zone d'entretien",
      to: '/zones',
    },
    bottomBar: isAdmin ? (
      <Group position="center" spacing={8}>
        <DeleteModal onDelete={deleteZone} loading={deleteLoading} />
        <SharedButton btnType="save" loading={updateLoading} onClick={handleFormSubmit}>
          Enregistrer
        </SharedButton>
      </Group>
    ) : undefined,
  };

  const handleUpdateZone = (params: Zone.FormBody) => () => updateZone(mapZoneFormBodyToParams(params));

  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateZone} preventLeave>
        <ZoneForm readonly={!isAdmin} />
      </EnhancedForm>
    </Page>
  );
};

const zoneDetailRoute = defineRoute({
  component: ZoneDetailPage,
  loader,
  actions,
});

export default zoneDetailRoute;

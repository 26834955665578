import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import metersListRoute from '@modules/water/meters/ListPage';
import createMeterRoute from '@modules/water/meters/CreatePage';
import meterSuccessRoute from '@modules/water/meters/SuccessPage';
import meterDetailRoute from '@modules/water/meters/DetailPage';
import ProtectedRoutes from '@core/router/components/ProtectedRoutes';
import { CompanyUserRole } from '@modules/auth/model';

const metersRoutes = (
  <Route path="meters">
    <Route index {...createRoute(metersListRoute)} />
    <Route path=":id" {...createRoute(meterDetailRoute)} />
    <Route path="success" {...createRoute(meterSuccessRoute)} />
    <Route element={<ProtectedRoutes roles={[CompanyUserRole.Admin]} />}>
      <Route path="new" {...createRoute(createMeterRoute)} />
    </Route>
  </Route>
);

export default metersRoutes;

import { MantineProvider, rem } from '@mantine/core';
import React, { FC, PropsWithChildren } from 'react';
import { MantineThemeOverride } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { rgba } from 'polished';
import { IconCalendar, IconCheck } from '@tabler/icons-react';
import 'dayjs/locale/fr';
import { Notifications } from '@mantine/notifications';

const MODAL_TRANSITION_DURATION = 300;
const NUMBER_PRECISION = 3;

const theme: MantineThemeOverride = {
  fontFamily: 'Balto',
  primaryColor: 'green',
  colors: {
    blue: [
      '#e7f5ff',
      '#d0ebff',
      '#c1cfec',
      '#74c0fc',
      '#4dabf7',
      '#339af0',
      '#228be6',
      '#1c7ed6',
      '#004c99',
      '#003361',
    ],
    green: [
      '#edf8ef',
      '#d3f9d8',
      '#b2f2bb',
      '#8ce99a',
      '#69db7c',
      '#51cf66',
      '#40c057',
      '#339e37',
      '#2f9e44',
      '#2b8a3e',
    ],
    background: ['#ffffff', '#f8f5f5', '#fdf9f4', '#f5f4f2', '#ebeae7'],
  },
  headings: {
    fontFamily: 'Balto',
    fontWeight: 500,
    sizes: {
      h1: {
        fontSize: rem(24),
        lineHeight: 'normal',
      },
      h2: {
        fontSize: rem(22),
        lineHeight: 'normal',
      },
      h3: {
        fontSize: rem(18),
        lineHeight: 'normal',
      },
      h4: {
        fontSize: rem(16),
        lineHeight: 'normal',
      },
      h5: {
        fontSize: rem(14),
        lineHeight: 'normal',
      },
      h6: {
        fontSize: rem(12),
        lineHeight: 'normal',
      },
    },
  },
  components: {
    Accordion: {
      defaultProps: {
        radius: 8,
      },
      styles: {
        item: {
          border: 'none',
        },
      },
    },
    Checkbox: {
      defaultProps: {
        color: 'green.7',
        radius: 8,
        size: 'md',
      },
    },
    Stepper: {
      defaultProps: {
        color: 'blue.2',
        completedIcon: <IconCheck color="#043361" strokeWidth={1.5} />,
        iconSize: 38,
      },
      styles: theme => ({
        steps: {
          background: theme.colors.blue[9],
          padding: '0 20px 20px',
        },
        content: {
          paddingTop: 0,
        },
        stepIcon: {
          background: rgba(theme.colors.gray[1], 0.15),
          color: rgba(theme.colors.background[0], 0.4),
          fontWeight: 500,
          fontSize: theme.fontSizes.sm,
          border: 'none',

          '&[data-progress]': {
            border: `2px solid ${theme.colors.background[0]}`,
            background: theme.colors.blue[9],
            color: theme.colors.background[0],
          },
        },
      }),
    },
    RadioGroup: {
      defaultProps: {
        size: 'md',
        spacing: 'xl',
      },
    },
    Radio: {
      defaultProps: {
        color: 'green.7',
      },
    },
    DatePickerInput: {
      defaultProps: {
        valueFormat: 'DD/MM/YYYY',
        locale: 'fr',
        size: 'md',
        radius: 'md',
        icon: <IconCalendar size={18} />,
        allowSingleDateInRange: true,
      },
    },
    YearPickerInput: {
      defaultProps: {
        size: 'md',
        radius: 'md',
      },
    },
    Textarea: {
      defaultProps: {
        radius: 'md',
        size: 'md',
      },
    },
    Card: {
      defaultProps: {
        radius: 10,
      },
    },
    NumberInput: {
      defaultProps: {
        precision: NUMBER_PRECISION,
        size: 'md',
        decimalSeparator: ',',
        removeTrailingZeros: true,
        inputMode: 'decimal',
        inputWrapperOrder: ['label', 'input', 'error', 'description'],
      },
    },
    Button: {
      defaultProps: {
        radius: 8,
        fw: 500,
        color: 'green.7',
      },
      styles: theme => ({
        label: {
          fontSize: theme.fontSizes.md,
        },
      }),
    },
    Select: {
      defaultProps: {
        size: 'md',
        radius: 8,
        dropdownPosition: 'bottom',
      },
    },
    MultiSelect: {
      defaultProps: {
        size: 'md',
        radius: 8,
        dropdownPosition: 'bottom',
      },
    },
    Tabs: {
      styles: theme => ({
        tabsList: {
          background: theme.colors.gray[2],
          borderRadius: 22,
          padding: 4,
          gap: theme.spacing.xs,
          border: 'none',
          flexWrap: 'nowrap',
          alignItems: 'center',
        },
        tab: {
          fontWeight: 500,
          fontSize: theme.fontSizes.md,
          flexBasis: '100%',
          border: 'none',
          margin: 0,
          color: rgba(theme.colors.dark[8], 0.6),
          ':hover': {
            borderRadius: 22,
          },
          '&[data-active]': {
            background: theme.white,

            borderRadius: 22,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
          },
        },
      }),
    },
    NavLink: {
      defaultProps: {
        // typed by @shared/Link
        component: NavLink,
      },
    },
    Text: {
      defaultProps: theme => ({
        color: theme.colors.dark[8],
        component: 'p',
        my: 0,
      }),
    },
    InputWrapper: {
      styles: theme => ({
        label: {
          fontSize: theme.fontSizes.md,
          paddingBottom: 8,
          lineHeight: 1,
        },
        required: {
          color: theme.colors.dark[8],
        },
      }),
    },
    TextInput: {
      defaultProps: {
        size: 'md',
        radius: 8,
        inputWrapperOrder: ['label', 'input', 'error', 'description'],
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    },
    Modal: {
      styles: {
        root: {
          zIndex: 2000,
        },
      },
      defaultProps: theme => ({
        overlayColor: theme.colors.gray[2],
        overlayBlur: 2,
        transitionDuration: MODAL_TRANSITION_DURATION,
        exitTransitionDuration: MODAL_TRANSITION_DURATION,
      }),
    },
  },
};
const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Notifications position="bottom-right" />
      {children}
    </MantineProvider>
  );
};

export default MantineTheme;

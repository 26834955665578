import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import * as OperatorsService from '@modules/operators/service';
import * as EI from 'fp-ts/Either';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { Stack, Text } from '@mantine/core';
import { SharedButton } from '@styles/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { constNull, pipe } from 'fp-ts/function';
import { operatorSchema } from '@modules/operators/schema';
import { Operator } from '@modules/operators/model';
import OperatorForm from '@modules/operators/components/form/OperatorForm';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { stringifyQueries } from '@shared/utils/queries';
import { SharedLink } from '@styles/shared/Link';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(OperatorsService.getLeader()),
});

const actions = {
  create: defineAction({
    type: 'create',
    payload: operatorSchema,
    handler: ({ payload }) => OperatorsService.createOperator(payload),
    redirect: ({ result }) =>
      pipe(
        result,
        EI.fold(constNull, ({ lastName, firstName }) => ({
          pathname: '/operators/success',
          search: stringifyQueries({ lastName, firstName }),
        })),
      ),
  }),
};

const CreateOperatorPage: FC = () => {
  const leader = useLoader<typeof loader>();
  const [createLoading, createOperator] = useAction(actions.create);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Operator.Params>({
    resolver: zodResolver(operatorSchema),
    defaultValues: { role: Operator.Role.Member },
  });
  const handleCreateOperator = (params: Operator.Params) => () => createOperator(params);

  const pageProps: PageProps = {
    seoTitle: 'Nouvel opérateur',
    back: {
      title: 'Nouvel opérateur',
      to: '/operators',
    },
    bottomBar: (
      <Stack spacing={10} align="center">
        <SharedButton btnType="create" loading={createLoading} onClick={handleFormSubmit}>
          Créer
        </SharedButton>

        <SharedLink
          to="/operators"
          label={
            <Text color="dark.3" weight={500} size="md" td="underline">
              Annuler
            </Text>
          }
        />
      </Stack>
    ),
  };
  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateOperator} preventLeave>
        <OperatorForm leader={leader} />
      </EnhancedForm>
    </Page>
  );
};

const createOperatorRoute = defineRoute({
  component: CreateOperatorPage,
  loader,
  actions,
});

export default createOperatorRoute;

import React, { FC } from 'react';
import { SimpleGrid } from '@mantine/core';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Controller } from 'react-hook-form';
import { Intervention } from '@modules/interventions/model';
import SafeNumberInput from '@shared/components/number/SafeNumberInput';

type FormContext<Body extends Intervention.Create.FormBody = Intervention.Create.FormBody> = Body & {
  dosage: Extract<Body['dosage'], { treatmentType: Intervention.TreatmentType.Volume }>;
};
const InterventionVolumeTreatment: FC = () => {
  const {
    control,
    formState: { errors },
  } = useEnhancedFormContext<FormContext>();

  return (
    <SimpleGrid cols={2}>
      <Controller
        control={control}
        name="dosage.volumeValue"
        render={({ field }) => (
          <SafeNumberInput
            {...field}
            min={0}
            error={!!errors.dosage?.volumeValue}
            label="Volume à traiter"
            placeholder="Saisir"
            required
          />
        )}
      />
    </SimpleGrid>
  );
};

export default InterventionVolumeTreatment;

import { Newtype } from 'newtype-ts';
import z from 'zod';
import { reportSchema } from '@modules/reports/schema';

export namespace PowerBi {
  export type ExportJobId = Newtype<{ readonly EXPORT_JOB_ID: unique symbol }, string> & string;
  export enum Report {
    RapportEauNational = 'national-water',
    RapportEauGolf = 'golf-water',
    RapportIntrantNational = 'national-input',
    RapportIntrantGolf = 'golf-input',
    CahierPhyto = 'book-inputs',
    CahierInterventionXLS = 'book-intervention-xls',
    CahierIndexXLS = 'book-water-index',
  }
}

export namespace Report {
  export enum Code {
    Water = 'water',
    Input = 'input',
    InputBook = 'input-book',
    InterventionBook = 'intervention-book',
    WaterBook = 'water-book',
  }

  export const pageTitle: Record<Code, string> = {
    [Code.Water]: 'Rapport eau',
    [Code.Input]: 'Rapport intrants',
    [Code.WaterBook]: 'Cahier relevé compteurs',
    [Code.InterventionBook]: 'Cahier interventions',
    [Code.InputBook]: 'Registre phytosanitaire',
  };
  export interface Job {
    report: PowerBi.Report;
    jobId: PowerBi.ExportJobId;
  }

  export type Params = z.infer<typeof reportSchema>;
}

import React, { FC } from 'react';
import { Card, Title } from '@mantine/core';
import { renderNullable, renderOptional } from '@shared/utils/render';
import InputSimpleInfo from '@modules/inputs/components/InputSimpleInfo';
import { formatDate, parseDate } from '@shared/modules/dates';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { NonMfscInput } from '@modules/inputs/amm/non-mfsc/model';
import { constPlaceholder } from '@modules/inputs/amm/utils';
import { identity } from 'fp-ts/function';

interface NonMfscInputInfosProps {
  input: NonMfscInput;
}

const NonMfscInputUsage: FC<NonMfscInputInfosProps> = ({ input }) => {
  return (
    <>
      <Title size="h3" pb={10} pt={30}>
        Usage
      </Title>
      <Card py={0}>
        <InputSimpleInfo title="Culture">{input.crop}</InputSimpleInfo>
        <InputSimpleInfo title="Bioagresseur">{input.pest}</InputSimpleInfo>
        <InputSimpleInfo title="Date de décision">
          {renderOptional(parseDate(input.decisionDate), decisionDate => formatDate(decisionDate), constPlaceholder)}
        </InputSimpleInfo>
        <InputSimpleInfo title="Dose retenue">
          {renderOptional(
            sequenceS(O.Apply)({ dose: O.fromNullable(input.dose), doseUnit: O.fromNullable(input.doseUnit) }),
            ({ dose, doseUnit }) => `${dose} ${doseUnit}`,
            constPlaceholder,
          )}
        </InputSimpleInfo>
        <InputSimpleInfo title="Condition emploi">
          {renderNullable(input.usageCondition, identity, constPlaceholder)}
        </InputSimpleInfo>
        <InputSimpleInfo title="Nombre max d’application">
          {renderNullable(input.maxApplicationNumber, identity, constPlaceholder)}
        </InputSimpleInfo>
        <InputSimpleInfo title="ZNT aquatique (en m)">{input.zntWater}</InputSimpleInfo>
        <InputSimpleInfo title="ZNT plantes non cibles (en m)">
          {renderNullable(input.zntPlants, identity, constPlaceholder)}
        </InputSimpleInfo>
        <InputSimpleInfo title="ZNT arthropodes non cibles (en m)">
          {renderNullable(input.zntAthropods, identity, constPlaceholder)}
        </InputSimpleInfo>
      </Card>
    </>
  );
};

export default NonMfscInputUsage;

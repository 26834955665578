import { OpenData } from '@shared/modules/opendata/model';
import { AmmInput } from '@modules/inputs/amm/model';
import { CustomInput } from '@modules/inputs/custom/model';
import { z } from 'zod';

export namespace Input {
  export const Id = z.string().uuid().brand('InputId');
  export type Id = z.infer<typeof Id>;

  export const Name = z.string().brand('InputName');
  export type Name = z.infer<typeof Name>;

  export const NameId = z.string().uuid().brand('InputNameId');
  export type NameId = z.infer<typeof NameId>;

  export enum Source {
    AMM = 'amm',
    Custom = 'custom',
  }

  export enum State {
    Authorized = 'AUTORISE',
    Unauthorized = 'RETIRE',
  }

  export const Type = { ...CustomInput.Type, ...AmmInput.Type };
  export type Type = CustomInput.Type | AmmInput.Type;
  export const typeLabel: Record<Type, string> = {
    ...CustomInput.typeLabel,
    ...AmmInput.typeLabel,
  };

  export namespace View {
    export namespace Mfsc {
      export interface Usage {
        usageId: OpenData.MfscUsageId;
        unit: string | null;
      }
      export interface Crop {
        name: string;
        pests: Array<Usage>;
      }
    }
    export interface Name {
      id: NameId | null;
      value: Input.Name;
    }

    export interface Usage {
      usageId: OpenData.ProductUsageId;
      unit: string | null;
    }

    export interface Pest {
      name: string;
      usages: Array<Usage>;
    }

    export interface Crop {
      name: string;
      pests: Array<Pest>;
    }

    export interface Detail {
      inputId: Id;
      source: Source;
      nameId: NameId | null;
      names: Array<Name>;
      tradeLicence: AmmInput.TradeLicence | null;
      nonMfsc: Array<Crop>;
      mfsc: Array<Mfsc.Crop>;
    }
  }

  export interface View {
    inputId: Id;
    source: Source;
    name: Name;
    nameId: NameId | null;
    tradeLicenceId: OpenData.TradeLicence.Id | null;
    state: State;
  }

  export namespace SearchUsage {
    export interface Filter {
      source: Source;
      id: Id;
      nameId: NameId | null;
      tradeLicenceId: OpenData.TradeLicence.Id | null;
    }
  }

  export namespace Search {
    export interface Filter {
      search: string;
      authorizedOnly: boolean;
      limit: number;
    }

    export interface EmptyFilter extends Omit<Input.Search.Filter, 'search'> {
      search: string | null;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Input = AmmInput | CustomInput;

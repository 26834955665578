import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import zonesListRoute from '@modules/zones/ListPage';
import zoneDetailRoute from '@modules/zones/DetailPage';
import createZoneRoute from '@modules/zones/CreatePage';
import zoneSuccessRoute from '@modules/zones/SuccessPage';
import ProtectedRoutes from '@core/router/components/ProtectedRoutes';
import { CompanyUserRole } from '@modules/auth/model';

const zonesRoutes = (
  <Route path="zones">
    <Route index {...createRoute(zonesListRoute)} />
    <Route path=":id" {...createRoute(zoneDetailRoute)} />
    <Route path="success" {...createRoute(zoneSuccessRoute)} />
    <Route element={<ProtectedRoutes roles={[CompanyUserRole.Admin]} />}>
      <Route path="new" {...createRoute(createZoneRoute)} />
    </Route>
  </Route>
);

export default zonesRoutes;

import React, { FC, useMemo } from 'react';
import { Center, Modal, Title } from '@mantine/core';
import { SharedButton } from '@styles/shared';
import { useDisclosure } from '@mantine/hooks';
import { Intervention } from '@modules/interventions/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateInterventionUsageSchema } from '@modules/interventions/schema';
import { getUsageFormBodyFromDetail } from '@modules/interventions/utils';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import UpdateInterventionUsageForm from '@modules/interventions/components/form/usage/UpdateInterventionUsageForm';
import { HttpTask } from '@core/http';

interface UpdateInterventionInputModalProps {
  intervention: Intervention;
  loading: boolean;
  onUpdate: (params: Intervention.Update.FormBody.Usage) => HttpTask;
}

const UpdateInterventionUsageModal: FC<UpdateInterventionInputModalProps> = ({ intervention, loading, onUpdate }) => {
  const [opened, handlers] = useDisclosure(false);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Intervention.Update.FormBody.Usage>({
    defaultValues: useMemo(() => getUsageFormBodyFromDetail(intervention), [intervention]),
    resolver: zodResolver(updateInterventionUsageSchema),
  });

  return (
    <>
      <Center my="md">
        <SharedButton onClick={handlers.open} loading={loading}>
          Modifier
        </SharedButton>
      </Center>

      <Modal title={<Title>Usage</Title>} opened={opened} onClose={handlers.close} centered>
        <EnhancedForm ref={formRef} form={form} onSubmit={onUpdate}>
          <UpdateInterventionUsageForm />
        </EnhancedForm>

        <Center mt={15}>
          <SharedButton onClick={handleFormSubmit} loading={loading}>
            Enregistrer
          </SharedButton>
        </Center>
      </Modal>
    </>
  );
};

export default UpdateInterventionUsageModal;

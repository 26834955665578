import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as IndexesService from '@modules/water/indexes/service';
import { defineAction, useAction } from '@core/router/action';
import * as EI from 'fp-ts/Either';
import { Water } from '@modules/water/model';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import Page, { PageProps } from '@layout/page/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import { Group } from '@mantine/core';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { SharedButton } from '@styles/shared';
import { indexSchema } from './schema';
import IndexForm from '@modules/water/indexes/components/form/IndexForm';
import { indexErrorFlashHandler } from '@modules/water/indexes/utils';
import Index = Water.Index;

const indexIdSchema = z.object({ id: createNewTypeStringSchema<Index.Id>() });

const loader = defineLoader({
  params: indexIdSchema,
  handler: ({ params }) => httpTaskToResponseTask(IndexesService.getWaterIndex(params.id)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params: indexIdSchema,
    payload: indexSchema,
    handler: ({ payload, params }) => IndexesService.updateWaterIndex(params.id, payload),
    flashOptions: {
      success: () => 'Les modifications apportées à cet index ont été appliquées à l’historique de vos saisies',
      error: ({ error }) => indexErrorFlashHandler(error),
    },
  }),
  delete: defineAction({
    type: 'delete',
    params: indexIdSchema,
    handler: ({ params }) => IndexesService.deleteWaterIndex(params.id),
    flashOptions: {
      success: () => "L'index a bien été supprimé.",
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/water/indexes' : null),
  }),
};

const IndexDetailPage: FC = () => {
  const index = useLoader<typeof loader>();

  const [updateLoading, updateIndex] = useAction(actions.update);
  const [deleteLoading, deleteIndex] = useAction(actions.delete);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Index.Params>({
    resolver: zodResolver(indexSchema),
    defaultValues: {
      meterId: index.meterId,
      date: index.date,
      number: index.number,
      drynessLevel: index.drynessLevel,
    },
  });

  const pageProps: PageProps = {
    seoTitle: `Index ${index.number} m³`,
    back: {
      title: `Index ${index.number} m³`,
      to: '/water/indexes',
    },
    bottomBar: (
      <Group position="center" spacing={8}>
        <DeleteModal onDelete={deleteIndex} loading={deleteLoading} />
        <SharedButton btnType="save" loading={updateLoading} onClick={handleFormSubmit}>
          Enregistrer
        </SharedButton>
      </Group>
    ),
  };

  const handleUpdateIndex = (params: Index.Params) => () => updateIndex(params);

  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateIndex} preventLeave>
        <IndexForm index={index} />
      </EnhancedForm>
    </Page>
  );
};

const indexDetailRoute = defineRoute({
  component: IndexDetailPage,
  loader,
  actions,
});

export default indexDetailRoute;

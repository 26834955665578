import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';

import operatorsListRoute from '@modules/operators/ListPage';
import createOperatorRoute from '@modules/operators/CreatePage';
import operatorSuccessRoute from '@modules/operators/SuccessPage';
import operatorDetailRoute from '@modules/operators/DetailPage';
import ProtectedRoutes from '@core/router/components/ProtectedRoutes';
import { CompanyUserRole } from '@modules/auth/model';

const operatorsRoutes = (
  <Route path="operators">
    <Route index {...createRoute(operatorsListRoute)} />
    <Route path=":id" {...createRoute(operatorDetailRoute)} />
    <Route path="success" {...createRoute(operatorSuccessRoute)} />
    <Route element={<ProtectedRoutes roles={[CompanyUserRole.Admin]} />}>
      <Route path="new" {...createRoute(createOperatorRoute)} />
    </Route>
  </Route>
);

export default operatorsRoutes;

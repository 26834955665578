import React, { FC } from 'react';
import { ActionIcon, Button, Group, Stack, TextInput, ThemeIcon, Title } from '@mantine/core';
import { IconListDetails, IconSquarePlus, IconTrash } from '@tabler/icons-react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Controller, useFieldArray } from 'react-hook-form';
import { DatePickerInput } from '@mantine/dates';
import { DateFormat, formatDate, LocalDate, parseDate } from '@shared/modules/dates';
import { newTypeWrap } from '@shared/schemas';
import { Intervention } from '@modules/interventions/model';

const InterventionEquipments: FC = () => {
  const {
    control,
    formState: { errors },
    setValue,
    register,
  } = useEnhancedFormContext<Intervention.Create.FormBody>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'equipments',
  });

  const handleRemove = (index: number) => () => remove(index);

  const handleAppend = () => append({ label: '', maintenanceDate: null });

  const handleDateChange = (index: number) => (value: Date | null) =>
    setValue(
      `equipments.${index}.maintenanceDate`,
      formatDate(value, DateFormat.LocalDate, newTypeWrap<LocalDate>('')),
    );

  return (
    <Stack spacing="xs">
      <Group spacing="xs" pb="xs">
        <ThemeIcon color="blue.2" variant="light" bg="transparent">
          <IconListDetails />
        </ThemeIcon>
        <Title size="h3">Equipements</Title>
      </Group>
      {fields.map((field, index) => (
        <Group spacing="xs" noWrap key={index}>
          <TextInput
            {...register(`equipments.${index}.label`)}
            error={!!errors.equipments?.[index]?.label}
            label="Équipement"
            placeholder="Saisir"
            required
            style={{ flex: 1 }}
          />
          <Controller
            control={control}
            name={`equipments.${index}.maintenanceDate`}
            render={({ field }) => (
              <DatePickerInput
                {...field}
                error={!!errors.equipments?.[index]?.maintenanceDate}
                value={parseDate(field.value, DateFormat.LocalDate, null)}
                placeholder="Sélectionner"
                onChange={handleDateChange(index)}
                label="Date entretien"
                style={{ flex: 1 }}
              />
            )}
          />
          <ActionIcon
            mt="auto"
            size={42}
            radius={8}
            bg="red.1"
            onClick={handleRemove(index)}
            variant="light"
            color="red"
          >
            <IconTrash />
          </ActionIcon>
        </Group>
      ))}
      <div>
        <Button
          onClick={handleAppend}
          variant="light"
          color="green"
          leftIcon={
            <ThemeIcon color="green" variant="light" bg="transparent">
              <IconSquarePlus />
            </ThemeIcon>
          }
        >
          Ajouter
        </Button>
      </div>
    </Stack>
  );
};

export default InterventionEquipments;

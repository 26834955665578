import React, { FC, useEffect, useMemo } from 'react';
import { Group, MultiSelect, Select, Stack, TextInput } from '@mantine/core';
import { Zone } from '@modules/zones/model';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Controller } from 'react-hook-form';
import * as ZonesService from '@modules/zones/service';
import { useFetchTaskOption } from '@core/http/hooks';
import * as S from 'fp-ts/string';
import * as O from 'fp-ts/Option';
import * as IOO from 'fp-ts/IOOption';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { getZoneDetailNullable } from '@modules/interventions/components/form/dosage/service';
import { renderOptional } from '@shared/utils/render';
import { Intervention } from '@modules/interventions/model';
import * as NEA from 'fp-ts/NonEmptyArray';
import { selectItemOrd } from '@shared/components/select/model';
import { InputUnitDescription } from '@modules/interventions/components/form/dosage/InterventionDosageForm';

type FormContext<Body extends Intervention.Create.FormBody = Intervention.Create.FormBody> = Body & {
  dosage: Extract<Body['dosage'], { treatmentType: Intervention.TreatmentType.Zone }>;
};

interface InterventionZoneTreatmentProps {
  oldTotalZoneAreaValue?: number | null;
}

const InterventionZoneTreatment: FC<InterventionZoneTreatmentProps> = ({ oldTotalZoneAreaValue }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    register,
  } = useEnhancedFormContext<FormContext>();

  const zoneAreaTypes = watch('dosage.area.zoneAreaTypes');
  const zoneId = watch('dosage.area.zoneId');
  const totalZoneAreaValue = watch('dosage.totalZoneAreaValue');

  const [zones] = useFetchTaskOption(ZonesService.searchZone, null);

  const zonesData = pipe(
    zones,
    O.fold(
      () => [],
      A.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    ),
  );

  const [nullableAreasZone] = useFetchTaskOption(getZoneDetailNullable, watch('dosage.zoneId'));

  const zone = useMemo(
    () =>
      pipe(
        nullableAreasZone,
        O.filterMap(zone =>
          pipe(
            zone.type === Zone.Type.Game
              ? pipe(
                  NEA.fromArray(zone.areas.filter(({ value }) => value > 0)),
                  O.map(areas => ({ ...zone, areas })),
                )
              : zone.area.value > 0
              ? O.some<Zone>({ ...zone, area: zone.area })
              : O.none,
          ),
        ),
      ),
    [nullableAreasZone],
  );

  const totalDescription = pipe(
    O.fromNullable(oldTotalZoneAreaValue),
    O.filter(total => total !== totalZoneAreaValue),
    O.map(total => <InputUnitDescription key={total}>Ancienne surface : {total} Ha</InputUnitDescription>),
    O.toUndefined,
  );

  // init zoneAreaTypes on zone fetch
  useEffect(() => {
    pipe(
      IOO.fromOption(zone),
      IOO.chainIOK(zone => () => {
        // update only when zone change
        if (zoneId !== zone.id) {
          if (zone.type === Zone.Type.NoGame)
            setValue('dosage.area', { zoneId: zone.id, type: zone.type, zoneAreaType: zone.area.type });
          else
            setValue('dosage.area', {
              zoneId: zone.id,
              type: zone.type,
              zoneAreaTypes: zone.areas.map(({ type }) => type),
            });
        }
      }),
    )();
  }, [setValue, zone, zoneId]);

  // compute areas surface
  useEffect(() => {
    pipe(
      IOO.fromOption(zone),
      IOO.chainIOK(zone => () => {
        if (zone.type === Zone.Type.NoGame) setValue('dosage.totalZoneAreaValue', zone.area.value);
        else
          setValue(
            'dosage.totalZoneAreaValue',
            pipe(
              zone.areas,
              A.reduce<Zone.Area, number>(0, (prev, curr) =>
                A.elem(S.Eq)(curr.type)(zoneAreaTypes) ? prev + curr.value : prev,
              ),
            ),
          );
      }),
    )();
  }, [setValue, zone, zoneAreaTypes]);

  return (
    <Stack spacing="xs">
      <Controller
        control={control}
        name="dosage.zoneId"
        render={({ field }) => (
          <Select
            {...field}
            error={!!errors.dosage?.zoneId}
            data={zonesData}
            label="Zone"
            placeholder="Sélectionner"
            required
          />
        )}
      />
      <Group align="flex-start" noWrap>
        {renderOptional(
          zone,
          zone =>
            zone.type === Zone.Type.Game ? (
              <Controller
                control={control}
                name="dosage.area.zoneAreaTypes"
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    error={!!errors.dosage?.area}
                    data={pipe(
                      zone.areas,
                      A.map(({ type }) => ({ value: type, label: Zone.Area.gameTypeLabel[type] })),
                      A.sort(selectItemOrd),
                    )}
                    label="Type de surface"
                    placeholder="Sélectionner"
                    required
                    style={{ flex: 2 }}
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                name="dosage.area.zoneAreaType"
                render={({ field }) => (
                  <Select
                    {...field}
                    value={zone.area.type}
                    error={!!errors.dosage?.area}
                    data={[{ value: zone.area.type, label: Zone.Area.noGameTypeLabel[zone.area.type] }]}
                    label="Type de surface"
                    placeholder="Sélectionner"
                    disabled
                    required
                    style={{ flex: 2 }}
                  />
                )}
              />
            ),
          () => (
            <MultiSelect
              error={!!errors.dosage?.area}
              data={[]}
              label="Type de surface"
              placeholder="Sélectionner"
              disabled
              required
              style={{ flex: 2 }}
            />
          ),
        )}
        <TextInput
          {...register('dosage.totalZoneAreaValue')}
          label="Surface en Ha"
          placeholder="-"
          disabled
          description={totalDescription}
          style={{ flex: 1 }}
        />
      </Group>
    </Stack>
  );
};

export default InterventionZoneTreatment;

import { ParsedQuery, StringifiableRecord } from 'query-string';
import { getSearchQuery } from '@shared/modules/filter/utils';
import { ReactNode } from 'react';

export type Filter = StringifiableRecord;

export type SearchFilter = Filter & {
  search: string | null;
};

export const defaultSearchFilter: SearchFilter = {
  search: null,
};

export type FilterQueryParser<F extends Filter> = (query: ParsedQuery) => F;

export const searchFilterParser: FilterQueryParser<SearchFilter> = query => ({
  search: getSearchQuery(query),
});

export interface FilterChildrenProps<F extends Filter> {
  filter: F;
  onChange: (filter: F) => void;
}

export type FilterChildren<F extends Filter> = (props: FilterChildrenProps<F>) => ReactNode;

import React, { FC } from 'react';
import * as Styled from './Nav.styles';
import { Image, Navbar } from '@mantine/core';
import { IconHome2, IconDroplet, IconPlant, IconPhone } from '@tabler/icons-react';
import { SharedLink } from '@styles/shared/Link';
import config from '@root/config';
import platformIcon from '@assets/icons/platform.svg';

const Nav: FC = () => {
  return (
    <Navbar bg="blue.9" height={64} style={{ borderRadius: '16px 16px 0 0' }} withBorder={false}>
      <Styled.NavListing>
        <li>
          <SharedLink label="Accueil" icon={<IconHome2 />} to="/" />
        </li>
        <li>
          <SharedLink label="Eau" icon={<IconDroplet />} to="/water" />
        </li>
        <Styled.NavPlatformReturn>
          <SharedLink icon={<Image src={platformIcon} width={30} height={30} />} to={config.VITE_PLATFORM_URL} />
        </Styled.NavPlatformReturn>
        <li>
          <SharedLink label="Intrants" icon={<IconPlant />} to="/inputs" />
        </li>
        <li>
          <SharedLink label="Urgence" icon={<IconPhone />} to="/emergency-numbers" />
        </li>
      </Styled.NavListing>
    </Navbar>
  );
};

export default Nav;

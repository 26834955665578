import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { Card, Image, Stack, Text } from '@mantine/core';
import { IconDashboard, IconDroplet } from '@tabler/icons-react';

import { rgba } from 'polished';
import { SharedButton } from '@styles/shared';
import { Link, NavLink } from 'react-router-dom';
import SimpleCard from '@shared/components/list/card/SimpleCard';

import logoVigieau from '@assets/logos/vigieau.svg';

const VIGIEAU_URL = 'https://vigieau.gouv.fr';

const WaterListPage: FC = () => {
  const pageProps: PageProps = {
    seoTitle: 'Eau',
    back: {
      to: '/',
      title: 'Eau',
    },
    bottomBar: (
      <SharedButton btnType="create" component={NavLink} to="indexes/new">
        Saisir un index
      </SharedButton>
    ),
  };
  return (
    <Page {...pageProps} withNav>
      <Card p="lg" withBorder={false} sx={theme => ({ background: rgba(theme.colors.blue[2], 0.15) })}>
        <Stack>
          <SimpleCard
            bg="blue.8"
            to="indexes"
            icon={IconDroplet}
            title="Index"
            description="Déclarez les index de vos compteurs"
          />
          <SimpleCard
            bg="blue.4"
            to="meters"
            icon={IconDashboard}
            title="Compteurs"
            description="Déclarez vos compteurs"
          />
          <Card
            component={Link}
            to={VIGIEAU_URL}
            p="sm"
            sx={theme => ({ border: `2px solid ${theme.colors.blue[6]}` })}
          >
            <Stack spacing="xs">
              <Image src={logoVigieau} width={100} height={34} />
              <Text size="sm">Visualisez les restrictions d'eau sur mon golf, et être alerté</Text>
            </Stack>
          </Card>
        </Stack>
      </Card>
    </Page>
  );
};

export default WaterListPage;

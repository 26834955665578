import React, { FC, Fragment } from 'react';
import { Card, Text, Title } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import InputSimpleInfo from '@modules/inputs/components/InputSimpleInfo';
import { formatDate, parseDate } from '@shared/modules/dates';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { MfscInput } from '@modules/inputs/amm/mfsc/model';
import { constPlaceholder } from '@modules/inputs/amm/utils';

interface MfscInputInfosProps {
  input: MfscInput;
}

const MfscInputUsage: FC<MfscInputInfosProps> = ({ input }) => {
  return (
    <>
      <Title size="h3" pb={10} pt={30}>
        Usage
      </Title>
      <Card py={0}>
        <InputSimpleInfo title="Culture">{input.crop}</InputSimpleInfo>
        <InputSimpleInfo title="Date de décision">
          {renderOptional(parseDate(input.decisionDate), decisionDate => formatDate(decisionDate), constPlaceholder)}
        </InputSimpleInfo>
        <InputSimpleInfo title="Dose retenue">
          {renderOptional(
            sequenceS(O.Apply)({ dose: O.fromNullable(input.dose), doseUnit: O.fromNullable(input.doseUnit) }),
            ({ dose, doseUnit }) => `${dose} ${doseUnit}`,
            constPlaceholder,
          )}
        </InputSimpleInfo>
        <InputSimpleInfo title="Saison d’application min">
          {renderOptional(
            sequenceS(O.Apply)({
              min: O.fromNullable(input.seasonMin),
              comment: O.fromNullable(input.seasonMinComment),
            }),
            ({ min, comment }) => (
              <Fragment>
                <Text>{min}</Text>
                <Text>{comment}</Text>
              </Fragment>
            ),
            constPlaceholder,
          )}
        </InputSimpleInfo>
        <InputSimpleInfo title="Saison d’application max">
          {renderOptional(
            sequenceS(O.Apply)({
              max: O.fromNullable(input.seasonMax),
              comment: O.fromNullable(input.seasonMaxComment),
            }),
            ({ max, comment }) => (
              <>
                <Text>{max}</Text>
                <Text>{comment}</Text>
              </>
            ),
            constPlaceholder,
          )}
        </InputSimpleInfo>
      </Card>
    </>
  );
};

export default MfscInputUsage;

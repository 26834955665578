import React, { FC } from 'react';
import { Stack } from '@mantine/core';
import { Input } from '@modules/inputs/model';
import InterventionRecapDoses from '@modules/interventions/components/steps/InterventionRecapDoses';
import AmmInterventionDosageForm from '@modules/interventions/components/form/dosage/AmmInterventionDosageForm';
import CustomInterventionDosageForm from '@modules/interventions/components/form/dosage/CustomInterventionDosageForm';
import { Intervention } from '@modules/interventions/model';

interface UpdateInterventionDosageFormProps {
  intervention: Intervention;
}

const UpdateInterventionDosageForm: FC<UpdateInterventionDosageFormProps> = ({ intervention }) => {
  return (
    <Stack>
      {intervention.type === Input.Source.AMM ? (
        <>
          <InterventionRecapDoses dose={intervention.input.dose} doseUnit={intervention.input.doseUnit} pt="xs" />

          <AmmInterventionDosageForm
            dose={intervention.input.dose}
            doseUnit={intervention.input.doseUnit}
            oldTotalZoneAreaValue={intervention.totalZoneAreaValue}
          />
        </>
      ) : (
        <CustomInterventionDosageForm oldTotalZoneAreaValue={intervention.totalZoneAreaValue} />
      )}
    </Stack>
  );
};

export default UpdateInterventionDosageForm;

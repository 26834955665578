import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Operator } from '@modules/operators/model';
import { Button, Modal, Radio, Stack, Text, TextInput, Title, UnstyledButton } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { DateFormat, formatDate, LocalDate, parseDate } from '@shared/modules/dates';
import { Controller } from 'react-hook-form';
import { useDisclosure } from '@mantine/hooks';
import { renderNullable } from '@shared/utils/render';
import { newTypeWrap } from '@shared/schemas';

interface OperatorFormProps {
  leader: Operator | null;
  readonly?: boolean;
}

const OperatorForm: FC<OperatorFormProps> = ({ leader, readonly = false }) => {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    watch,
  } = useEnhancedFormContext<Operator.Params>();

  const [modalOpen, handlers] = useDisclosure(false);

  const handleDateChange = (value: Date | null) =>
    setValue('certyphytoExpirationDate', formatDate(value, DateFormat.LocalDate, newTypeWrap<LocalDate>('')));

  const handleChange = (role: Operator.Role) =>
    leader && role === Operator.Role.Leader ? handlers.open() : setValue('role', role);

  const handleRoleConfirm = () => {
    setValue('role', Operator.Role.Leader);
    handlers.close();
  };

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing="xs">
          <TextInput
            {...register('lastName')}
            error={!!errors.lastName}
            label="Nom"
            placeholder="Saisir un nom"
            disabled={readonly}
            required
          />
          <TextInput
            {...register('firstName')}
            error={!!errors.firstName}
            label="Prénom"
            placeholder="Saisir un prénom"
            disabled={readonly}
            required
          />
          <TextInput
            {...register('certyphytoNumber')}
            error={!!errors.certyphytoNumber}
            label="Numéro Certyphyto"
            placeholder="Saisir un numéro Certyphyto"
            disabled={readonly}
            required
          />
          <Controller
            control={control}
            name="certyphytoExpirationDate"
            render={({ field }) => (
              <DatePickerInput
                {...field}
                error={!!errors.certyphytoExpirationDate}
                value={parseDate(field.value, DateFormat.LocalDate, null)}
                placeholder="Saisir une date fin de validité"
                onChange={handleDateChange}
                label="Date fin de validité"
                disabled={readonly}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <Radio.Group
                mt={10}
                {...field}
                onChange={handleChange}
                label={<Title size="h3">L’opérateur est-il un décideur intervention phyto ?</Title>}
              >
                <Stack spacing="xs">
                  <Radio error={!!errors.role} value={Operator.Role.Leader} label="Oui" disabled={readonly} />
                  <Radio error={!!errors.role} value={Operator.Role.Member} label="Non" disabled={readonly} />
                </Stack>
              </Radio.Group>
            )}
          />
        </Stack>
      </form>
      {renderNullable(leader, leader => (
        <Modal opened={modalOpen} onClose={handlers.close} centered>
          <Stack align="center" ta="center">
            <Title size="h2">Etes-vous sûr ?</Title>
            <Text lh={1.25}>
              Un décideur est déjà sélectionné au sein de votre équipe, souhaitez-vous remplacer {leader.lastName}{' '}
              {leader.firstName} par l'opérateur {watch('lastName')} {watch('firstName')} ?
            </Text>
            <Stack>
              <Button onClick={handleRoleConfirm}>Valider ce décideur</Button>
              <UnstyledButton onClick={handlers.close} c="dark.3" ta="center" td="underline" fw={500}>
                Annuler
              </UnstyledButton>
            </Stack>
          </Stack>
        </Modal>
      ))}
    </>
  );
};

export default OperatorForm;

import { Newtype } from 'newtype-ts';

export namespace EmergencyNumber {
  export type Id = Newtype<{ readonly ID: unique symbol }, string> & string;

  export enum Type {
    Main = 'main',
    PoisonControlCenter = 'poison-control-center',
    GreenNumber = 'green-number',
  }
}

export interface EmergencyNumber {
  id: EmergencyNumber.Id;
  label: string;
  number: string;
  type: EmergencyNumber.Type;
}

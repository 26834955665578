import React, { useState } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { Report } from '@modules/reports/model';
import * as ReportService from '@modules/reports/service';
import { useSendTask } from '@core/http/hooks';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { reportSchema } from '@modules/reports/schema';
import { pipe } from 'fp-ts/function';
import { SharedButton } from '@styles/shared';
import ReportForm from '@modules/reports/components/form/ReportForm';
import { defineLoader, useLoader } from '@core/router/loader';
import z from 'zod';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { defineRoute } from '@core/router';
import { renderNullable } from '@shared/utils/render';
import { Anchor, Center, Loader, Stack } from '@mantine/core';

const loader = defineLoader({
  params: z.object({ code: z.nativeEnum(Report.Code) }),
  handler: ({ params }) => T.of(params.code),
});

export interface ReportResult {
  name: string | null;
  url: string;
}

const ReportPage = () => {
  const [result, setResult] = useState<ReportResult | null>(null);
  const code = useLoader<typeof loader>();

  const [createLoading, createReport] = useSendTask(ReportService.generateReport, {}, code);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Report.Params>({
    resolver: zodResolver(reportSchema),
  });

  const pageProps: PageProps = {
    seoTitle: Report.pageTitle[code],
    back: {
      to: '/',
      title: Report.pageTitle[code],
    },
    bottomBar: (
      <SharedButton btnType="save" loading={createLoading} onClick={handleFormSubmit}>
        Générer le rapport
      </SharedButton>
    ),
  };

  const handleSubmit = (params: Report.Params) =>
    pipe(
      () => createReport(params),
      TE.chainIOK(result => () => setResult(result)),
    );

  const handleDownloadFile = (url: string, name: string | null) => () => {
    const link = document.createElement('a');

    link.href = url;
    if (name) link.download = name;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleSubmit} preventLeave>
        <ReportForm />
        {createLoading ? (
          <Center pt="xl">
            <Loader size="xl" variant="dots" />
          </Center>
        ) : (
          renderNullable(result, ({ url, name }) => (
            <Center pt="xl">
              <Stack>
                <Anchor href={url} download={name} target="_blank" rel="noopener noreferrer" ta="center">
                  Voir le rapport
                </Anchor>
                <SharedButton
                  onClick={handleDownloadFile(url, name)}
                  c="green.7"
                  fw={400}
                  style={{ background: 'none' }}
                  sx={{ '&:hover': { textDecoration: 'underline' } }}
                >
                  Télécharger le rapport
                </SharedButton>
              </Stack>
            </Center>
          ))
        )}
      </EnhancedForm>
    </Page>
  );
};

const reportRoute = defineRoute({
  component: ReportPage,
  loader,
});
export default reportRoute;

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { EmergencyNumber } from '@modules/referentials/model';
import Type = EmergencyNumber.Type;

export function filterMapEmergencyNumbers(numbers: Array<EmergencyNumber>) {
  const {
    [Type.Main]: mains,
    [Type.PoisonControlCenter]: poisonControlCenters,
    [Type.GreenNumber]: greenNumbers,
  } = pipe(
    numbers,
    A.reduce<EmergencyNumber, Record<Type, Array<EmergencyNumber>>>(
      { [Type.Main]: [], [Type.PoisonControlCenter]: [], [Type.GreenNumber]: [] },
      (numbers, number) => ({
        ...numbers,
        [number.type]: [...numbers[number.type], number],
      }),
    ),
  );

  return {
    mains,
    poisonControlCenters,
    greenNumbers,
  };
}

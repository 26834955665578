import { SearchFilter } from '@shared/modules/filter';
import { HttpRange, httpService, HttpStatusCode, HttpTask } from '@core/http';
import { Operator } from '@modules/operators/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

const URI = '/operators';
export function getOperatorsRange(page: number, filter: SearchFilter): HttpRange<Operator.Range.Item, SearchFilter> {
  return httpService.getRange(URI, page, filter);
}

export function createOperator(params: Operator.Params): HttpTask<Operator> {
  return httpService.post(URI, params);
}

export function getOperator(id: Operator.Id): HttpTask<Operator> {
  return httpService.get(`${URI}/${id}`);
}

export function updateOperator(id: Operator.Id, params: Operator.Params): HttpTask<Operator> {
  return httpService.post(`${URI}/${id}`, params);
}

export function deleteOperator(id: Operator.Id): HttpTask {
  return httpService.delete(`${URI}/${id}`);
}

export function getLeader(operatorId?: Operator.Id): HttpTask<Operator | null> {
  return pipe(
    httpService.get<Operator>(`${URI}/leader`, { params: { operatorId } }, true),
    TE.map(res => (res.status === HttpStatusCode.NO_CONTENT ? null : res.data)),
  );
}

export function searchOperator(search: string | null): HttpTask<Array<Operator.List.Item>> {
  return httpService.get(`${URI}/search`, { params: { search } });
}

import React, { FC } from 'react';
import { Center, Modal, Title } from '@mantine/core';
import { SharedButton } from '@styles/shared';
import { useDisclosure } from '@mantine/hooks';
import { Intervention } from '@modules/interventions/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateInterventionDosageSchema } from '@modules/interventions/schema';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { getDosageFormBodyFromDetail } from '@modules/interventions/utils';
import UpdateInterventionDosageForm from '@modules/interventions/components/form/dosage/UpdateInterventionDosageForm';
import { HttpTask } from '@core/http';

interface UpdateInterventionDosageModalProps {
  intervention: Intervention;
  onUpdate: (params: Intervention.Update.FormBody.Dosage) => HttpTask;
  loading: boolean;
}

const UpdateInterventionDosageModal: FC<UpdateInterventionDosageModalProps> = ({ intervention, onUpdate, loading }) => {
  const { formRef, form, handleFormSubmit } = useEnhancedForm<Intervention.Update.FormBody.Dosage>({
    resolver: zodResolver(updateInterventionDosageSchema),
    defaultValues: getDosageFormBodyFromDetail(intervention),
  });

  const [opened, handlers] = useDisclosure(false);

  return (
    <>
      <Center my="md">
        <SharedButton onClick={handlers.open} loading={loading}>
          Modifier
        </SharedButton>
      </Center>
      <Modal title={<Title>Dosage</Title>} opened={opened} onClose={handlers.close} centered>
        <EnhancedForm ref={formRef} form={form} onSubmit={onUpdate}>
          <UpdateInterventionDosageForm intervention={intervention} />
        </EnhancedForm>

        <Center mt={15}>
          <SharedButton onClick={handleFormSubmit} loading={loading}>
            Enregistrer
          </SharedButton>
        </Center>
      </Modal>
    </>
  );
};

export default UpdateInterventionDosageModal;

import React, { FC } from 'react';
import { useParentLoader } from '@core/router/loader';
import AmmInputContent from '@modules/inputs/amm/components/detail/AmmInputContent';
import MfscInputUsage from '@modules/inputs/amm/mfsc/components/detail/MfscInputUsage';
import { loader } from '@modules/inputs/amm/mfsc/routes';

const MfscInputDetailPage: FC = () => {
  const input = useParentLoader<typeof loader>('mfsc-input-detail');

  return (
    <AmmInputContent input={input}>
      <MfscInputUsage input={input} />
    </AmmInputContent>
  );
};

export default MfscInputDetailPage;

import React, { FC } from 'react';
import { CustomInput } from '@modules/inputs/custom/model';
import { Card, SimpleGrid, Stack, Text, TextInput, Title } from '@mantine/core';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Percentage } from '@shared/modules/number/model';
import * as S from 'fp-ts/string';
import { renderOptional } from '@shared/utils/render';

interface CustomInputCompositionValuesProps {
  title: string;
  total: Percentage | null;
  late: Percentage | null;
  organic: Percentage | null;
}
const CustomInputCompositionValues: FC<CustomInputCompositionValuesProps> = ({ title, total, organic, late }) => {
  return (
    <Stack spacing="sm">
      <Title size="h4">{title}</Title>
      <SimpleGrid cols={3}>
        <TextInput
          label={
            <Text size="sm" weight="normal" lh={1}>
              Total
            </Text>
          }
          value={total ?? 'xx'}
          disabled
        />
        <TextInput
          label={
            <Text size="sm" weight="normal" lh={1}>
              Retard
            </Text>
          }
          value={late ?? 'xx'}
          disabled
        />
        <TextInput
          label={
            <Text size="sm" weight="normal" lh={1}>
              Organique
            </Text>
          }
          value={organic ?? 'xx'}
          disabled
        />
      </SimpleGrid>
    </Stack>
  );
};

interface CustomInputCompositionElementsProps {
  title: string;
  values: Array<string | false>;
}
const CustomInputCompositionElements: FC<CustomInputCompositionElementsProps> = ({ title, values }) => {
  return renderOptional(NEA.fromArray(values.filter(S.isString)), values => (
    <Stack spacing={5}>
      <Title size="h4">{title}</Title>
      <Text lh={1.25}>{values.join(' | ')}</Text>
    </Stack>
  ));
};

interface CustomInputCompositionProps {
  input: CustomInput;
}

const CustomInputComposition: FC<CustomInputCompositionProps> = ({ input }) => {
  const secondary = [`Magnésie ${input.magnesia}%`, input.sulfur && 'Souffre', input.calcium && 'Calcium'];
  const oligo = [
    input.boron && 'Bore',
    input.manganese && 'Manganèse',
    input.copper && 'Cuivre',
    input.iron && 'Fer',
    input.molybdenum && 'Molybdène',
    input.zinc && 'Zinc',
  ];

  const other = [
    input.agronomicAdditive && 'Additif agronomique',
    input.macroOrganism && 'Macroorganisme',
    input.ceramic && 'Céramique poreuse',
    input.zeolite && 'Zéolithe',
    input.otherRawMaterial && 'Autre matière première',
  ];

  return (
    <Stack>
      <Title size="h3">Composition du produit</Title>
      <Card withBorder={false}>
        <Stack spacing="xl">
          <CustomInputCompositionValues
            title="Azote (N)"
            total={input.totalN}
            late={input.lateN}
            organic={input.organicN}
          />
          <CustomInputCompositionValues
            title="Phosphore (P)"
            total={input.totalP}
            late={input.lateP}
            organic={input.organicP}
          />
          <CustomInputCompositionValues
            title="Potassium (K)"
            total={input.totalK}
            late={input.lateK}
            organic={input.organicK}
          />
          <CustomInputCompositionElements title="Eléments secondaires" values={secondary} />
          <CustomInputCompositionElements title="Oligos élements" values={oligo} />
          <CustomInputCompositionElements title="Autres" values={other} />
        </Stack>
      </Card>
    </Stack>
  );
};

export default CustomInputComposition;

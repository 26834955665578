import React, { FC } from 'react';
import { ActionIcon, Anchor, AspectRatio, Card, Container, Drawer, Group, Stack, Text, Title } from '@mantine/core';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as ReferentialsService from '@modules/referentials/service';
import { defineRoute } from '@core/router';
import { filterMapEmergencyNumbers } from '@modules/emergency-numbers/utils';
import { IconPhone, IconChevronRight, IconArrowLeft } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { EmergencyNumber } from '@modules/referentials/model';
import Page, { PageProps } from '@layout/page/Page';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(ReferentialsService.getEmergencyNumbers()),
});

const EmergencyGreenNumberCard: FC<{ emergency: EmergencyNumber }> = ({ emergency }) => {
  return (
    <AspectRatio ratio={294 / 48} py={5}>
      <Anchor href={`tel:${emergency.number}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="294" height="48" viewBox="0 0 294 48">
          <g transform="translate(-20 -677)">
            <g transform="translate(20 677)" stroke="#2b8a3e" strokeWidth={2} fill="none">
              <rect width="294" height="48" rx="24" stroke="none" />
              <rect x="1" y="1" width="292" height="46" rx="23" fill="none" />
            </g>
            <rect width="133" height="48" rx="24" transform="translate(20 677)" fill="#2b8a3e" />
            <text transform="translate(177 708)" fontWeight={500} fontSize={16} fill="#2b8a3e">
              <tspan x="0" y="0">
                {emergency.number}
              </tspan>
            </text>
            <text transform="translate(80 708)" fontWeight={500} fontSize={18} fill="#fff">
              <tspan x="0" y="0">
                N°Vert
              </tspan>
            </text>
            <circle cx="20" cy="20" r="20" transform="translate(25 681)" fill="#fff" />
            <g transform="translate(28 684)">
              <rect width="34" height="34" rx="8" fill="none" />
              <path d="M0 0h24v24H0z" fill="none" transform="translate(5 5)" />
              <path
                d="M5 4h4l2 5-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2"
                fill="#2b8a3e"
                transform="translate(5 5)"
              />
            </g>
          </g>
        </svg>
      </Anchor>
    </AspectRatio>
  );
};

const EmergencyNumberSimpleCard: FC<{ emergency: EmergencyNumber }> = ({ emergency }) => {
  return (
    <Card bg="background.1" px="lg" py="md" radius={71} withBorder={false}>
      <Group position="apart">
        <Stack spacing={5}>
          <Title size="h4">{emergency.label}</Title>
          <Text lh={1}>{emergency.number}</Text>
        </Stack>
        <ActionIcon component="a" href={`tel:${emergency.number}`} variant="filled" radius="xl" size={41} color="red.4">
          <IconPhone size={22} />
        </ActionIcon>
      </Group>
    </Card>
  );
};

const EmergencyNumbersListPage: FC = () => {
  const numbers = useLoader<typeof loader>();
  const [opened, handlers] = useDisclosure(false);

  const { mains, poisonControlCenters, greenNumbers } = filterMapEmergencyNumbers(numbers);

  const title = "Liste des numéros d'urgence";

  const pageProps: PageProps = {
    title,
    back: {
      to: '/',
      title: "Numéros d'urgence",
    },
  };

  return (
    <Page {...pageProps} withNav>
      <Stack spacing={10}>
        {mains.map(emergency => (
          <EmergencyNumberSimpleCard key={emergency.id} emergency={emergency} />
        ))}
        {renderOptional(NEA.fromArray(poisonControlCenters), () => (
          <Card bg="#f3f6fb" px="lg" py="md" radius={71} withBorder={false}>
            <Group position="apart">
              <Stack spacing={5}>
                <Title size="h4">Centres antipoison</Title>
                <Text size="sm" lh={1}>
                  Liste des numéros antipoison
                </Text>
              </Stack>
              <ActionIcon
                onClick={handlers.open}
                variant="filled"
                radius="xl"
                size={41}
                bg="white"
                color="gray.1"
                c="black"
              >
                <IconChevronRight size={22} />
              </ActionIcon>
            </Group>
          </Card>
        ))}
        {greenNumbers.map(emergency => (
          <Card key={emergency.id} bg="background.3" px="lg" py="md" radius={10} withBorder={false}>
            <Container maw={294} p={0}>
              <Title size="h4">{emergency.label}</Title>
              <EmergencyGreenNumberCard emergency={emergency} />
              <Text ta="right" size="xs">
                Appel gratuit
              </Text>
            </Container>
          </Card>
        ))}
      </Stack>
      <Drawer
        opened={opened}
        onClose={handlers.close}
        title={
          <Group spacing={5}>
            <ActionIcon onClick={handlers.close} variant="transparent" c="white">
              <IconArrowLeft size={22} />
            </ActionIcon>
            <Title size="h3" color="white">
              Centre antipoison
            </Title>
          </Group>
        }
        size="100%"
        position="right"
        withCloseButton={false}
        styles={theme => ({
          header: {
            width: '100%',
            background: theme.colors.blue[9],
            minHeight: 63,
            padding: '0 20px',
            marginBottom: 0,
            zIndex: 10,
          },
          drawer: { border: 'none', display: 'flex', flexDirection: 'column' },
          body: { flexGrow: 1, overflowY: 'auto' },
        })}
      >
        <Stack spacing="lg" px="lg" pt={20} pb={90}>
          <Stack spacing={5}>
            <Text size="xs" lh={1}>
              Numéros d’urgences /
            </Text>
            <Title>Liste des centres antipoison</Title>
          </Stack>
          <Stack spacing={10}>
            {poisonControlCenters.map(emergency => (
              <EmergencyNumberSimpleCard key={emergency.id} emergency={emergency} />
            ))}
          </Stack>
        </Stack>
      </Drawer>
    </Page>
  );
};

const emergencyNumbersListRoute = defineRoute({
  component: EmergencyNumbersListPage,
  loader,
});

export default emergencyNumbersListRoute;

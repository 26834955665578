import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as OperatorsService from '@modules/operators/service';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { defineAction, useAction } from '@core/router/action';
import { Group } from '@mantine/core';
import { SharedButton } from '@styles/shared/Button';
import * as EI from 'fp-ts/Either';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { Operator } from '@modules/operators/model';
import { operatorSchema } from '@modules/operators/schema';
import OperatorForm from '@modules/operators/components/form/OperatorForm';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { useHasRole } from '@modules/auth/hooks';
import { CompanyUserRole } from '@modules/auth/model';

const operatorIdSchema = z.object({ id: createNewTypeStringSchema<Operator.Id>() });

const loader = defineLoader({
  params: operatorIdSchema,
  handler: ({ params }) =>
    httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        operator: OperatorsService.getOperator(params.id),
        leader: OperatorsService.getLeader(params.id),
      }),
    ),
});

const actions = {
  update: defineAction({
    type: 'update',
    params: operatorIdSchema,
    payload: operatorSchema,
    handler: ({ payload, params }) => OperatorsService.updateOperator(params.id, payload),
    flashOptions: {
      success: () => "L'opérateur a été modifié avec succès",
    },
  }),
  delete: defineAction({
    type: 'delete',
    params: operatorIdSchema,
    handler: ({ params }) => OperatorsService.deleteOperator(params.id),
    flashOptions: {
      success: () => 'Opérateur supprimé avec succès',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/operators' : null),
  }),
};

const OperatorDetailPage: FC = () => {
  const { operator, leader } = useLoader<typeof loader>();
  const [updateLoading, updateOperator] = useAction(actions.update);
  const [deleteLoading, deleteOperator] = useAction(actions.delete);
  const isAdmin = useHasRole(CompanyUserRole.Admin);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Operator.Params>({
    resolver: zodResolver(operatorSchema),
    defaultValues: {
      role: operator.role,
      lastName: operator.lastName,
      certyphytoExpirationDate: operator.certyphytoExpirationDate,
      certyphytoNumber: operator.certyphytoNumber,
      firstName: operator.firstName,
    },
  });

  const pageProps: PageProps = {
    seoTitle: 'Votre opérateur',
    back: {
      title: 'Votre opérateur',
      to: '/operators',
    },
    bottomBar: isAdmin ? (
      <Group position="center" spacing={8}>
        <DeleteModal onDelete={deleteOperator} loading={deleteLoading} />
        <SharedButton btnType="save" loading={updateLoading} onClick={handleFormSubmit}>
          Enregistrer
        </SharedButton>
      </Group>
    ) : undefined,
  };

  const handleUpdateOperator = (params: Operator.Params) => () => updateOperator(params);

  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateOperator} preventLeave>
        <OperatorForm leader={leader} readonly={!isAdmin} />
      </EnhancedForm>
    </Page>
  );
};

const operatorDetailRoute = defineRoute({
  component: OperatorDetailPage,
  loader,
  actions,
});

export default operatorDetailRoute;

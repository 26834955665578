import { Newtype } from 'newtype-ts';
import { z } from 'zod';

export namespace OpenData {
  export const AmmNumber = z.string().brand('AmmNumber');
  export type AmmNumber = z.infer<typeof AmmNumber>;
  export type ProductUsageId = Newtype<{ readonly ID: unique symbol }, string> & string;
  export type MfscUsageId = Newtype<{ readonly ID: unique symbol }, string> & string;
  export type UsageId = ProductUsageId | MfscUsageId;

  export namespace TradeLicence {
    export const Id = z.string().uuid().brand('TradeLicenceId');
    export type Id = z.infer<typeof Id>;

    export const Number = z.number().brand('TradeLicenceNumber');
    export type Number = z.infer<typeof Number>;
  }
}

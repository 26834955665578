import { Newtype } from 'newtype-ts';
import { zoneFormBodySchema, zoneParamsSchema } from '@modules/zones/schema';
import z from 'zod';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as Ord from 'fp-ts/Ord';
import * as S from 'fp-ts/string';

export namespace Zone {
  export type Id = Newtype<{ readonly ID: unique symbol }, string> & string;

  export enum Type {
    Game = 'game',
    NoGame = 'no-game',
  }

  export const typeLabel: Record<Type, string> = {
    [Type.Game]: 'Zone de jeu',
    [Type.NoGame]: 'Zone de non jeu',
  };

  export namespace Range {
    export interface Item {
      id: Id;
      type: Type;
      name: string;
    }
  }

  export namespace Area {
    export enum GameType {
      Green = 'Green',
      Departs = 'Departs',
      Fairways = 'Fairways',
      RoughsEntretenus = 'RoughsEntretenus',
      RoughsExtensifs = 'RoughsExtensifs',
      AvantGreen = 'AvantGreen',
      TourGreen = 'TourGreen',
      Bunker = 'Bunker',
    }

    export const gameTypeLabel: Record<GameType, string> = {
      [GameType.Departs]: 'Départs',
      [GameType.Fairways]: 'Fairways',
      [GameType.Green]: 'Green',
      [GameType.RoughsEntretenus]: 'Semi roughs',
      [GameType.RoughsExtensifs]: 'Roughs extensifs',
      [GameType.AvantGreen]: 'Avant green',
      [GameType.TourGreen]: 'Tour de green',
      [GameType.Bunker]: 'Bunker',
    };

    export enum NoGameType {
      ZoneCultiveePiedsArbres = 'ZoneCultiveePiedsArbres',
      ZoneCultiveeMassifsFloraux = 'ZoneCultiveeMassifsFloraux',
      ZoneCultiveeMassifsArbusifs = 'ZoneCultiveeMassifsArbusifs',
      ZoneNonCultivee = 'ZoneNonCultivee',
      SurfacesSemiPermeables = 'SurfacesSemiPermeables',
      VegetationLigneuse = 'VegetationLigneuse',
      ZoneArbresEtArbustesOrnement = 'ZoneArbresEtArbustesOrnement',
      ZonesPlantesEtFleursMassif = 'ZonesPlantesEtFleursMassif',
      PJTImpermeable = 'PJTImpermeable',
      PJTPermeables = 'PJTPermeables',
      ZonesVergerPotager = 'ZonesVergerPotager',
      Broussailles = 'Broussailles',
    }

    export const noGameTypeLabel: Record<NoGameType, string> = {
      [NoGameType.ZoneCultiveePiedsArbres]: 'Zone cultivée pieds d’arbre',
      [NoGameType.ZoneCultiveeMassifsFloraux]: 'Zone cultivée massifs floraux',
      [NoGameType.ZoneCultiveeMassifsArbusifs]: 'Zone cultivée massifs arbusifs',
      [NoGameType.ZoneNonCultivee]: 'Zone non cultivée',
      [NoGameType.SurfacesSemiPermeables]: 'Surfaces semi-perméables',
      [NoGameType.VegetationLigneuse]: 'Végétation ligneuse',
      [NoGameType.ZoneArbresEtArbustesOrnement]: 'Zone arbres et arbustes',
      [NoGameType.ZonesPlantesEtFleursMassif]: 'Zones plantes et fleurs à massif',
      [NoGameType.PJTImpermeable]: 'Allées de PJT imperméables',
      [NoGameType.PJTPermeables]: 'Allées de PJT perméables',
      [NoGameType.ZonesVergerPotager]: 'Zones de verger et potager',
      [NoGameType.Broussailles]: 'Broussailles',
    };

    export const Type = { ...GameType, ...NoGameType };
    export type Type = GameType | NoGameType;

    export const typeLabel: Record<Type, string> = {
      ...gameTypeLabel,
      ...noGameTypeLabel,
    };

    export const ord = Ord.fromCompare<Area>((x, y) => S.Ord.compare(typeLabel[x.type], typeLabel[y.type]));
  }

  export interface GameArea {
    type: Area.GameType;
    value: number;
  }

  export interface NoGameArea {
    type: Area.NoGameType;
    value: number;
  }

  export type Area = GameArea | NoGameArea;

  export type Params = z.infer<typeof zoneParamsSchema>;
  export type FormBody = z.infer<typeof zoneFormBodySchema>;

  export interface Game {
    id: Id;
    name: string;
    areas: NEA.NonEmptyArray<GameArea>;
    type: Type.Game;
  }

  export interface NoGame {
    id: Id;
    name: string;
    area: NoGameArea;
    type: Type.NoGame;
  }

  export namespace List {
    export interface Item {
      id: Id;
      type: Type;
      name: string;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Zone = Zone.Game | Zone.NoGame;

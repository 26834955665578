import React, { FC } from 'react';
import * as ZonesService from '@modules/zones/service';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { Zone } from '@modules/zones/model';
import ZoneForm from '@modules/zones/components/form/ZoneForm';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { zoneFormBodySchema, zoneParamsSchema } from '@modules/zones/schema';
import { constNull, pipe } from 'fp-ts/function';
import { defineAction, useAction } from '@core/router/action';
import { Stack, Text } from '@mantine/core';
import { SharedButton } from '@styles/shared/Button';
import * as EI from 'fp-ts/Either';
import { getInitialGameAreas, mapZoneFormBodyToParams } from '@modules/zones/utils';
import { SharedLink } from '@styles/shared/Link';
import { stringifyQueries } from '@shared/utils/queries';

const actions = {
  create: defineAction({
    type: 'create',
    payload: zoneParamsSchema,
    handler: ({ payload }) => ZonesService.createZone(payload),
    redirect: ({ result }) =>
      pipe(
        result,
        EI.fold(constNull, ({ name }) => ({
          pathname: '/zones/success',
          search: stringifyQueries({ name }),
        })),
      ),
  }),
};

const ZoneDetailPage: FC = () => {
  const [createLoading, createZone] = useAction(actions.create);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Zone.FormBody>({
    resolver: zodResolver(zoneFormBodySchema),
    defaultValues: {
      type: Zone.Type.Game,
      ...getInitialGameAreas(),
    },
  });

  const pageProps: PageProps = {
    seoTitle: "Nouvelle zone d'entretien",
    back: {
      title: "Nouvelle zone d'entretien",
      to: '/zones',
    },
    bottomBar: (
      <Stack spacing={10} align="center">
        <SharedButton btnType="create" loading={createLoading} onClick={handleFormSubmit}>
          Créer
        </SharedButton>

        <SharedLink
          to="/zones"
          label={
            <Text color="dark.3" weight={500} size="md" td="underline">
              Annuler
            </Text>
          }
        />
      </Stack>
    ),
  };

  const handleCreateZone = (params: Zone.FormBody) => () => createZone(mapZoneFormBodyToParams(params));

  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateZone} preventLeave>
        <ZoneForm />
      </EnhancedForm>
    </Page>
  );
};

const createZoneRoute = defineRoute({
  component: ZoneDetailPage,
  actions,
});

export default createZoneRoute;

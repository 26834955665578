import React, { FC, useState } from 'react';
import { Stack, Select } from '@mantine/core';
import { NonMfscInput } from '@modules/inputs/amm/non-mfsc/model';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Controller } from 'react-hook-form';
import { Input } from '@modules/inputs/model';
import { identity, pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { AmmInput } from '@modules/inputs/amm/model';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { constPlaceholder, placeholder } from '@modules/inputs/amm/utils';
import * as NEA from 'fp-ts/NonEmptyArray';
import { NonMfscParams } from '@modules/inputs/search/model';
import * as O from 'fp-ts/Option';
import { newTypeWrap } from '@shared/schemas';
import InputSimpleInfo from '@modules/inputs/components/InputSimpleInfo';
import { Intervention } from '@modules/interventions/model';
import AmmInputNameSelection from '@modules/inputs/amm/components/steps/1/AmmInputNameSelection';

interface CreateInterventionFirstStepProps {
  input: NonMfscInput;
  detail: Omit<Input.View.Detail, 'mfsc'>;
  params: NonMfscParams;
}

const CreateNonMfscInterventionFirstStep: FC<CreateInterventionFirstStepProps> = ({ input, detail, params }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useEnhancedFormContext<Intervention.Create.FormBody.AMM>();

  const [crop, setCrop] = useState(params.crop);
  const [pest, setPest] = useState(params.pest);

  const handleCropChange = (name: string | null) => {
    const crop = pipe(
      detail.nonMfsc,
      A.findFirst(crop => crop.name === name),
      O.toNullable,
    );

    if (crop) {
      setValue('input.usageId', newTypeWrap(''));
      setPest(({ name }) => ({ name, usages: [] }));
      setCrop(crop);
    }
  };

  const handlePestChange = (pests: Array<Input.View.Pest>) => (name: string | null) => {
    const pest = pipe(
      pests,
      A.findFirst(pest => pest.name === name),
      O.toNullable,
    );

    if (pest) {
      setValue('input.usageId', newTypeWrap(''));
      setPest(pest);
    }
  };

  return (
    <Stack px="md" pt="lg">
      <div>
        <AmmInputNameSelection input={input} names={detail.names} />

        <Stack spacing="xs">
          <Select
            value={crop.name}
            onChange={handleCropChange}
            label="Culture"
            placeholder="Sélectionner"
            data={detail.nonMfsc.map(({ name }) => ({ value: name, label: name }))}
            error={!!errors.input?.usageId}
            searchable
            required
          />
          {renderOptional(NEA.fromArray(crop.pests), pests => (
            <Select
              value={pest.name}
              label="Bioagresseur"
              placeholder="Sélectionner"
              data={pests.map(({ name }) => ({ value: name, label: name }))}
              onChange={handlePestChange(pests)}
              error={!!errors.input?.usageId}
              searchable
              required
            />
          ))}
          {renderOptional(NEA.fromArray(pest.usages), usages => (
            <Controller
              control={control}
              name="input.usageId"
              render={({ field }) => (
                <Select
                  {...field}
                  error={!!errors.input?.usageId}
                  data={usages.map(({ unit, usageId }) => ({ value: usageId, label: unit ?? placeholder }))}
                  label="Unité de dosage max"
                  placeholder="Sélectionner"
                  searchable
                  required
                />
              )}
            />
          ))}
        </Stack>
      </div>
      <div>
        <InputSimpleInfo title="Type de l'intrant">{AmmInput.typeLabel[input.ammType]}</InputSimpleInfo>
        <InputSimpleInfo title="Délai de rentrée">{input.waitingPeriod}</InputSimpleInfo>
        <InputSimpleInfo title="Fonction">
          {renderNullable(input.functions, identity, constPlaceholder)}
        </InputSimpleInfo>
      </div>
    </Stack>
  );
};

export default CreateNonMfscInterventionFirstStep;

import React, { FC } from 'react';
import InterventionRecapCard from '@modules/interventions/components/steps/InterventionRecapCard';
import CustomInterventionDosageForm from '@modules/interventions/components/form/dosage/CustomInterventionDosageForm';
import { CustomInput } from '@modules/inputs/custom/model';
import { Box } from '@mantine/core';

interface CreateCustomInterventionThirdStepProps {
  input: CustomInput;
}

const CreateCustomInterventionThirdStep: FC<CreateCustomInterventionThirdStepProps> = ({ input }) => {
  return (
    <>
      <InterventionRecapCard type={input.customType} name={input.name} />
      <Box px="lg" pt="lg">
        <CustomInterventionDosageForm />
      </Box>
    </>
  );
};

export default CreateCustomInterventionThirdStep;

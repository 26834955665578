import React, { FC } from 'react';
import { Stack, Title } from '@mantine/core';
import AmmInputWarningCard from '@modules/inputs/amm/components/detail/AmmInputWarningCard';
import { IconAlertTriangle } from '@tabler/icons-react';
import * as NEA from 'fp-ts/NonEmptyArray';
import { RiskPhrase } from '@modules/inputs/amm/model';

interface AmmInputDangerProps {
  hazardPhrases: NEA.NonEmptyArray<RiskPhrase>;
}

const AmmInputDanger: FC<AmmInputDangerProps> = ({ hazardPhrases }) => {
  return (
    <>
      <Title size="h3" pb="xs" pt={30}>
        Mentions danger
      </Title>
      <Stack spacing={5}>
        {hazardPhrases.map(({ code, phase }) => (
          <AmmInputWarningCard icon={IconAlertTriangle} title={code} key={code}>
            {phase}
          </AmmInputWarningCard>
        ))}
      </Stack>
    </>
  );
};

export default AmmInputDanger;

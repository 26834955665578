import React, { FC } from 'react';
import { Badge, DefaultMantineColor } from '@mantine/core';
import { Water } from '@modules/water/model';
import Meter = Water.Meter;

interface MeterStateLabelProps {
  activationState: Meter.State;
}

const stateLabel: Record<Meter.State, string> = {
  [Meter.State.Disabled]: 'Inactif',
  [Meter.State.Enabled]: 'Actif',
};

const stateColor: Record<Meter.State, DefaultMantineColor> = {
  [Meter.State.Disabled]: 'gray',
  [Meter.State.Enabled]: 'blue',
};

const MeterStateLabel: FC<MeterStateLabelProps> = ({ activationState }) => {
  return (
    <Badge color={stateColor[activationState]} fw={600}>
      {stateLabel[activationState]}
    </Badge>
  );
};

export default MeterStateLabel;

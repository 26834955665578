import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Zone } from '@modules/zones/model';
import { Select, SimpleGrid, Stack, Tabs, Text, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { getSelectDataFromEnumLabels } from '@shared/utils/enum';
import { renderNullable } from '@shared/utils/render';
import { gameZoneAreasShape } from '@modules/zones/schema';
import * as R from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as Ord from 'fp-ts/Ord';
import * as N from 'fp-ts/number';
import SafeNumberInput from '@shared/components/number/SafeNumberInput';

const areaOrd: Ord.Ord<[Zone.Area.GameType, typeof gameZoneAreasShape[Zone.Area.GameType]]> = pipe(
  N.Ord,
  Ord.contramap(([, { order }]) => order),
);

const gameZoneAreas = pipe(R.toEntries(gameZoneAreasShape), A.sort(areaOrd));

interface ZoneFormProps {
  readonly?: boolean;
}

const ZoneForm: FC<ZoneFormProps> = ({ readonly = false }) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useEnhancedFormContext<Zone.FormBody>();

  const type = watch('type');

  const handleTabChange = () => setValue('type', type === Zone.Type.Game ? Zone.Type.NoGame : Zone.Type.Game);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Tabs value={type} onTabChange={handleTabChange}>
        <Tabs.List mb={20}>
          <Tabs.Tab value={Zone.Type.Game} disabled={readonly}>
            {Zone.typeLabel[Zone.Type.Game]}
          </Tabs.Tab>
          <Tabs.Tab value={Zone.Type.NoGame} disabled={readonly}>
            {Zone.typeLabel[Zone.Type.NoGame]}
          </Tabs.Tab>
        </Tabs.List>
        <TextInput
          {...register('name')}
          label="Nom"
          error={!!errors.name}
          placeholder="Saisir un nom"
          pb="xs"
          disabled={readonly}
          required
        />
        <Tabs.Panel value={Zone.Type.Game}>
          <Stack spacing="xs">
            <SimpleGrid cols={2} spacing="xs">
              {gameZoneAreas.map(([type, { required }]) => (
                <Controller
                  key={type}
                  control={control}
                  name={`${type}.value`}
                  render={({ field }) => (
                    <SafeNumberInput
                      min={0}
                      label={
                        <Stack spacing={8}>
                          <Text lh={1}>
                            {Zone.Area.gameTypeLabel[type]}
                            {required && ' *'}
                          </Text>
                          <Text lh={1} size="xs" weight={400} opacity={0.4}>
                            Surface en Ha
                          </Text>
                        </Stack>
                      }
                      placeholder="Saisir"
                      error={!!(errors as any)?.[type]?.value}
                      disabled={readonly}
                      {...field}
                    />
                  )}
                />
              ))}
            </SimpleGrid>
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value={Zone.Type.NoGame}>
          <Stack spacing="xs">
            <Controller
              control={control}
              name="area.type"
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    error={'area' in errors && !!errors.area?.type}
                    data={getSelectDataFromEnumLabels(Zone.Area.noGameTypeLabel)}
                    placeholder="Sélectionner"
                    label="Type de zone"
                    disabled={readonly}
                    required
                  />
                  {renderNullable(field.value, type => (
                    <Controller
                      key={type}
                      control={control}
                      name="area.value"
                      render={({ field }) => (
                        <SafeNumberInput
                          error={'area' in errors && !!errors.area?.value}
                          min={0}
                          label={
                            <Stack spacing={8}>
                              <Text lh={1}>{Zone.Area.noGameTypeLabel[type]}</Text>
                              <Text lh={1} size="xs" weight={400} opacity={0.4}>
                                Surface en Ha
                              </Text>
                            </Stack>
                          }
                          placeholder="Saisir"
                          disabled={readonly}
                          styles={{
                            label: {
                              paddingBottom: 4,
                            },
                          }}
                          {...field}
                        />
                      )}
                    />
                  ))}
                </>
              )}
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </form>
  );
};

export default ZoneForm;

import React, { FC } from 'react';
import { RiskPhrase } from '@modules/inputs/amm/model';
import { Stack, Title } from '@mantine/core';
import AmmInputWarningCard from '@modules/inputs/amm/components/detail/AmmInputWarningCard';
import { IconHandStop } from '@tabler/icons-react';
import * as NEA from 'fp-ts/NonEmptyArray';

interface AmmInputRiskProps {
  riskPhrases: NEA.NonEmptyArray<RiskPhrase>;
}

const AmmInputRisk: FC<AmmInputRiskProps> = ({ riskPhrases }) => {
  return (
    <>
      <Title size="h3" pt={30} pb="xs">
        Phrases de risque
      </Title>
      <Stack spacing={5}>
        {riskPhrases.map(({ code, phase }) => (
          <AmmInputWarningCard key={code} title={code} icon={IconHandStop}>
            {phase}
          </AmmInputWarningCard>
        ))}
      </Stack>
    </>
  );
};

export default AmmInputRisk;

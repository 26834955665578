import { HttpError, httpService, HttpStatusCode, HttpTask } from '@core/http';
import { AuthTokens, Profile, SSOToken } from '@modules/auth/model';
import { pipe } from 'fp-ts/function';
import * as localforage from 'localforage';
import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';

const tokenStorage = localforage.createInstance({
  name: 'pg-golf',
  storeName: 'auth',
});

export function getAuthTokens(): TO.TaskOption<AuthTokens> {
  return pipe(() => tokenStorage.getItem<AuthTokens>('tokens'), T.map(O.fromNullable));
}

export function getProfile(): HttpTask<Profile> {
  return httpService.get('/profile');
}

function storeAuthTokens(tokens: AuthTokens): T.Task<AuthTokens> {
  return () => tokenStorage.setItem('tokens', tokens);
}

export function authenticate(token: SSOToken): HttpTask<AuthTokens> {
  return pipe(httpService.post<AuthTokens>('/authenticate', { token }), TE.chainFirstTaskK(storeAuthTokens));
}

export function refreshToken(): HttpTask<AuthTokens> {
  return pipe(
    getAuthTokens(),
    TE.fromTaskOption(() => HttpError.fromStatusCode(HttpStatusCode.UNAUTHORIZED)),
    TE.chain(({ refreshToken }) => httpService.post<AuthTokens>('/authenticate/refresh', { refreshToken })),
    TE.chainFirstTaskK(storeAuthTokens),
  );
}

import React, { FC } from 'react';
import CreateInterventionSecondStep from '@modules/interventions/components/steps/2/CreateInterventionSecondStep';
import { AmmInput } from '@modules/inputs/amm/model';

interface CreateAmmInterventionSecondStepProps {
  input: AmmInput;
}

const CreateAmmInterventionSecondStep: FC<CreateAmmInterventionSecondStepProps> = ({ input }) => {
  const name = input.tradeLicence ? input.tradeLicence.name : input.name;
  return <CreateInterventionSecondStep name={name} type={input.ammType} dose={input.dose} doseUnit={input.doseUnit} />;
};

export default CreateAmmInterventionSecondStep;

import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { SelectItem } from '@mantine/core';

export function getSelectDataFromEnumLabels<E extends string>(labels: Record<E, string>): Array<SelectItem> {
  return Object.keys(labels).map(value => ({ value, label: labels[value as E] }));
}

export function getEnumValueFromSelectProps<E>({ value }: SelectItem, enumeration: Record<string, E>): E | null {
  return pipe(
    O.fromNullable(value),
    O.filterMap(value =>
      typeof value === 'string' && Object.values(enumeration).includes(value as unknown as E)
        ? O.some(value as unknown as E)
        : O.none,
    ),
    O.toNullable,
  );
}

export function numberEnumToArray<E extends number>(e: Record<E, number | string>): Array<E> {
  return Object.keys(e)
    .map(x => parseInt(x))
    .filter(x => !isNaN(x)) as unknown as Array<E>;
}

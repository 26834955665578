import React, { FC } from 'react';
import { Stepper } from '@mantine/core';
import CreateCustomInterventionFirstStep from '@modules/inputs/custom/components/steps/1/CreateCustomInterventionFirstStep';
import { useParentLoader } from '@core/router/loader';
import { loader } from '@modules/inputs/custom/routes';
import CreateInterventionSecondStep from '@modules/interventions/components/steps/2/CreateInterventionSecondStep';
import CreateCustomInterventionFourthStep from '@modules/inputs/custom/components/steps/4/CreateCustomInterventionFourthStep';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { defineRoute } from '@core/router';
import InterventionCreatePage, { actions } from '@modules/interventions/CreatePage';
import { Intervention } from '@modules/interventions/model';
import CreateCustomInterventionThirdStep from '@modules/inputs/custom/components/steps/3/CreateCustomInterventionThirdStep';

const CustomInterventionCreatePage: FC = () => {
  const input = useParentLoader<typeof loader>('custom-input-detail');

  const checks: Array<Array<FieldPath<Intervention.Create.FormBody>>> = [
    [],
    ['targetedPest', 'triggeringFactor'],
    ['dosage', 'treatment'],
  ];

  return (
    <InterventionCreatePage checks={checks} type={input.customType}>
      <Stepper.Step>
        <CreateCustomInterventionFirstStep input={input} />
      </Stepper.Step>
      <Stepper.Step>
        <CreateInterventionSecondStep name={input.name} type={input.customType} />
      </Stepper.Step>
      <Stepper.Step>
        <CreateCustomInterventionThirdStep input={input} />
      </Stepper.Step>
      <Stepper.Step>
        <CreateCustomInterventionFourthStep />
      </Stepper.Step>
    </InterventionCreatePage>
  );
};

const createCustomInterventionRoute = defineRoute({
  component: CustomInterventionCreatePage,
  actions,
});

export default createCustomInterventionRoute;

import { PageProps } from '@layout/page/Page';
import { Badge, Center, Group, Text, Title } from '@mantine/core';
import { PreserveSearchLink, SharedLink } from '@styles/shared/Link';
import { SharedButton } from '@styles/shared';
import React from 'react';
import { InterventionFromQuery } from '@modules/interventions/model';
import { AmmInput } from '@modules/inputs/amm/model';
import { CustomInput } from '@modules/inputs/custom/model';

export function getDetailPageProps(input: AmmInput | CustomInput, from: InterventionFromQuery): PageProps {
  return {
    title: (
      <>
        <Title>{input.name}</Title>
        <Badge color="blue.5" size="md" fw={500}>
          {input.owner}
        </Badge>
      </>
    ),
    seoTitle: 'Intrant',
    back: {
      title: (
        <Group position="apart" style={{ flex: '1 1 auto' }} noWrap>
          <Title size="h3" color="white">
            Intrant
          </Title>
          <Center>
            <SharedLink
              to="/inputs/search"
              label={
                <Text color="white" weight={500} size="xs" td="underline">
                  Nouvelle recherche
                </Text>
              }
              sx={{ ':hover': { background: 'transparent' } }}
            />
          </Center>
        </Group>
      ),
      to: '/inputs',
    },
    bottomBar: (
      <Center>
        <SharedButton component={PreserveSearchLink} to="new" btnType="create">
          {from === InterventionFromQuery.Search ? 'Créer une intervention' : 'Sélectionner cet intrant'}
        </SharedButton>
      </Center>
    ),
  };
}

import React, { FC } from 'react';
import { Stepper } from '@mantine/core';
import { useParentLoader } from '@core/router/loader';
import { Input } from '@modules/inputs/model';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { defineRoute } from '@core/router';
import { AmmParamsType } from '@modules/inputs/search/model';
import CreateAmmInterventionFourthStep from '@modules/inputs/amm/components/steps/4/CreateAmmInterventionFourthStep';
import InterventionCreatePage, { actions } from '@modules/interventions/CreatePage';
import { loader } from '@modules/inputs/amm/mfsc/routes';
import CreateMfscInterventionFirstStep from '@modules/inputs/amm/mfsc/components/steps/1/CreateMfscInterventionFirstStep';
import { Intervention } from '@modules/interventions/model';
import CreateAmmInterventionThirdStep from '@modules/inputs/amm/components/steps/3/CreateAmmInterventionThirdStep';
import { InputContextProvider } from '@modules/inputs/components/context/InputContextProvider';
import CreateAmmInterventionSecondStep from '@modules/inputs/amm/components/steps/2/CreateAmmInterventionSecondStep';

const NonMfscInterventionCreatePage: FC = () => {
  const input = useParentLoader<typeof loader>('mfsc-input-detail');

  const checks: Array<Array<FieldPath<Intervention.Create.FormBody>>> = [
    ['input'],
    ['targetedPest', 'triggeringFactor'],
    ['dosage', 'treatment'],
  ];

  return (
    <InputContextProvider source={Input.Source.AMM} type={AmmParamsType.Mfsc}>
      {({ params, detail }) => (
        <InterventionCreatePage checks={checks} doseUnit={input.doseUnit} type={input.ammType}>
          <Stepper.Step>
            <CreateMfscInterventionFirstStep params={params} input={input} detail={detail} />
          </Stepper.Step>
          <Stepper.Step>
            <CreateAmmInterventionSecondStep input={input} />
          </Stepper.Step>
          <Stepper.Step>
            <CreateAmmInterventionThirdStep input={input} />
          </Stepper.Step>
          <Stepper.Step>
            <CreateAmmInterventionFourthStep inputId={input.id} />
          </Stepper.Step>
        </InterventionCreatePage>
      )}
    </InputContextProvider>
  );
};

const createMfscInterventionRoute = defineRoute({
  component: NonMfscInterventionCreatePage,
  actions,
});

export default createMfscInterventionRoute;

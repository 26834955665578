import React, { FC } from 'react';
import { Global } from '@mantine/core';

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={theme => ({
        'html, body, #root': {
          width: '100%',
          height: '100%',
          background: theme.colors.background[0],
        },
        '.cookie-consent': {
          lineHeight: 1.3,
          fontFamily: theme.fontFamily,
          '--color-primary': theme.colors.green[7],
        },
      })}
    />
  );
};

export { GlobalStyles };

import z from 'zod';
import { Zone } from '@modules/zones/model';
import { nonEmptyStringSchema } from '@shared/schemas';
import * as R from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';

const gameZoneAreaSchema = z.object({
  type: z.nativeEnum(Zone.Area.GameType),
  value: z.number().min(0),
});

const gameZoneAreaFormBody = gameZoneAreaSchema.extend({
  value: gameZoneAreaSchema.shape.value.nullable(),
});

const gameZoneParamsSchema = z.object({
  name: nonEmptyStringSchema,
  areas: z.array(gameZoneAreaSchema).min(1),
  type: z.literal(Zone.Type.Game),
});

const noGameZoneAreaSchema = z.object({
  type: z.nativeEnum(Zone.Area.NoGameType),
  value: z.number().min(0),
});

export const gameZoneAreasShape = {
  [Zone.Area.GameType.Green]: { schema: gameZoneAreaSchema, required: true, order: 0 },
  [Zone.Area.GameType.Departs]: { schema: gameZoneAreaSchema, required: true, order: 1 },
  [Zone.Area.GameType.Fairways]: { schema: gameZoneAreaFormBody, required: false, order: 2 },
  [Zone.Area.GameType.RoughsEntretenus]: { schema: gameZoneAreaFormBody, required: false, order: 3 },
  [Zone.Area.GameType.RoughsExtensifs]: { schema: gameZoneAreaFormBody, required: false, order: 4 },
  [Zone.Area.GameType.Bunker]: { schema: gameZoneAreaFormBody, required: false, order: 5 },
  [Zone.Area.GameType.TourGreen]: { schema: gameZoneAreaFormBody, required: false, order: 6 },
  [Zone.Area.GameType.AvantGreen]: { schema: gameZoneAreaFormBody, required: false, order: 7 },
} satisfies Record<Zone.Area.GameType, unknown>;

const gameZoneFormBodySchema = z
  .object({
    name: nonEmptyStringSchema,
    type: z.literal(Zone.Type.Game),
  })
  .extend(
    pipe(
      gameZoneAreasShape,
      R.map(({ schema }) => schema),
    ),
  );

const noGameZoneSchema = z.object({
  name: nonEmptyStringSchema,
  area: noGameZoneAreaSchema,
  type: z.literal(Zone.Type.NoGame),
});

export const zoneParamsSchema = z.discriminatedUnion('type', [gameZoneParamsSchema, noGameZoneSchema]);
export const zoneFormBodySchema = z.discriminatedUnion('type', [gameZoneFormBodySchema, noGameZoneSchema]);

import React, { FC } from 'react';
import { VirtualizedListChildrenProps } from '@shared/modules/range';
import { Badge, Card, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconLeaf } from '@tabler/icons-react';
import { renderOptional } from '@shared/utils/render';
import { Operator } from '@modules/operators/model';
import { formatDate, parseDate } from '@shared/modules/dates';
import Role = Operator.Role;

const OperatorListRow: FC<VirtualizedListChildrenProps<Operator.Range.Item>> = ({ item }) => {
  return (
    <Card component={Link} to={item.id} withBorder={false} shadow="0 5px 5px 0 rgba(0, 0, 0, 0.03)">
      <Stack spacing="xs">
        <Group position="apart">
          <Group spacing="xs">
            <ThemeIcon variant="light" color="green.7" size={22}>
              <IconLeaf size={14} />
            </ThemeIcon>
            <Text size="sm">{item.certyphytoNumber}</Text>
          </Group>
          {item.role === Role.Leader && (
            <Badge fw={500} color="blue.5">
              {Operator.roleLabel[item.role]}
            </Badge>
          )}
        </Group>
        <Title size="h3">
          {item.firstName} {item.lastName}
        </Title>

        {renderOptional(parseDate(item.certyphytoExpirationDate), date => (
          <Badge color="gray" w="fit-content" fw={400} tt="none">
            {formatDate(date)}
          </Badge>
        ))}
      </Stack>
    </Card>
  );
};

export default OperatorListRow;

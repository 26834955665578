import { FilterQueryParser, getStringArrayQuery, getStringQuery } from '@shared/modules/filter';
import { Water } from '@modules/water/model';
import * as O from 'fp-ts/Option';
import Index = Water.Index;
import { HttpError, HttpStatusCode } from '@core/http';
import { pipe } from 'fp-ts/function';

export const indexRangeFilterParser: FilterQueryParser<Index.Range.Filter> = query => ({
  startDate: getStringQuery(query, 'startDate'),
  endDate: getStringQuery(query, 'endDate'),
  meterIds: getStringArrayQuery(query, 'meterIds'),
});

export function indexErrorFlashHandler(error: HttpError<Water.Index.Error>) {
  return pipe(
    error,
    O.fromPredicate(error => error.status === HttpStatusCode.CONFLICT),
    O.chain(error => error.data),
    O.fold<Index.Error, boolean | string>(
      () => true,
      error => {
        const { minNumber, maxNumber } = error ?? {};

        if (minNumber != null && maxNumber != null) {
          return `Vos saisies sont incompatibles avec des données existantes. Vous devez saisir un index compris entre ${minNumber} et ${maxNumber}.`;
        } else if (minNumber != null) {
          return `Vos saisies sont incompatibles avec des données existantes. Vous devez saisir un index supérieur à ${minNumber}.`;
        } else if (maxNumber != null) {
          return `Vos saisies sont incompatibles avec des données existantes. Vous devez saisir un index compris entre 0 et ${maxNumber}.`;
        } else {
          return true;
        }
      },
    ),
  );
}

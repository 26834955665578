export enum CookieConsentChoice {
  Statistic = 'statistic',
}

export const cookieConsentChoices: Record<CookieConsentChoice, string> = {
  [CookieConsentChoice.Statistic]: 'Analyse statistique et optimisation',
};

export const COOKIE_CONSENT_COOKIE_NAME = 'cookie-consent-platform-golf';
export const COOKIE_CONSENT_PRIVACY_LINK = 'https://www.platform.garden/cgu-mentions-legales/';

import z, { ZodSchema } from 'zod';
import { Intervention } from '@modules/interventions/model';
import { createNewTypeStringSchema } from '@shared/schemas';
import { Zone } from '@modules/zones/model';
import { LocalDate } from '@shared/modules/dates';
import { Operator } from '@modules/operators/model';
import { Input } from '@modules/inputs/model';
import { OpenData } from '@shared/modules/opendata/model';
import * as P from 'fp-ts/Predicate';

export const createInterventionTreatmentSchema = z.object({
  unit: createNewTypeStringSchema<Intervention.TreatmentUnit>(),
  doseValue: z.number(),
  mushVolumeValue: z.number().nullish(),
}) satisfies ZodSchema<Intervention.Create.Treatment, any, any>;

const interventionEquipment = z.object({
  label: z.string().min(1),
  maintenanceDate: createNewTypeStringSchema<LocalDate>().nullable(),
}) satisfies ZodSchema<Intervention.Equipment, any, any>;

const zoneAreaSchema = z.object({
  // util, see InterventionZoneTreatment useEffect
  zoneId: createNewTypeStringSchema<Zone.Id>().nullable(),
});

const noGameZoneAreaSchema = zoneAreaSchema.extend({
  type: z.literal(Zone.Type.NoGame),
  zoneAreaType: z.nativeEnum(Zone.Area.NoGameType),
});

const gameZoneAreaSchema = zoneAreaSchema.extend({
  type: z.literal(Zone.Type.Game),
  zoneAreaTypes: z.array(z.nativeEnum(Zone.Area.GameType)).min(1),
});

export const zoneDosageSchema = z.object({
  treatmentType: z.literal(Intervention.TreatmentType.Zone),
  zoneId: createNewTypeStringSchema<Zone.Id>(),
  totalZoneAreaValue: z.number(),
  area: z.discriminatedUnion('type', [noGameZoneAreaSchema, gameZoneAreaSchema]),
});

export const volumeDosageSchema = z.object({
  treatmentType: z.literal(Intervention.TreatmentType.Volume),
  volumeValue: z.number(),
});

export const dosageSchema = z.discriminatedUnion('treatmentType', [zoneDosageSchema, volumeDosageSchema]);

const customInterventionSchema = z.object({
  targetedPest: z.string().nullable(),
  triggeringFactor: z.nativeEnum(Intervention.TriggeringFactor).nullable(),
  treatment: createInterventionTreatmentSchema,
  date: createNewTypeStringSchema<LocalDate>(),
  operatorId: createNewTypeStringSchema<Operator.Id>(),
  equipments: z.array(interventionEquipment),
  comments: z.string().nullable(),
  type: z.literal(Input.Source.Custom),
  dosage: dosageSchema,
});

const createInputSchema = z.object({
  inputNameId: Input.NameId.nullable(),
  tradeLicenceId: OpenData.TradeLicence.Id.nullable(),
  usageId: createNewTypeStringSchema<OpenData.UsageId>(),
});

const ammInterventionSchema = z.object({
  targetedPest: z.string().nullable(),
  triggeringFactor: z.nativeEnum(Intervention.TriggeringFactor).nullable(),
  treatment: createInterventionTreatmentSchema,
  date: createNewTypeStringSchema<LocalDate>(),
  operatorId: createNewTypeStringSchema<Operator.Id>(),
  equipments: z.array(interventionEquipment),
  comments: z.string().nullable(),
  dosage: dosageSchema,

  type: z.literal(Input.Source.AMM),
  input: createInputSchema,
  windForce: z.nativeEnum(Intervention.WindForce).nullable(),
  otherMethods: z.string().nullable(),
});

export const updateInterventionSchema = z.object({
  targetedPest: z.string().nullable(),
  triggeringFactor: z.nativeEnum(Intervention.TriggeringFactor).nullable(),
  type: z.nativeEnum(Intervention.TreatmentType),
  zoneId: createNewTypeStringSchema<Zone.Id>().nullable(),
  zoneAreaTypes: z.array(z.nativeEnum(Zone.Area.Type)),
  totalZoneAreaValue: z.number().nullable(),
  volumeValue: z.number().nullable(),
  treatment: createInterventionTreatmentSchema,
  date: createNewTypeStringSchema<LocalDate>(),
  windForce: z.nativeEnum(Intervention.WindForce).nullable(),
  operatorId: createNewTypeStringSchema<Operator.Id>(),
  equipments: z.array(interventionEquipment),
  otherMethods: z.string().nullable(),
  comments: z.string().nullable(),
});

export const updateInterventionUsageSchema = z.object({
  targetedPest: z.string().nullable(),
  triggeringFactor: z.nativeEnum(Intervention.TriggeringFactor),
});

export const updateInterventionDosageSchema = z.object({
  dosage: dosageSchema,
  treatment: createInterventionTreatmentSchema,
});

export const updateInterventionContextSchema = z.object({
  date: createNewTypeStringSchema<LocalDate>(),
  windForce: z.nativeEnum(Intervention.WindForce).nullable(),
  operatorId: createNewTypeStringSchema<Operator.Id>(),
  equipments: z.array(interventionEquipment),
  otherMethods: z.string().nullable(),
  comments: z.string().nullable(),
});

export const updateInterventionBodySchema = updateInterventionUsageSchema
  .merge(updateInterventionDosageSchema)
  .merge(updateInterventionContextSchema);

export const createInterventionSchema = z.discriminatedUnion('type', [customInterventionSchema, ammInterventionSchema]);

export type InterventionDosageFormBody = z.infer<typeof dosageSchema>;

export const triggeringFactorRequired: P.Predicate<Input.Type> = type =>
  [Input.Type.PPP, Input.Type.Melange, Input.Type.ProduitMixte].includes(type);

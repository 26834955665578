import { httpService, HttpTask } from '@core/http';
import { Input } from '../model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as TO from 'fp-ts/TaskOption';
import * as O from 'fp-ts/Option';

const URI = '/inputs/search';

export function searchInputs(params: Input.Search.Filter): HttpTask<Array<Input.View>> {
  return httpService.get(URI, { params });
}
export function searchEmptyInputs(params: Input.Search.EmptyFilter): HttpTask<Array<Input.View>> {
  return pipe(
    TO.fromOption(O.fromNullable(params.search)),
    TO.fold(
      () => TE.of([]),
      search => searchInputs({ ...params, search }),
    ),
  );
}

export function searchInputsUsage(params: Input.SearchUsage.Filter): HttpTask<Input.View.Detail> {
  return httpService.get(`${URI}/usages`, { params });
}

import React, { FC } from 'react';
import Page from '@layout/page/Page';
import { Card, Stack, Text, Title } from '@mantine/core';
import CustomInputInfos from '@modules/inputs/custom/components/detail/CustomInputInfos';
import CustomInputComposition from '@modules/inputs/custom/components/detail/CustomInputComposition';
import { renderNullable } from '@shared/utils/render';
import { loader } from '@modules/inputs/custom/routes';
import { useParentLoader } from '@core/router/loader';
import { getDetailPageProps } from '@modules/inputs/utils';
import { queryFromloader } from '@modules/inputs/components/context/InputLayout';

const InputCustomDetailPage: FC = () => {
  const input = useParentLoader<typeof loader>('custom-input-detail');
  const from = useParentLoader<typeof queryFromloader>('query-from');

  return (
    <Page {...getDetailPageProps(input, from)} bg="background.1">
      <Stack spacing={30}>
        <CustomInputInfos input={input} />
        <CustomInputComposition input={input} />
        {renderNullable(input.comment, comment => (
          <Stack spacing="xs">
            <Title size="h3">Commentaires</Title>
            <Card withBorder={false}>
              <Text lh={1.25}>{comment}</Text>
            </Card>
          </Stack>
        ))}
      </Stack>
    </Page>
  );
};

export default InputCustomDetailPage;

import { RefObject, useCallback, useContext, useRef } from 'react';
import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import {
  EnhancedFormContext,
  EnhancedFormContextValue,
  EnhancedFormExposedMethods,
} from '@shared/modules/form/components/EnhancedForm';
import { FieldValues } from 'react-hook-form';

interface ExternalSubmitValue<Values extends FieldValues> {
  formRef: RefObject<EnhancedFormExposedMethods>;
  form: UseFormReturn<Values>;
  resetFormChanged: () => void;
  handleFormSubmit: () => void;
  showCancelButton: boolean;
}

export function useEnhancedForm<T extends FieldValues>(formProps: UseFormProps<T>): ExternalSubmitValue<T> {
  const form = useForm<T>(formProps);

  const formRef = useRef<EnhancedFormExposedMethods>(null);

  const handleFormSubmit = useCallback(
    () => (formRef.current ? formRef.current.handleSubmit() : console.warn('No form ref to submit !')),
    [],
  );

  return {
    formRef,
    form,
    resetFormChanged: form.reset,
    showCancelButton: form.formState.isDirty,
    handleFormSubmit,
  };
}

export function useEnhancedFormContext<Values extends FieldValues>() {
  return useContext<EnhancedFormContextValue<Values>>(EnhancedFormContext);
}

import React, { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { InputContext, InputContextParams } from '@modules/inputs/components/context/model';
import { defineLoader } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as T from 'fp-ts/Task';
import { getEnumQuery } from '@shared/modules/filter';
import { InterventionFromQuery } from '@modules/interventions/model';
import { defineRoute } from '@core/router';

export const queryFromloader = defineLoader({
  id: 'query-from',
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return T.of(getEnumQuery(queries, InterventionFromQuery, 'from') ?? InterventionFromQuery.Search);
  },
});
const InputLayout: FC = () => {
  const [context, setContext] = useState<InputContext | null>(null);

  return <Outlet context={[context, setContext] satisfies InputContextParams} />;
};

const inputLayoutRoute = defineRoute({
  component: InputLayout,
  loader: queryFromloader,
});
export default inputLayoutRoute;

import React, { createElement, FC, PropsWithChildren } from 'react';
import { Card, Group, Text, ThemeIcon } from '@mantine/core';
import { Icon } from '@tabler/icons-react';

interface NonMfscInputWarningCardProps {
  icon: Icon;
  title: string;
}

const AmmInputWarningCard: FC<PropsWithChildren<NonMfscInputWarningCardProps>> = ({ icon, title, children }) => {
  return (
    <Card withBorder={false}>
      <Group spacing={5} pb="xs">
        <ThemeIcon color="red.6" variant="light" bg="red.1" size={22}>
          {createElement(icon, { size: 14 })}
        </ThemeIcon>
        <Text lh={1.25} color="red.6" weight={500}>
          {title}
        </Text>
      </Group>
      <Text lh={1.25}>{children}</Text>
    </Card>
  );
};

export default AmmInputWarningCard;

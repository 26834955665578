import { NavLink as MantineNavLink } from '@mantine/core';
import { Link, LinkProps, NavLink as RouterNavLink, To, useLocation } from 'react-router-dom';
import { forwardRef, useMemo } from 'react';
import * as S from 'fp-ts/string';

// for typing with Router polymorphic
export const SharedLink = MantineNavLink<typeof RouterNavLink>;

export const PreserveSearchLink = forwardRef<HTMLAnchorElement, LinkProps>(({ to: receivedTo, ...props }, ref) => {
  const location = useLocation();

  const to = useMemo<To>(
    () => (S.isString(receivedTo) ? { ...location, pathname: receivedTo } : { ...location, ...receivedTo }),
    [location, receivedTo],
  );

  return <Link ref={ref} to={to} {...props} />;
});

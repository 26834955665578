import { LocalDate } from '@shared/modules/dates';
import { Input } from '@modules/inputs/model';
import { Newtype } from 'newtype-ts';
import { CustomInput as InputCustom } from '@modules/inputs/custom/model';
import { Zone } from '@modules/zones/model';
import { Operator } from '@modules/operators/model';
import { OpenData } from '@shared/modules/opendata/model';
import { AmmInput as AmmInputNamespace } from '@modules/inputs/amm/model';
import { SearchFilter } from '@shared/modules/filter';
import {
  createInterventionSchema,
  updateInterventionBodySchema,
  updateInterventionContextSchema,
  updateInterventionDosageSchema,
  updateInterventionSchema,
  updateInterventionUsageSchema,
} from '@modules/interventions/schema';
import z from 'zod';

export namespace Intervention {
  export type Id = Newtype<{ readonly ID: unique symbol }, string> & string;
  export type TreatmentUnit = Newtype<{ readonly TREATMENT_UNIT: unique symbol }, string> & string;

  export enum TriggeringFactor {
    Curative = 'curative',
    Preventive = 'preventive',
    EarlyPreventive = 'early-preventive',
    Antigerminative = 'antigerminative',
  }

  export const triggeringFactorLabel: Record<TriggeringFactor, string> = {
    [TriggeringFactor.Curative]: 'Curatif',
    [TriggeringFactor.Preventive]: 'Préventif',
    [TriggeringFactor.EarlyPreventive]: 'Curatif précoce',
    [TriggeringFactor.Antigerminative]: 'Antigerminatif',
  };

  export enum TreatmentType {
    Zone = 'zone',
    Volume = 'volume',
  }

  export const treatmentTypeLabel: Record<TreatmentType, string> = {
    [TreatmentType.Zone]: 'Zone',
    [TreatmentType.Volume]: 'Volume',
  };

  export interface Treatment {
    unit: TreatmentUnit;
    doseValue: number;
    mushVolumeValue: number | null;
    doseTotalQuantity: number | null;
    mushVolumeTotalQuantity: number | null;
  }

  export interface Equipment {
    label: string;
    maintenanceDate: LocalDate | null;
  }

  export interface CustomInput {
    type: InputCustom.Type;
    name: Input.Name;
  }

  export interface AmmInput {
    type: AmmInputNamespace.Type;
    name: string;
    crop: string;
    pest: string | null;
    unit: string | null;
    waitingPeriod: string;
    functions: string | null;
    dose: number | null;
    doseUnit: TreatmentUnit | null;
  }

  export interface Common {
    id: Id;
    targetedPest: string | null;
    triggeringFactor: TriggeringFactor;
    treatmentType: TreatmentType;
    zone: Zone | null;
    zoneAreaTypes: Array<Zone.Area.Type>;
    totalZoneAreaValue: number | null;
    volumeValue: number | null;
    treatment: Treatment;
    date: LocalDate;
    operator: Operator;
    equipments: Array<Equipment>;
    comments: string | null;
  }

  export interface AMM extends Common {
    input: AmmInput;
    otherMethods: string | null;
    windForce: WindForce | null;
    type: Input.Source.AMM;
  }

  export interface Custom extends Common {
    input: CustomInput;
    type: Input.Source.Custom;
  }

  export enum WindForce {
    Zero,
    One,
    Two,
    Three,
    Four,
  }

  export const windForceLabel: Record<WindForce, string> = {
    [WindForce.Zero]: 'CALME',
    [WindForce.One]: 'TRÈS LÉGÈRE BRISE',
    [WindForce.Two]: 'LÉGÈRE BRISE',
    [WindForce.Three]: 'BRISE',
    [WindForce.Four]: 'VENT MODÉRÉ',
  };

  export namespace Create {
    export interface Treatment {
      unit: TreatmentUnit;
      doseValue: number;
      mushVolumeValue?: number | null | undefined;
    }
    export interface Common {
      targetedPest: string | null;
      triggeringFactor: Intervention.TriggeringFactor | null;
      treatmentType: Intervention.TreatmentType;
      zoneId: Zone.Id | null;
      zoneAreaTypes: Array<Zone.Area.Type>;
      totalZoneAreaValue: number | null;
      volumeValue: number | null;
      treatment: Treatment;
      date: LocalDate;
      operatorId: Operator.Id;
      equipments: Array<Equipment>;
      comments: string | null;
    }

    export namespace AMM {
      export interface Input {
        inputNameId: Input.NameId | null;
        tradeLicenceId: OpenData.TradeLicence.Id | null;
        usageId: OpenData.UsageId;
      }
    }
    export interface AMM extends Common {
      input: AMM.Input;
      windForce: WindForce | null;
      otherMethods: string | null;
      type: Input.Source.AMM;
    }

    export interface Custom extends Common {
      type: Input.Source.Custom;
    }

    export namespace FormBody {
      export type AMM = Extract<FormBody, { type: Input.Source.AMM }>;
    }

    export type Params = Create.AMM | Create.Custom;
    export type FormBody = z.infer<typeof createInterventionSchema>;
  }

  export namespace SimulateTreatment {
    export interface Params {
      areaValue: number;
      treatmentUnit: TreatmentUnit;
      doseValue: number;
      mushVolumeValue: number | null;
    }

    export interface Result {
      doseValue: number;
      mushVolumeValue: number | null;
    }
  }

  export namespace Range {
    export interface Item {
      id: Id;
      date: LocalDate;
      name: string;
      inputType: Input.Type;
    }

    export interface Filter extends SearchFilter {
      startDate: LocalDate | null;
      endDate: LocalDate | null;
      inputType: Input.Type | null;
    }
  }

  export namespace Update {
    export namespace FormBody {
      export type Usage = z.infer<typeof updateInterventionUsageSchema>;
      export type Dosage = z.infer<typeof updateInterventionDosageSchema>;
      export type Context = z.infer<typeof updateInterventionContextSchema>;
    }

    export type Params = z.infer<typeof updateInterventionSchema>;

    export type FormBody = z.infer<typeof updateInterventionBodySchema>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Intervention = Intervention.AMM | Intervention.Custom;

export enum InterventionFromQuery {
  Search = 'search',
  Create = 'create',
}

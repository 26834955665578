import React, { FC } from 'react';
import { Card } from '@mantine/core';
import { CustomInput } from '@modules/inputs/custom/model';
import InputSimpleInfo from '@modules/inputs/components/InputSimpleInfo';

interface CustomInputInfosProps {
  input: CustomInput;
}
const CustomInputInfos: FC<CustomInputInfosProps> = ({ input }) => {
  return (
    <Card withBorder={false} py={4}>
      <InputSimpleInfo title="Type intrant">{CustomInput.typeLabel[input.customType]}</InputSimpleInfo>
      <InputSimpleInfo title="Usage">{CustomInput.usageLabel[input.usage]}</InputSimpleInfo>
      <InputSimpleInfo title="Unité">{input.unit}</InputSimpleInfo>
    </Card>
  );
};

export default CustomInputInfos;

import { SSOToken } from '@modules/auth/model';
import * as AuthService from '@modules/auth/service';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import z from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';

const loader = defineLoader({
  params: z.object({ token: createNewTypeStringSchema<SSOToken>() }),
  handler: ({ params }) => httpTaskToResponseTask(AuthService.authenticate(params.token)),
  redirect: () => '/',
});

const authenticateRoute = defineRoute({
  loader,
});

export default authenticateRoute;

import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Water } from '@modules/water/model';
import { Card, Group, Select, SelectItem, Skeleton, Stack, ThemeIcon } from '@mantine/core';
import { Controller } from 'react-hook-form';
import * as MetersService from '@modules/water/meters/service';
import { useFetchTask, useFetchTaskOption } from '@core/http/hooks';
import { flow, identity, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as TE from 'fp-ts/TaskEither';
import { DatePickerInput } from '@mantine/dates';
import { renderHttpRemoteData, renderNullable } from '@root/shared/utils/render';
import { HttpTask } from '@core/http';
import { DateFormat, formatDate, LocalDate, parseDate } from '@shared/modules/dates';
import Index = Water.Index;
import { newTypeWrap } from '@shared/schemas';
import { getSelectDataFromEnumLabels } from '@shared/utils/enum';
import * as E from 'fp-ts/Either';
import SafeNumberInput from '@shared/components/number/SafeNumberInput';
import CustomAlert from '@shared/components/alert/CustomAlert';

function getWaterMeterDescription(id: Water.Meter.Id | null): HttpTask<string | null> {
  return id
    ? pipe(
        MetersService.getWaterMeter(id),
        TE.map(({ description }) => description),
      )
    : TE.right(null);
}

interface IndexFormProps {
  index?: Index;
}
const IndexForm: FC<IndexFormProps> = ({ index }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useEnhancedFormContext<Index.Params>();

  const [meters] = useFetchTaskOption(MetersService.searchWaterMeters);

  const metersData: Array<SelectItem> = pipe(
    meters,
    O.fold(
      () => [],
      A.map(({ id, name }) => ({ value: id, label: name })),
    ),
    index
      ? flow(
          E.fromPredicate(
            A.some(({ value }) => value === index.meterId),
            identity,
          ),
          E.getOrElse(A.prepend({ value: index.meterId, label: index.meterName })),
        )
      : identity,
  );

  const handleDateChange = (value: Date | null) =>
    setValue('date', formatDate(value, DateFormat.LocalDate, newTypeWrap<LocalDate>('')));

  const meterId = watch('meterId');
  const [description] = useFetchTask(getWaterMeterDescription, meterId);

  const handleDrynessChange = (value: `${Water.DrynessLevel}` | null) => value && setValue('drynessLevel', +value);

  return (
    <form>
      <Stack>
        <Controller
          control={control}
          name="meterId"
          render={({ field }) => (
            <Select
              {...field}
              error={!!errors.meterId}
              data={metersData}
              placeholder="Sélectionner"
              label="Compteur"
              required
            />
          )}
        />
        {renderHttpRemoteData(
          description,
          description => renderNullable(description, description => <Card bg="gray.1">{description}</Card>),
          () => (
            <Skeleton height={50} />
          ),
        )}
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DatePickerInput
              {...field}
              error={!!errors.date}
              value={parseDate(field.value, DateFormat.LocalDate, null)}
              placeholder="Sélectionner"
              onChange={handleDateChange}
              label="Date"
              required
            />
          )}
        />

        <Controller
          control={control}
          name="number"
          render={({ field }) => (
            <Group spacing="xs">
              <SafeNumberInput
                {...field}
                error={!!errors.number}
                placeholder="Sélectionner"
                label="Index"
                min={0}
                required
                style={{ flex: 'auto' }}
              />
              <ThemeIcon fw={500} color="background.4" c="dark.8" radius={8} size="xl" mt="auto">
                m³
              </ThemeIcon>
            </Group>
          )}
        />

        <CustomAlert color="red" bg="red.1">
          Pour rappel nous vous demandons de saisir uniquement le chiffre visible sur le compteur et non pas de saisir
          votre consommation.
        </CustomAlert>

        <Controller
          control={control}
          name="drynessLevel"
          render={({ field }) => (
            <Select
              {...field}
              value={`${field.value}`}
              onChange={handleDrynessChange}
              error={!!errors.drynessLevel}
              data={getSelectDataFromEnumLabels(Water.drynessLevelLabel)}
              placeholder="Sélectionner"
              label="Niveau d'alerte sécheresse"
              required
            />
          )}
        />
      </Stack>
    </form>
  );
};

export default IndexForm;

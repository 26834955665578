import React, { FC, useCallback } from 'react';
import { Box } from '@mantine/core';
import { Input } from '@modules/inputs/model';
import AmmInterventionContextForm from '@modules/interventions/components/form/context/AmmInterventionContextForm';
import * as InterventionsService from '@modules/interventions/service';
import { LocalDate } from '@shared/modules/dates';

interface CreateAmmInterventionFourthStepProps {
  inputId: Input.Id;
}
const CreateAmmInterventionFourthStep: FC<CreateAmmInterventionFourthStepProps> = ({ inputId }) => {
  const getOperators = useCallback(
    (interventionDate: LocalDate) => InterventionsService.getInterventionInputOperators(inputId, interventionDate),
    [inputId],
  );

  return (
    <Box px="lg" pt="lg">
      <AmmInterventionContextForm getOperators={getOperators} />
    </Box>
  );
};

export default CreateAmmInterventionFourthStep;

import z from 'zod';
import { Water } from '@modules/water/model';
import Meter = Water.Meter;
import { nonEmptyStringSchema } from '@shared/schemas';

export const meterSchema = z.object({
  name: nonEmptyStringSchema,
  sourceType: z.nativeEnum(Meter.SourceType),
  serialNumber: nonEmptyStringSchema,
  description: z.string().nullable(),
});

export const activateMeterSchema = z.object({
  value: z.boolean(),
});

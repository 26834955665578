import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Group, Select, Text, TextInput } from '@mantine/core';
import { SharedLink } from '@styles/shared/Link';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Intervention } from '@modules/interventions/model';
import { Input } from '@modules/inputs/model';
import { SelectItem } from '@mantine/core/lib/Select/types';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { AmmInput } from '@modules/inputs/amm/model';
import { renderNullable } from '@shared/utils/render';

interface AmmInputNameSelectionProps {
  input: AmmInput;
  names: Array<Input.View.Name>;
}

const AmmInputNameSelection: FC<AmmInputNameSelectionProps> = ({ input, names }) => {
  const {
    control,
    formState: { errors },
  } = useEnhancedFormContext<Intervention.Create.FormBody.AMM>();

  const namesData: Array<SelectItem> = pipe(
    names.filter((input): input is { id: Input.NameId; value: Input.Name } => !!input.id),
    A.map(({ id, value }) => ({ value: id, label: value })),
  );

  return renderNullable(
    input.tradeLicence,
    ({ name }) => <TextInput label="Nom de l'intrant" value={name} mb="xl" required disabled />,
    () => (
      <>
        <Controller
          control={control}
          name="input.inputNameId"
          render={({ field }) => (
            <Select
              {...field}
              error={!!errors.input?.inputNameId}
              data={namesData}
              label="Nom de l'intrant"
              placeholder="Sélectionner"
              searchable
              required
            />
          )}
        />
        <Group position="right">
          <Box>
            <SharedLink
              label={
                <Text color="dark.3" weight={500} td="underline">
                  Modifier l'intrant
                </Text>
              }
              to="/inputs/search"
            />
          </Box>
        </Group>
      </>
    ),
  );
};

export default AmmInputNameSelection;

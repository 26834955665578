import axios from 'axios';

import { v4 as uuid } from 'uuid';
import { stringifyQueries } from '@shared/utils/queries';
import { TRANSACTION_ID_HEADER_KEY } from '@core/http/model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import config from '@root/config';
import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';

import * as AuthService from '@modules/auth/service';
import { sequenceS } from 'fp-ts/Apply';

const baseURL = pipe(
  O.tryCatch(() => new URL(config.VITE_API_PREFIX, config.VITE_API_URL).toString()),
  O.filter(() => import.meta.env.PROD),
  O.getOrElse(() => config.VITE_API_PREFIX),
);

export const defaultAxiosInstance = axios.create({
  paramsSerializer: { serialize: stringifyQueries },
  withCredentials: true,
  baseURL,
});

defaultAxiosInstance.interceptors.request.use(config => {
  const authHeaderTask = pipe(
    AuthService.getAuthTokens(),
    TO.fold(
      () => T.of({}),
      tokens => T.of({ Bearer: tokens.token }),
    ),
  );

  const transactionIdTask = T.fromIO(() => ({ [TRANSACTION_ID_HEADER_KEY]: uuid() }));

  return pipe(
    sequenceS(T.ApplyPar)({
      authHeader: authHeaderTask,
      transactionIdHeader: transactionIdTask,
    }),
    T.map(({ authHeader, transactionIdHeader }) => ({
      ...config,
      headers: {
        ...config.headers,
        ...authHeader,
        ...transactionIdHeader,
      },
    })),
  )();
});

import { RangeCursor, VirtualizedListProps } from '@shared/modules/range';
import React, { CSSProperties, FC, useEffect } from 'react';
import { renderOptional } from '@shared/utils/render';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { Box, Text } from '@mantine/core';
import { useThrottledCallback } from 'use-debounce';
import { isFilterEmpty } from '@shared/modules/filter';

interface LoadingRowProps {
  index: number;
  style: CSSProperties;
  loadRow: (index: number) => void;
}

const LoadingRow: FC<LoadingRowProps> = ({ index, style, loadRow }) => {
  useEffect(() => {
    loadRow(index);
  });

  return <div style={style} />;
};

export function VirtualizedList<T>({
  header,
  range,
  children,
  rowHeight = 55,
  emptyRender,
  loadPage,
  gap = 10,
}: VirtualizedListProps<T>) {
  const virtualizer = useWindowVirtualizer({
    count: range.total,
    estimateSize: () => rowHeight,
    overscan: 10,
  });

  useEffect(() => {
    if (range.total > 0) {
      virtualizer.scrollToIndex(0);
    }
  }, [range.total, virtualizer]);

  const handleLoadRow = (index: number) => {
    if (!range.has(index)) {
      loadPage(Math.floor(index / RangeCursor.DEFAULT_SIZE) + 1);
    }
  };

  // Throttle to wait state refresh
  const throttleLoadRow = useThrottledCallback(handleLoadRow, 250);

  return (
    <Box>
      {header}

      {range.total === 0 ? (
        emptyRender ? (
          isFilterEmpty(range.filter) && emptyRender
        ) : (
          <Text py="lg" size="sm" color="gray.8" fs="italic">
            Aucun élément à afficher
          </Text>
        )
      ) : (
        <div style={{ position: 'relative', height: virtualizer.getTotalSize(), width: '100%' }}>
          {virtualizer.getVirtualItems().map(row => {
            const style: CSSProperties = {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              minHeight: 55,
              transform: `translateY(${row.start}px)`,
            };

            return renderOptional(
              range.get(row.index),
              item => (
                <Box
                  key={row.key}
                  ref={virtualizer.measureElement}
                  data-index={row.index}
                  style={style}
                  sx={{
                    ':not(:last-child)': {
                      paddingBottom: gap,
                    },
                  }}
                >
                  {children({ row, item })}
                </Box>
              ),
              () => <LoadingRow key={row.key} style={style} index={row.index} loadRow={throttleLoadRow} />,
            );
          })}
        </div>
      )}
    </Box>
  );
}

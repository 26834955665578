import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as InterventionsService from '@modules/interventions/service';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { defineAction, useAction } from '@core/router/action';
import { Intervention } from '@modules/interventions/model';
import { updateInterventionSchema } from '@modules/interventions/schema';
import UpdateInterventionForm from '@modules/interventions/components/form/UpdateInterventionForm';
import * as EI from 'fp-ts/Either';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { Button, Group } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import InterventionDetailHeader from '@modules/interventions/components/form/header/InterventionDetailHeader';

const params = z.object({ id: createNewTypeStringSchema<Intervention.Id>() });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(InterventionsService.getIntervention(params.id)),
});

export const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: updateInterventionSchema,
    handler: ({ payload, params }) => InterventionsService.updateIntervention(params.id, payload),
    flashOptions: {
      success: () => "L'intervention a été modifiée avec succès",
    },
  }),
  delete: defineAction({
    type: 'delete',
    params,
    handler: ({ params }) => InterventionsService.deleteIntervention(params.id),
    flashOptions: {
      success: () => "L'intervention a bien été supprimée.",
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/interventions' : null),
  }),
};

const InterventionDetailPage: FC = () => {
  const intervention = useLoader<typeof loader>();

  const [deleteLoading, deleteIndex] = useAction(actions.delete);

  const pageProps: PageProps = {
    seoTitle: `Votre intervention du ${intervention.date}`,
    back: {
      title: `Votre intervention du ${intervention.date}`,
      to: '/interventions',
    },
    bottomBar: (
      <Group>
        <DeleteModal onDelete={deleteIndex} loading={deleteLoading} />
        <Button component={Link} to="duplicate" relative="path" variant="light" leftIcon={<IconCopy />}>
          Dupliquer
        </Button>
      </Group>
    ),
  };

  return (
    <Page {...pageProps} bg="background.1" p={0}>
      <InterventionDetailHeader intervention={intervention} />

      <UpdateInterventionForm intervention={intervention} />
    </Page>
  );
};

const interventionDetailRoute = defineRoute({
  component: InterventionDetailPage,
  loader,
  actions,
});

export default interventionDetailRoute;

import { Input } from '@modules/inputs/model';
import * as A from 'fp-ts/Array';
import { newTypeWrap } from '@shared/schemas';
import Source = Input.Source;
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { AmmParamsType, InputParams } from '@modules/inputs/search/model';
import { stringifyQueries } from '@shared/utils/queries';
import { createPath } from 'history';
import { ParsedQuery } from 'query-string';

export function initInputFormBody(detail: Input.View.Detail): InputParams | null {
  switch (detail.source) {
    case Source.Custom:
      return { source: detail.source };
    case Source.AMM:
      if (A.isNonEmpty(detail.mfsc)) {
        return {
          source: detail.source,
          type: AmmParamsType.Mfsc,
          usage: { usageId: newTypeWrap(''), unit: '' },
          crop: { name: '', pests: [] },
        };
      } else if (A.isNonEmpty(detail.nonMfsc)) {
        return {
          source: detail.source,
          type: AmmParamsType.NonMfsc,
          usage: { usageId: newTypeWrap(''), unit: '' },
          pest: { name: '', usages: [] },
          crop: { name: '', pests: [] },
        };
      } else {
        // todo log error
        return null;
      }
  }
}

export function getDefaultName({ inputId, source, nameId }: Input.View.Detail) {
  return pipe([inputId, source, nameId], A.filterMap(O.fromNullable)).join('|');
}

export function getDefaultSearch({ names, nameId }: Input.View.Detail) {
  return pipe(
    names,
    A.findFirst(({ id }) => id === nameId),
    O.map(({ value }) => value),
    O.toNullable,
  );
}

export function getSuccessTo(detail: Input.View.Detail, params: InputParams, queries: ParsedQuery): string {
  function getPathname() {
    switch (params.source) {
      case Input.Source.Custom:
        return `/inputs/custom/${detail.inputId}`;
      case Input.Source.AMM:
        switch (params.type) {
          case AmmParamsType.NonMfsc:
            return `/inputs/amm/non-mfsc/${detail.inputId}/${params.usage.usageId}`;
          case AmmParamsType.Mfsc:
            return `/inputs/amm/mfsc/${detail.inputId}/${params.usage.usageId}`;
        }
    }
  }

  return createPath({ pathname: getPathname(), search: stringifyQueries(queries) });
}

import React, { FC } from 'react';
import router from 'routes';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from '@styles/global';
import MantineTheme from '@styles/mantine';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { NavigationProgress } from '@mantine/nprogress';
import { RouterProvider } from 'react-router-dom';
import CookieConsent from '@innovantic/cookie-consent';
import {
  COOKIE_CONSENT_COOKIE_NAME,
  COOKIE_CONSENT_PRIVACY_LINK,
  cookieConsentChoices,
} from '@shared/modules/analytics/model';

import '@innovantic/cookie-consent/dist/style.css';

const App: FC = () => {
  return (
    <SentryProvider>
      <MantineTheme>
        <GlobalStyles />

        <NavigationProgress color="blue" size={2} />

        <HelmetProvider>
          <CookieConsent
            choices={cookieConsentChoices}
            cookieName={COOKIE_CONSENT_COOKIE_NAME}
            privacyLink={COOKIE_CONSENT_PRIVACY_LINK}
          >
            <RouterProvider router={router} />
          </CookieConsent>
        </HelmetProvider>
      </MantineTheme>
    </SentryProvider>
  );
};

export default App;

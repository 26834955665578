import React, { FC } from 'react';
import { Select, Stack, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { getSelectDataFromEnumLabels } from '@shared/utils/enum';
import { Intervention } from '@modules/interventions/model';
import { useEnhancedFormContext } from '@shared/modules/form';

const UpdateInterventionUsageForm: FC = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useEnhancedFormContext<Intervention.Update.FormBody.Usage>();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack>
        <TextInput label="Organisme nuisible visé" placeholder="Saisir" {...register('targetedPest')} />
        <Controller
          control={control}
          name="triggeringFactor"
          render={({ field }) => (
            <Select
              {...field}
              error={!!errors.triggeringFactor}
              label="Facteur déclenchant"
              placeholder="Sélectionner"
              data={getSelectDataFromEnumLabels(Intervention.triggeringFactorLabel)}
              required
              withinPortal
            />
          )}
        />
      </Stack>
    </form>
  );
};

export default UpdateInterventionUsageForm;

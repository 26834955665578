import { AxiosResponse } from 'axios';
import { IO } from 'fp-ts/IO';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

const regExpFilename = /filename="(?<filename>.*)"/;

export function extractFileName(response: AxiosResponse<Blob>): string | null {
  return pipe(
    O.fromNullable(response.headers['content-disposition']),
    O.filterMap(contentDisposition => (typeof contentDisposition === 'string' ? O.some(contentDisposition) : O.none)),
    O.chainNullableK(contentDisposition => regExpFilename.exec(contentDisposition)?.groups?.filename),
    O.toNullable,
  );
}

export function downloadFile(response: AxiosResponse<Blob>): IO<void> {
  return () => {
    const fileName = extractFileName(response);

    const url = window.URL.createObjectURL(response.data);

    const link = document.createElement('a');

    link.href = url;

    if (fileName) {
      link.download = fileName;
    }

    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
}

import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import reportRoute from '@modules/reports/ReportPage';
import WaterReportListPage from '@modules/reports/water/ListPage';

const waterReportRoutes = (
  <Route path="water">
    <Route index element={<WaterReportListPage />} />
    <Route path=":code" {...createRoute(reportRoute)} />
  </Route>
);

export default waterReportRoutes;

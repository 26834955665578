import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import { getStringQuery } from '@shared/modules/filter';
import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import SuccessPage from '@shared/components/success-page/SuccessPage';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return httpTaskToResponseTask(TE.fromNullable(HttpError.notFound)(getStringQuery(queries, 'meterName')));
  },
});
const IndexSuccessPage: FC = () => {
  const meterName = useLoader<typeof loader>();

  return (
    <SuccessPage
      description={`Un nouvel index a bien été créé pour le compteur ${meterName}, vous pouvez désormais le consulter depuis la liste.`}
      to="/water/indexes"
    >
      Consulter les index
    </SuccessPage>
  );
};

const indexSuccessRoute = defineRoute({
  component: IndexSuccessPage,
  loader,
});
export default indexSuccessRoute;

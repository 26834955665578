import { Newtype } from 'newtype-ts';

export type SSOToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;
export type AuthToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;
export type RefreshToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;

export enum CompanyUserRole {
  Admin = 'admin',
  Tech = 'tech',
  Communication = 'communication',
  Marketing = 'marketing',
  Agref = 'agref',
  Fredon = 'fredon',
  Unep = 'unep',
}

export interface Profile<Role extends CompanyUserRole = CompanyUserRole> {
  email: string;
  firstName: string | null;
  lastName: string | null;
  companyLabel: string | null;
  role: Role;
}

export interface AuthTokens {
  token: AuthToken;
  refreshToken: RefreshToken;
}

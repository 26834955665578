import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as MetersService from '@modules/water/meters/service';
import { defineAction, useAction } from '@core/router/action';
import * as EI from 'fp-ts/Either';
import { Water } from '@modules/water/model';
import { activateMeterSchema, meterSchema } from '@modules/water/meters/schema';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import Page, { PageProps } from '@layout/page/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Group, Modal, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { SharedButton } from '@styles/shared';
import MeterStateLabel from '@modules/water/meters/components/state/MeterStateLabel';
import MeterForm from '@modules/water/meters/components/form/MeterForm';
import { IconDashboard } from '@tabler/icons-react';
import { rgba } from 'polished';
import { useDisclosure } from '@mantine/hooks';
import { useHasRole } from '@modules/auth/hooks';
import { CompanyUserRole } from '@modules/auth/model';
import Meter = Water.Meter;

const meterIdSchema = z.object({ id: createNewTypeStringSchema<Meter.Id>() });

const loader = defineLoader({
  params: meterIdSchema,
  handler: ({ params }) => httpTaskToResponseTask(MetersService.getWaterMeter(params.id)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params: meterIdSchema,
    payload: meterSchema,
    handler: ({ payload, params }) => MetersService.updateWaterMeter(params.id, payload),
    flashOptions: {
      success: () => 'Les modifications apportées à ce compteur ont été appliquées à l’historique de vos saisies',
    },
  }),
  delete: defineAction({
    type: 'delete',
    params: meterIdSchema,
    handler: ({ params }) => MetersService.deleteWaterMeter(params.id),
    flashOptions: {
      success: () => 'Le compteur a bien été supprimé.',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/water/meters' : null),
  }),
  activate: defineAction({
    type: 'activate',
    params: meterIdSchema,
    payload: activateMeterSchema,
    handler: ({ params, payload }) => MetersService.toggleActivationWaterMeter(params.id, payload.value),
    flashOptions: {
      success: ({ payload }) => `Le compteur a bien été ${payload.value ? 'activé' : 'désactivé'}.`,
    },
  }),
};

const MeterDetailPage: FC = () => {
  const meter = useLoader<typeof loader>();
  const isAdmin = useHasRole(CompanyUserRole.Admin);
  const [updateLoading, updateMeter] = useAction(actions.update);
  const [deleteLoading, deleteMeter] = useAction(actions.delete);
  const [activateLoading, activateMeter] = useAction(actions.activate);
  const [modalOpen, handlers] = useDisclosure(false);
  const isActivated = meter.activationState === Meter.State.Enabled;

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Meter.Params>({
    resolver: zodResolver(meterSchema),
    defaultValues: {
      name: meter.name,
      description: meter.description,
      serialNumber: meter.serialNumber,
      sourceType: meter.sourceType,
    },
  });

  const pageProps: PageProps = {
    seoTitle: `Compteur ${meter.serialNumber}`,
    back: {
      title: (
        <Group position="apart" style={{ flex: '1 1 auto' }} noWrap>
          <Title size="h3" color="white">
            Compteur {meter.serialNumber}
          </Title>
          <MeterStateLabel activationState={meter.activationState} />
        </Group>
      ),
      to: '/water/meters',
    },
    bottomBar: isAdmin ? (
      <Group position="center" spacing={8}>
        <DeleteModal onDelete={deleteMeter} loading={deleteLoading} />
        <SharedButton btnType="save" loading={updateLoading} onClick={handleFormSubmit}>
          Enregistrer
        </SharedButton>
      </Group>
    ) : undefined,
  };

  const handleUpdateMeter = (params: Meter.Params) => () => updateMeter(params);

  const handleToggleActivation = () => {
    handlers.close();
    activateMeter({ value: !isActivated });
  };

  return (
    <>
      <Page {...pageProps} bg="background.1">
        <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateMeter} preventLeave>
          <MeterForm readonly={!isAdmin} />
          {isAdmin && (
            <Button
              w="fit-content"
              mt={33}
              color={isActivated ? 'dark.3' : 'blue.8'}
              variant="light"
              leftIcon={<IconDashboard size={18} />}
              onClick={handlers.open}
              loading={activateLoading}
              sx={theme => ({ background: rgba(isActivated ? theme.colors.dark[3] : theme.colors.blue[8], 0.15) })}
            >
              {isActivated ? 'Désactiver' : 'Activer'} le compteur
            </Button>
          )}
        </EnhancedForm>
      </Page>
      <Modal opened={modalOpen} onClose={handlers.close} centered>
        <Stack align="center" ta="center">
          {isActivated ? (
            <>
              <Title size="h2">Désactiver un compteur</Title>
              <Text lh={1.25}>
                Si vous désactivez le compteur {meter.name}, vous ne pourrez plus y avoir accès depuis la saisie
                d’index. Si vous changez d’avis, vous pourrez le réactiver depuis son détail.
              </Text>
              <Stack>
                <Button loading={activateLoading} onClick={handleToggleActivation} color="red.5">
                  Désactiver le compteur
                </Button>
                <UnstyledButton onClick={handlers.close} c="dark.3" ta="center" td="underline" fw={500}>
                  Annuler
                </UnstyledButton>
              </Stack>
            </>
          ) : (
            <>
              <Title size="h2">Activer un compteur</Title>
              <Text lh={1.25}>
                Si vous activez le compteur {meter.name}, il sera disponible depuis la saisie d’index.
              </Text>
              <Stack>
                <Button loading={activateLoading} onClick={handleToggleActivation} color="green.7">
                  Activer le compteur
                </Button>
                <UnstyledButton onClick={handlers.close} c="dark.3" ta="center" td="underline" fw={500}>
                  Annuler
                </UnstyledButton>
              </Stack>
            </>
          )}
        </Stack>
      </Modal>
    </>
  );
};

const meterDetailRoute = defineRoute({
  component: MeterDetailPage,
  loader,
  actions,
});

export default meterDetailRoute;

import React, { FC } from 'react';

import { ModalContent, SharedButton } from '@styles/shared';
import { Group, Modal, Text } from '@mantine/core';

interface FormPreventLeaveModalProps {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const FormPreventLeaveModal: FC<FormPreventLeaveModalProps> = ({ open, onClose, onLeave }) => {
  return (
    <Modal
      opened={open}
      onClose={onClose}
      centered
      size={500}
      title={
        <Text size="lg" weight="600">
          Modifications en cours
        </Text>
      }
    >
      <ModalContent>
        <Text size="sm">
          Si vous quittez maintenant cette page, vos modifications en cours ne seront pas enregistrées.
        </Text>
      </ModalContent>

      <Group position="right">
        <SharedButton btnType="cancel" children="Annuler" onClick={onClose} />
        <SharedButton btnType="save" children="Ignorer" onClick={onLeave} />
      </Group>
    </Modal>
  );
};

export default FormPreventLeaveModal;

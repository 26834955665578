import React, { forwardRef, useEffect, useState } from 'react';
import { Anchor, Box, SpoilerProps, useComponentDefaultProps } from '@mantine/core';
import useStyles from './LineClampSpoiler.styles';

import { useEnhancedElementSize } from '@shared/components/spoiler/hooks';

interface LineClampSpoilerProps
  extends Omit<SpoilerProps, 'maxHeight' | 'hideLabel' | 'showLabel' | 'transitionDuration'> {
  lineClamp?: number;
  hideLabel?: string;
  showLabel?: string;
}

const defaultProps: Partial<LineClampSpoilerProps> = {
  lineClamp: 4,
  showLabel: 'Voir plus',
  hideLabel: 'Voir moins',
};

// src/mantine-core/src/Spoiler/Spoiler.tsx
const LineClampSpoiler = forwardRef<HTMLDivElement, LineClampSpoilerProps>((props, ref) => {
  const {
    initialState = false,
    className,
    children,
    hideLabel,
    showLabel,
    controlRef,
    lineClamp,
    classNames,
    styles,
    unstyled,
    ...others
  } = useComponentDefaultProps<LineClampSpoilerProps>('LineClampSpoiler', defaultProps, props);

  const { classes, cx } = useStyles(undefined, { classNames, styles, unstyled, name: 'LineClampSpoiler' });

  const [show, setShowState] = useState<boolean>(initialState);
  const [spoiler, setSpoilerState] = useState<boolean>(initialState);
  const { ref: contentRef, offset, scroll } = useEnhancedElementSize();
  const spoilerMoreContent = show ? hideLabel : showLabel;

  useEffect(() => {
    if (!show) setSpoilerState(offset.height < scroll.height || offset.width < scroll.width);
  }, [offset, children, scroll, show]);

  return (
    <Box className={cx(classes.root, className)} ref={ref} {...others}>
      <div className={classes.content}>
        <div
          ref={contentRef}
          className={classes.content}
          style={
            show
              ? {
                  maxHeight: scroll.height,
                }
              : {
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: lineClamp,
                  overflow: 'hidden',
                  whiteSpace: 'initial',
                }
          }
        >
          {children}
        </div>
      </div>

      {spoiler && (
        <Anchor
          component="button"
          ref={controlRef}
          onClick={() => setShowState(opened => !opened)}
          className={classes.control}
        >
          {spoilerMoreContent}
        </Anchor>
      )}
    </Box>
  );
});

export default LineClampSpoiler;

import { Input } from '@modules/inputs/model';
import { LocalDate } from '@shared/modules/dates';
import { OpenData } from '@shared/modules/opendata/model';
import { Intervention } from '@modules/interventions/model';
import TreatmentUnit = Intervention.TreatmentUnit;
import { NonEmptyString } from '@shared/schemas';

export interface RiskPhrase {
  code: string;
  phase: string;
}

export namespace AmmInput {
  export enum Type {
    ProduitMixte = 'PRODUIT-MIXTE',
    Melange = 'MELANGE',
    Mfsc = 'MFSC',
    Ppp = 'PPP',
    Adjuvant = 'ADJUVANT',
  }

  export const typeLabel: Record<Type, string> = {
    [Type.ProduitMixte]: 'Produits mixtes',
    [Type.Melange]: 'Mélange',
    [Type.Mfsc]: 'MFSC et Biostimulants',
    [Type.Ppp]: 'PPP',
    [Type.Adjuvant]: 'Adjuvants',
  };

  export enum AuthorizationState {
    Remove = 'RETIRE',
    Authorized = 'AUTORISE',
  }

  export const authorizationStateLabel: Record<AuthorizationState, string> = {
    [AuthorizationState.Authorized]: 'Autorisé',
    [AuthorizationState.Remove]: 'Retiré',
  };

  export const authorizationStateDate = {
    [AuthorizationState.Authorized]: 'firstAuthorizationDate',
    [AuthorizationState.Remove]: 'cancellationDate',
  } satisfies Record<AuthorizationState, keyof AmmInput>;

  export interface TradeLicence {
    id: OpenData.TradeLicence.Id;
    name: Input.Name;
    licenceNumber: OpenData.TradeLicence.Number;
    owner: NonEmptyString;
  }
}

export interface AmmInput {
  id: Input.Id;
  ammNumber: OpenData.AmmNumber;
  source: Input.Source.AMM;
  name: Input.Name;
  secondaryNames: string | null;
  tradeLicence: AmmInput.TradeLicence | null;
  owner: string;
  ammType: AmmInput.Type;
  authorizationState: AmmInput.AuthorizationState;
  cancellationDate: LocalDate | null;
  firstAuthorizationDate: LocalDate | null;
  activeSubstance: string | null;
  waitingPeriod: string;
  functions: string | null;
  physicalState: string | null;
  decisionDate: LocalDate | null;
  dose: number | null;
  doseUnit: TreatmentUnit | null;
  crop: string;
  hazardPhrases: Array<RiskPhrase>;
  riskPhrases: Array<RiskPhrase>;
  epis: Array<string>;
  quickFdsLink: string | null;
}

import { Input } from '@modules/inputs/model';
import { Percentage } from '@shared/modules/number/model';
import { Newtype } from 'newtype-ts';

export namespace CustomInput {
  export type Unit = Newtype<{ readonly UNIT: unique symbol }, string> & string;
  export enum Type {
    Adjuvant = 'ADJUVANT',
    Melange = 'MELANGE',
    MFSC = 'MFSC',
    PPP = 'PPP',
    ProduitMixte = 'PRODUIT-MIXTE',
    CorrecteurPH = 'CorrecteurPH',
    Fertilisant = 'Fertilisant',
    ListeCE = 'ListeCE',
    Macroorganisme = 'Macroorganisme',
    Pigment = 'Pigment',
    SubstratMineral = 'SubstratMineral',
    SupportCulture = 'SupportCulture',
  }

  export const typeLabel: Record<Type, string> = {
    [Type.Adjuvant]: 'Adjuvant',
    [Type.Melange]: 'Mélange',
    [Type.MFSC]: 'MFSC',
    [Type.PPP]: 'PPP',
    [Type.ProduitMixte]: 'Produit - mixte',
    [Type.CorrecteurPH]: 'Correcteur de PH',
    [Type.Fertilisant]: 'Fertilisant',
    [Type.ListeCE]: 'Liste CE 2021 - 1104',
    [Type.Macroorganisme]: 'Macroorganisme',
    [Type.Pigment]: 'Pigment',
    [Type.SubstratMineral]: 'Substrat minéral',
    [Type.SupportCulture]: 'Support de culture',
  };

  export enum Usage {
    WetingAgent = 'weting-agent',
    BioStimulant = 'biostimulant',
    Fertilization = 'fertilization',
    IntegratedPestManagement = 'integrated-pest-management',
    SoilImprovement = 'soil-improvement',
    TopDressing = 'top-dressing',
    SoilLife = 'soil-life',
  }

  export const usageLabel: Record<Usage, string> = {
    [Usage.WetingAgent]: 'Agent mouillant CE',
    [Usage.BioStimulant]: 'Biostimulant CE',
    [Usage.Fertilization]: 'Fertilisation',
    [Usage.IntegratedPestManagement]: 'Lutte intégrée',
    [Usage.SoilImprovement]: 'Amélioration sol',
    [Usage.TopDressing]: 'Top dressing',
    [Usage.SoilLife]: 'Vie du sol',
  };
}

export interface CustomInput {
  id: Input.Id;
  source: Input.Source.Custom;
  name: Input.Name;
  owner: string;
  customType: CustomInput.Type;
  usage: CustomInput.Usage;
  unit: CustomInput.Unit;
  physicalState: string | null;
  organicCompliant: boolean;
  totalN: Percentage | null;
  lateN: Percentage | null;
  organicN: Percentage | null;
  totalP: Percentage | null;
  lateP: Percentage | null;
  organicP: Percentage | null;
  totalK: Percentage | null;
  lateK: Percentage | null;
  organicK: Percentage | null;
  magnesia: number;
  sulfur: boolean;
  calcium: boolean;
  boron: boolean;
  iron: boolean;
  manganese: boolean;
  copper: boolean;
  molybdenum: boolean;
  zinc: boolean;
  agronomicAdditive: boolean;
  macroOrganism: boolean;
  ceramic: boolean;
  zeolite: boolean;
  otherRawMaterial: boolean;
  comment: string | null;
}

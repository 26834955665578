import z from 'zod';
import { createNewTypeStringSchema, nonEmptyStringSchema } from '@shared/schemas';
import { Operator } from '@modules/operators/model';
import { LocalDate } from '@shared/modules/dates';

export const operatorSchema = z.object({
  lastName: nonEmptyStringSchema,
  firstName: nonEmptyStringSchema,
  certyphytoNumber: createNewTypeStringSchema<Operator.CertyphytoNumber>(),
  certyphytoExpirationDate: createNewTypeStringSchema<LocalDate>(),
  role: z.nativeEnum(Operator.Role),
});

import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import React from 'react';
import indexesListRoute from '@modules/water/indexes/ListPage';
import createIndexRoute from '@modules/water/indexes/CreatePage';
import indexDetailRoute from '@modules/water/indexes/DetailPage';
import indexSuccessRoute from '@modules/water/indexes/SuccessPage';

const indexesRoutes = (
  <Route path="indexes">
    <Route index {...createRoute(indexesListRoute)} />
    <Route path=":id" {...createRoute(indexDetailRoute)} />
    <Route path="new" {...createRoute(createIndexRoute)} />
    <Route path="success" {...createRoute(indexSuccessRoute)} />
  </Route>
);

export default indexesRoutes;

import React, { ChangeEventHandler, FC, useMemo, useState } from 'react';
import {
  Anchor,
  Box,
  Center,
  Checkbox,
  Group,
  Loader,
  Select,
  Stack,
  Text,
  ThemeIcon,
  SelectItem,
} from '@mantine/core';
import { Input } from '@modules/inputs/model';
import { useDebouncedValue } from '@mantine/hooks';
import { useFetchTaskOption, useSendTask } from '@core/http/hooks';
import * as InputSearchService from '@modules/inputs/search/service';
import { pipe } from 'fp-ts/function';
import * as S from 'fp-ts/string';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as TO from 'fp-ts/TaskOption';

import * as Json from 'fp-ts/Json';
import { InputFormBody } from '@modules/inputs/search/model';
import { renderOptional } from '@shared/utils/render';
import SearchUsageForm from '@modules/inputs/search/components/form/usage/SearchUsageForm';
import { useEnhancedFormContext } from '@shared/modules/form';
import { sequenceT } from 'fp-ts/Apply';
import { initInputFormBody } from '@modules/inputs/search/utils';
import { IconSeeding } from '@tabler/icons-react';
import config from '@root/config';
import State = Input.State;
import { InputSchema } from '@modules/inputs/search/schema';
import searchValueSchema = InputSchema.searchValueSchema;

interface SearchFormProps {
  defaultName: string | null;
  defaultSearch: string | null;
}

const SearchForm: FC<SearchFormProps> = ({ defaultName, defaultSearch }) => {
  const { setValue, watch, handleSubmit, reset } = useEnhancedFormContext<InputFormBody>();

  const [search, setSearch] = useState<string | null>(defaultSearch);
  const [debouncedSearch] = useDebouncedValue(search, 400, { leading: true });

  const handleSearchChange = (query: string) => {
    const search = S.isEmpty(query) ? null : query;

    setSearch(search);
  };

  const authorizedOnly = watch('state') === Input.State.Authorized;

  const filter = useMemo(
    () => ({
      search: debouncedSearch,
      authorizedOnly,
      limit: 1000,
    }),
    [authorizedOnly, debouncedSearch],
  );

  const [inputs, , inputsLoading, setInputs] = useFetchTaskOption(InputSearchService.searchEmptyInputs, filter);

  const [, searchUsages] = useSendTask(InputSearchService.searchInputsUsage);

  const inputsData: Array<SelectItem> = pipe(
    inputs,
    O.fold(
      () => [],
      A.map(({ nameId, inputId, name, source, tradeLicenceId }) => ({
        value: JSON.stringify({ inputId, source, nameId, tradeLicenceId }),
        label: name,
      })),
    ),
  );

  const handleStateChange: ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) =>
    setValue('state', currentTarget.checked ? State.Authorized : State.Unauthorized);

  const handleInputChange = (value: string | null) =>
    pipe(
      O.fromNullable(value),
      O.chainEitherK(Json.parse),
      O.chain(O.tryCatchK(searchValueSchema.parse)),
      TO.fromOption,
      TO.chain(({ inputId, source, nameId, tradeLicenceId }) =>
        TO.fromTaskEither(() => searchUsages({ id: inputId, source, nameId, tradeLicenceId })),
      ),
      TO.chainFirstIOK(detail => () => {
        setValue('detail', detail);
        setValue('params', initInputFormBody(detail));
      }),
    )();

  const handleReset = () => {
    reset({
      state: watch('state'),
    });

    setSearch(null);
    setInputs(O.none);
  };

  return (
    <Stack>
      <Box pb="lg" sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}>
        <Text opacity={0.4} size="sm" weight={500} pb={4}>
          Filtrer par
        </Text>
        <Checkbox
          onChange={handleStateChange}
          checked={authorizedOnly}
          styles={theme => ({ labelWrapper: { flex: 1, fontSize: theme.fontSizes.md } })}
          label="Intrants autorisés uniquement"
          labelPosition="left"
        />
      </Box>
      <form onSubmit={handleSubmit}>
        <Stack spacing="xs">
          <Select
            label="Nom de l'intrant"
            placeholder="Sélectionner"
            data={inputsData}
            searchValue={search ?? ''}
            onSearchChange={handleSearchChange}
            onChange={handleInputChange}
            nothingFound={search ? 'Aucun intrant trouvé' : 'Aucune recherche renseignée'}
            rightSection={inputsLoading ? <Loader pr="xs" /> : undefined}
            defaultValue={defaultName}
            searchable
            required
          />
          <Group position="right">
            <Anchor size="sm" component="button" onClick={handleReset} c="dark.3" fw={500} td="underline">
              Réinitialiser
            </Anchor>
          </Group>

          {renderOptional(
            sequenceT(O.Apply)(O.fromNullable(watch('detail')), O.fromNullable(watch('params'))),
            ([detail, params]) => (
              <SearchUsageForm detail={detail} params={params} />
            ),
            () => (
              <Center pt="sm">
                <Stack align="center" ta="center" spacing="lg" maw={250}>
                  <ThemeIcon size={95} color="transparent" c="blue.2">
                    <IconSeeding size="100%" strokeWidth={1.5} />
                  </ThemeIcon>
                  <Text weight={500} lh={1.25}>
                    Vous ne trouvez pas un intrant ? Faites une demande de création.
                  </Text>
                  <Anchor href={config.VITE_INPUT_REQUEST} color="dark.3" weight={500}>
                    Demande de création
                  </Anchor>
                </Stack>
              </Center>
            ),
          )}
        </Stack>
      </form>
    </Stack>
  );
};

export default SearchForm;

import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Water } from '@modules/water/model';
import { Select, Stack, Textarea, TextInput } from '@mantine/core';
import { getSelectDataFromEnumLabels } from '@shared/utils/enum';
import { Controller } from 'react-hook-form';
import Meter = Water.Meter;

interface MeterFormProps {
  readonly?: boolean;
}
const MeterForm: FC<MeterFormProps> = ({ readonly = false }) => {
  const {
    control,
    register,
    formState: { errors },
  } = useEnhancedFormContext<Meter.Params>();

  return (
    <form>
      <Stack>
        <TextInput
          {...register('name')}
          error={!!errors.name}
          label="Nom du compteur"
          placeholder="Saisir un nom de compteur"
          disabled={readonly}
          required
        />
        <TextInput
          {...register('serialNumber')}
          error={!!errors.serialNumber}
          label="Numéro de série"
          placeholder="Saisir le numéro de série"
          disabled={readonly}
          required
        />
        <Controller
          control={control}
          name="sourceType"
          render={({ field }) => (
            <Select
              {...field}
              error={!!errors.sourceType}
              data={getSelectDataFromEnumLabels(Meter.sourceTypeLabel)}
              placeholder="Sélectionner"
              label="Type de source"
              disabled={readonly}
              required
            />
          )}
        />
        <Textarea
          {...register('description')}
          error={!!errors.description}
          label="Description"
          placeholder="Localisation, informations complémentaires, ..."
          disabled={readonly}
        />
      </Stack>
    </form>
  );
};

export default MeterForm;

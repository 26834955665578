import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { Water } from '@modules/water/model';
import { LocalDate } from '@root/shared/modules/dates';

export const indexSchema = z.object({
  meterId: createNewTypeStringSchema<Water.Meter.Id>(),
  number: z.number().positive(),
  date: createNewTypeStringSchema<LocalDate>(),
  drynessLevel: z.nativeEnum(Water.DrynessLevel),
});

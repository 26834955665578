import Seo from '@shared/modules/seo/Seo';
import { renderNullable } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { NavLink, To } from 'react-router-dom';
import * as Styled from './Page.styles';
import { ActionIcon, Box, BoxProps, Group, Title } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { filterEmptyStringToOption } from '@shared/utils/string';
import Nav from '@layout/nav/Nav';
import * as S from 'fp-ts/string';

export interface BackLinkProps {
  title: ReactNode;
  to: To;
}

export interface PageProps {
  title?: ReactNode;
  seoTitle?: string;
  bottomBar?: ReactNode;
  back?: BackLinkProps;
  withNav?: boolean;
}

const Page: FC<PropsWithChildren<PageProps & BoxProps>> = ({
  title,
  seoTitle,
  bottomBar,
  back,
  withNav = false,
  children,
  ...contentProps
}) => {
  const computeSeoTitle = pipe(
    filterEmptyStringToOption(seoTitle),
    O.alt(() => (typeof title === 'string' ? O.some(title) : O.none)),
    O.toUndefined,
  );

  return (
    <Styled.PageContainer bg={contentProps.bg}>
      <Seo title={computeSeoTitle} />

      {renderNullable(back, back => (
        <Group spacing={5} bg="blue.9" h={63} px={20} style={{ flex: '0 0 auto' }}>
          <ActionIcon component={NavLink} to={back.to} variant="transparent" c="white">
            <IconArrowLeft size={22} />
          </ActionIcon>
          {S.isString(back.title) ? (
            <Title size="h3" color="white">
              {back.title}
            </Title>
          ) : (
            back.title
          )}
        </Group>
      ))}

      <Styled.PageContent p={20} {...contentProps}>
        {renderNullable(title, title => (
          <Box mb={20}>{typeof title === 'string' ? <Title>{title}</Title> : title}</Box>
        ))}
        {children}
      </Styled.PageContent>

      <Styled.PageBottom>
        {renderNullable(bottomBar, bottom => (
          <Styled.PageBottomBar>{bottom}</Styled.PageBottomBar>
        ))}
        {withNav && <Nav />}
      </Styled.PageBottom>
    </Styled.PageContainer>
  );
};

export default Page;

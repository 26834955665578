import React, { FC } from 'react';
import SuccessPage from '@shared/components/success-page/SuccessPage';

const InterventionSuccessPage: FC = () => {
  return (
    <SuccessPage
      description={`L’intervention a bien été ajoutée, vous pouvez la consulter depuis la liste de vos interventions.`}
      to="/interventions"
    >
      Consulter les interventions
    </SuccessPage>
  );
};

export default InterventionSuccessPage;

import React, { FC } from 'react';
import { renderNullable } from '@shared/utils/render';
import { Badge, Group, Text } from '@mantine/core';
import { Intervention } from '@modules/interventions/model';
import TreatmentUnit = Intervention.TreatmentUnit;
import { GroupProps } from '@mantine/core/lib/Group/Group';

interface InterventionRecapDosesProps {
  dose?: number | null;
  doseUnit?: TreatmentUnit | null;
}

const InterventionRecapDoses: FC<InterventionRecapDosesProps & GroupProps> = ({ dose, doseUnit, ...rest }) => {
  return dose || doseUnit ? (
    <Group {...rest}>
      {renderNullable(dose, dose => (
        <Badge color="green">
          <Text size="md" color="dark.6" weight={500} tt="none">
            Dose homologué : {dose}
          </Text>
        </Badge>
      ))}
      {renderNullable(doseUnit, unit => (
        <Badge color="green">
          <Text size="md" color="dark.6" weight={500} tt="none">
            Unité : {unit}
          </Text>
        </Badge>
      ))}
    </Group>
  ) : null;
};

export default InterventionRecapDoses;

import { HttpRange, httpService, HttpTask } from '@core/http';
import { SearchFilter } from '@shared/modules/filter';
import { Zone } from '@modules/zones/model';

export const URI = '/zones';

export function getZonesRange(page: number, filter: SearchFilter): HttpRange<Zone.Range.Item, SearchFilter> {
  return httpService.getRange(URI, page, filter);
}

export function getZone(id: Zone.Id): HttpTask<Zone> {
  return httpService.get(`${URI}/${id}`);
}

export function updateZone(id: Zone.Id, params: Zone.Params): HttpTask<Zone> {
  return httpService.post(`${URI}/${id}`, params);
}

export function deleteZone(id: Zone.Id): HttpTask {
  return httpService.delete(`${URI}/${id}`);
}

export function createZone(params: Zone.Params): HttpTask<Zone> {
  return httpService.post(URI, params);
}

export function searchZone(search: string | null): HttpTask<Array<Zone.List.Item>> {
  return httpService.get(`${URI}/search`, { params: { search } });
}

import { NumberInput, NumberInputProps } from '@mantine/core';
import { forwardRef } from 'react';
import { constVoid } from 'fp-ts/function';

interface SafeNumberInputProps extends Omit<NumberInputProps, 'value' | 'onChange'> {
  value?: number | '' | null;

  onChange?(value: number | null): void;
}

const SafeNumberInput = forwardRef<HTMLInputElement, SafeNumberInputProps>(
  ({ value, onChange = constVoid, ...props }, ref) => {
    const handleChange = (value: number | '') => onChange(value === '' ? null : value);

    return <NumberInput ref={ref} {...props} value={value ?? ''} onChange={handleChange} />;
  },
);

export default SafeNumberInput;

import * as O from 'fp-ts/Option';
import { To } from 'history';
import { pipe } from 'fp-ts/function';
import { CompanyUserRole, Profile } from '@modules/auth/model';
import { Navigate, Outlet } from 'react-router-dom';
import { useProfile } from '@modules/auth/hooks';
import * as React from 'react';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError, HttpStatusCode } from '@core/http';
import * as NEA from 'fp-ts/NonEmptyArray';

type ProtectedRoutesProps<T extends CompanyUserRole> = {
  roles: NEA.NonEmptyArray<T>;
  redirect?: To;
};

export function ProtectedRoutes<T extends CompanyUserRole>({ roles, redirect }: ProtectedRoutesProps<T>) {
  const profile = useProfile();

  return pipe(
    O.some(profile),
    O.filter((profile): profile is Profile<T> => roles.some(role => role === profile.role)),
    O.fold(
      () =>
        redirect ? (
          <Navigate to={redirect} replace />
        ) : (
          <ErrorPage error={HttpError.fromStatusCode(HttpStatusCode.UNAUTHORIZED)} />
        ),
      profile => <Outlet context={profile} />,
    ),
  );
}

export default ProtectedRoutes;

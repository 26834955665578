import React, { FC, PropsWithChildren } from 'react';
import { Box, Text } from '@mantine/core';
import { rgba } from 'polished';
import { BoxProps } from '@mantine/core/lib/Box/Box';
import * as S from 'fp-ts/string';

interface InputSimpleInfoProps extends BoxProps {
  title: string;
}

const InputSimpleInfo: FC<PropsWithChildren<InputSimpleInfoProps>> = ({ title, children, ...rest }) => {
  return (
    <Box
      py={15}
      sx={theme => ({ '&:not(:last-child)': { borderBottom: `1px solid ${rgba(theme.colors.dark[0], 0.25)}` } })}
      {...rest}
    >
      <Text lh={1.25} size="xs" color="blue.8" weight={500}>
        {title}
      </Text>
      {S.isString(children) ? (
        <Text pt={3} lh={1.25}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
};

export default InputSimpleInfo;

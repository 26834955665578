import React, { FC } from 'react';
import { SimpleGrid } from '@mantine/core';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Controller } from 'react-hook-form';
import { Intervention } from '@modules/interventions/model';
import InterventionDosageForm, {
  InputUnitDescription,
} from '@modules/interventions/components/form/dosage/InterventionDosageForm';
import SafeNumberInput from '@shared/components/number/SafeNumberInput';

interface CustomInterventionDosageFormProps {
  oldTotalZoneAreaValue?: number | null;
}

const CustomInterventionDosageForm: FC<CustomInterventionDosageFormProps> = ({ oldTotalZoneAreaValue }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useEnhancedFormContext<Intervention.Update.FormBody.Dosage>();

  const unit = watch('treatment.unit');
  const [, rightUnit] = (unit ?? '').split('/');

  return (
    <InterventionDosageForm oldTotalZoneAreaValue={oldTotalZoneAreaValue}>
      <SimpleGrid cols={2} spacing="xs">
        <Controller
          control={control}
          name="treatment.doseValue"
          render={({ field }) => (
            <SafeNumberInput
              {...field}
              min={0}
              error={!!errors.treatment?.doseValue}
              label="Dose appliquée"
              placeholder="Saisir"
              description={<InputUnitDescription>{unit}</InputUnitDescription>}
              required
            />
          )}
        />
        <Controller
          control={control}
          name="treatment.mushVolumeValue"
          render={({ field }) => (
            <SafeNumberInput
              {...field}
              min={0}
              error={!!errors.treatment?.mushVolumeValue}
              value={field.value ?? undefined}
              label="Volume de bouillie"
              description={<InputUnitDescription>{rightUnit ? `L/${rightUnit}` : null}</InputUnitDescription>}
              placeholder="Saisir"
            />
          )}
        />
      </SimpleGrid>
    </InterventionDosageForm>
  );
};

export default CustomInterventionDosageForm;

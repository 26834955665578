import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import reportRoute from '@modules/reports/ReportPage';
import InputReportListPage from '@modules/reports/inputs/ListPage';

const inputReportRoutes = (
  <Route path="input">
    <Route index element={<InputReportListPage />} />
    <Route path=":code" {...createRoute(reportRoute)} />
  </Route>
);

export default inputReportRoutes;

import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute, defineRoute } from '@core/router';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { Input } from '@modules/inputs/model';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import * as InputsService from '@modules/inputs/service';
import { OpenData } from '@shared/modules/opendata/model';
import NonMfscInputDetailPage from '@modules/inputs/amm/non-mfsc/DetailPage';
import createNonMfscInterventionRoute from '@modules/inputs/amm/non-mfsc/CreatePage';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { getStringQuery } from '@shared/modules/filter';

const nonMfscParamsSchema = z.object({
  id: Input.Id,
  usageId: createNewTypeStringSchema<OpenData.ProductUsageId>(),
});

export const loader = defineLoader({
  id: 'non-mfsc-input-detail',
  params: nonMfscParamsSchema,
  handler: ({ params, request }) => {
    const queries = parseQueriesFormUrl(request.url);
    const tradeLicenceId = getStringQuery<OpenData.TradeLicence.Id>(queries, 'tradeLicenceId');
    return httpTaskToResponseTask(InputsService.getNonMfscInputDetail(params.id, params.usageId, tradeLicenceId));
  },
});

const nonMfscInputRoutes = (
  <Route
    path="non-mfsc/:id/:usageId"
    {...createRoute(
      defineRoute({
        loader,
      }),
    )}
  >
    <Route index element={<NonMfscInputDetailPage />} />
    <Route path="new" {...createRoute(createNonMfscInterventionRoute)} />
  </Route>
);

export default nonMfscInputRoutes;

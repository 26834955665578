import React, { createElement, FC } from 'react';
import { Card, Center, Group, Image, Stack, Text } from '@mantine/core';
import headerBackground from '@assets/images/home-header.webp';
import { IconPlant, IconFileReport, IconFileAnalytics, IconDroplet, IconPhone, Icon } from '@tabler/icons-react';
import fedeLogo from '@assets/logos/fede.svg';
import platformGolfLogo from '@assets/logos/platformgolf.svg';
import { rgba } from 'polished';
import { SharedButtonLink } from '@styles/shared';
import * as Styled from './HomePage.styles';
import Page from '@layout/page/Page';
import SimpleCard from '@shared/components/list/card/SimpleCard';
import { Link } from 'react-router-dom';
import { Report } from '@modules/reports/model';

interface HomeReportCardProps {
  code: Report.Code;
  icon: Icon;
}

const HomeReportCard: FC<HomeReportCardProps> = ({ code, icon }) => {
  return (
    <Card component={Link} to={`/reports/${code}`}>
      {createElement(icon)}
      <Text tt="uppercase" weight={500}>
        {Report.pageTitle[code]}
      </Text>
      <Text>Consulter, partager</Text>
    </Card>
  );
};

const HomePage: FC = () => {
  return (
    <Page withNav p={0}>
      <Stack spacing={0} align="center">
        <Center w="100%" pos="relative">
          <Image src={headerBackground} height={215} />
          <Image pt={40} src={platformGolfLogo} pos="absolute" width={134} />
          <SharedButtonLink
            to="/emergency-numbers"
            radius="lg"
            color="red.5"
            pos="absolute"
            top={23}
            right={23}
            leftIcon={<IconPhone />}
          >
            Urgence
          </SharedButtonLink>
        </Center>
        <Stack mx="auto" maw={430} style={{ transform: 'translateY(-20px)' }} px={20}>
          <Card withBorder={false} radius={15}>
            <Group noWrap>
              <Image src={fedeLogo} width={91} height={91} />
              <Text size="sm" lh={1.21}>
                Cet espace, réservé aux affiliés Golf, vous permet de déclarer et de suivre vos consommations d'Eau et
                d'Intrants.
              </Text>
            </Group>
          </Card>
          <Card withBorder={false} sx={theme => ({ background: rgba(theme.colors.blue[2], 0.15) })}>
            <Stack>
              <SimpleCard
                p="md"
                to="inputs"
                icon={IconPlant}
                title="Intrants"
                description="Gérez la déclaration de vos intrants."
                sx={theme => ({
                  background: rgba(theme.colors.blue[8], 0.85),
                  svg: {
                    width: 28,
                    height: 28,
                  },
                })}
              />
              <SimpleCard
                p="md"
                bg="blue.4"
                to="water"
                icon={IconDroplet}
                title="Eau"
                description="Suivez votre consommation, déclarez vos compteurs."
                sx={{
                  svg: {
                    width: 28,
                    height: 28,
                  },
                }}
              />
              <Styled.HomeReportCards cols={2}>
                <HomeReportCard code={Report.Code.Water} icon={IconFileReport} />
                <HomeReportCard code={Report.Code.Input} icon={IconFileAnalytics} />
              </Styled.HomeReportCards>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Page>
  );
};

export default HomePage;

import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import * as ReferentialsService from '@modules/referentials/service';

export const treatmentUnitsloader = defineLoader({
  id: 'treatment-units',
  handler: () =>
    httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        areaUnits: ReferentialsService.getAreaTreatmentUnits(),
        otherUnits: ReferentialsService.getOtherTreatmentUnits(),
      }),
    ),
});

import React, { FC, useCallback, useEffect, useState } from 'react';
import { SimpleGrid, TextInput } from '@mantine/core';
import { useEnhancedFormContext } from '@shared/modules/form';
import {
  InterventionDosageFormBody,
  createInterventionTreatmentSchema,
  volumeDosageSchema,
  zoneDosageSchema,
} from '@modules/interventions/schema';
import { Controller, DeepPartial } from 'react-hook-form';
import { useSendTask } from '@core/http/hooks';
import * as InterventionsService from '@modules/interventions/service';
import { pipe } from 'fp-ts/function';
import { Intervention } from '@modules/interventions/model';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { sequenceS } from 'fp-ts/Apply';
import { renderOptional } from '@shared/utils/render';
import CustomAlert from '@shared/components/alert/CustomAlert';
import InterventionDosageForm, {
  InputUnitDescription,
} from '@modules/interventions/components/form/dosage/InterventionDosageForm';
import SafeNumberInput from '@shared/components/number/SafeNumberInput';

interface AmmInterventionDosageFormProps {
  dose?: number | null;
  doseUnit?: Intervention.TreatmentUnit | null;
  oldTotalZoneAreaValue?: number | null;
}
const AmmInterventionDosageForm: FC<AmmInterventionDosageFormProps> = ({ dose, doseUnit, oldTotalZoneAreaValue }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useEnhancedFormContext<Intervention.Update.FormBody.Dosage>();

  const [simulateResult, setSimulateResult] = useState<Intervention.SimulateTreatment.Result | null>(null);

  const [, simulate] = useSendTask(InterventionsService.simulateInterventionTreatment);

  const handleSimulate = useCallback(
    (treatment?: DeepPartial<Intervention.Treatment>, dosage?: DeepPartial<InterventionDosageFormBody>) => {
      const parseTreatment = createInterventionTreatmentSchema.safeParse(treatment);
      const parseAreaValue =
        dosage?.treatmentType === Intervention.TreatmentType.Zone
          ? zoneDosageSchema.shape.totalZoneAreaValue.safeParse(dosage?.totalZoneAreaValue)
          : dosage?.treatmentType === Intervention.TreatmentType.Volume
          ? volumeDosageSchema.shape.volumeValue.safeParse(dosage?.volumeValue)
          : null;

      if (parseTreatment.success && parseAreaValue?.success) {
        pipe(
          () =>
            simulate({
              areaValue: parseAreaValue.data,
              doseValue: parseTreatment.data.doseValue,
              treatmentUnit: parseTreatment.data.unit,
              mushVolumeValue: parseTreatment.data.mushVolumeValue ?? null,
            }),
          TE.chainIOK(result => () => setSimulateResult(result)),
        )();
      } else {
        setSimulateResult(null);
      }
    },
    [simulate],
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const names = [
        'treatment',
        'dosage.totalZoneAreaValue',
        'dosage.volumeValue',
        'dosage.treatmentType',
      ] satisfies Array<typeof name>;
      if (names.some(item => name?.startsWith(item))) handleSimulate(value.treatment, value.dosage);
    });

    handleSimulate(watch('treatment'), watch('dosage'));

    return () => subscription.unsubscribe();
  }, [handleSimulate, watch]);

  const doseValue = watch('treatment.doseValue');
  const unit = watch('treatment.unit');
  const [leftUnit, rightUnit] = unit.split('/');

  const alertDose = pipe(
    sequenceS(O.Apply)({ dose: O.fromNullable(dose), doseUnit: O.fromNullable(doseUnit) }),
    O.filter(({ doseUnit }) => doseUnit === unit),
    O.filter(({ dose }) => dose < doseValue),
  );

  const alertUnit = pipe(
    O.fromNullable(doseUnit),
    O.filter(doseUnit => doseUnit !== unit),
  );

  return (
    <InterventionDosageForm doseUnit={doseUnit} oldTotalZoneAreaValue={oldTotalZoneAreaValue}>
      <SimpleGrid cols={2} spacing="xs">
        <Controller
          control={control}
          name="treatment.doseValue"
          render={({ field }) => (
            <SafeNumberInput
              {...field}
              min={0}
              error={!!errors.treatment?.doseValue}
              label="Dose appliquée"
              placeholder="Saisir"
              description={<InputUnitDescription>{unit}</InputUnitDescription>}
              required
            />
          )}
        />
        <TextInput
          label="Quantité/traitement"
          value={simulateResult?.doseValue ?? 'Automatique'}
          description={<InputUnitDescription>{leftUnit}</InputUnitDescription>}
          disabled
        />
        <Controller
          control={control}
          name="treatment.mushVolumeValue"
          render={({ field }) => (
            <SafeNumberInput
              {...field}
              min={0}
              error={!!errors.treatment?.mushVolumeValue}
              value={field.value ?? undefined}
              label="Volume de bouillie"
              description={<InputUnitDescription>{rightUnit ? `L/${rightUnit}` : null}</InputUnitDescription>}
              placeholder="Saisir"
            />
          )}
        />
        <TextInput
          label="Vol bouillie/traitement"
          value={simulateResult?.mushVolumeValue ?? 'Automatique'}
          description={<InputUnitDescription>L</InputUnitDescription>}
          disabled
        />
      </SimpleGrid>
      {renderOptional(alertDose, () => (
        <CustomAlert title="Attention" color="red" mt="xs">
          La dose appliquée est supérieure à la dose homologuée
        </CustomAlert>
      ))}
      {renderOptional(alertUnit, () => (
        <CustomAlert title="Attention" color="orange" mt="xs">
          L'unité choisie est différente de l'unité de la dose homologuée. Pensez à bien convertir les doses pour
          respecter les doses homologuées
        </CustomAlert>
      ))}
    </InterventionDosageForm>
  );
};

export default AmmInterventionDosageForm;

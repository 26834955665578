import React, { FC, useMemo } from 'react';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { Intervention } from '@modules/interventions/model';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import * as InterventionsService from '@modules/interventions/service';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { updateInterventionBodySchema, updateInterventionSchema } from '@modules/interventions/schema';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { getUpdateFormBodyFromDetail, getUpdateParamsFromFromBody } from '@modules/interventions/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { SharedButton } from '@styles/shared';
import { pipe } from 'fp-ts/function';
import DuplicateInterventionForm from '@modules/interventions/components/duplicate/DuplicateInterventionForm';
import InterventionDetailHeader from './components/form/header/InterventionDetailHeader';
import { useNavigate } from 'react-router-dom';
import * as TE from 'fp-ts/TaskEither';

const params = z.object({ id: createNewTypeStringSchema<Intervention.Id>() });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(InterventionsService.getIntervention(params.id)),
});

const actions = {
  duplicate: defineAction({
    type: 'duplicate',
    params,
    payload: updateInterventionSchema,
    handler: ({ payload, params }) => InterventionsService.duplicateIntervention(params.id, payload),
    flashOptions: {
      success: () => 'Intervention créée',
    },
  }),
};

const InterventionDuplicatePage: FC = () => {
  const intervention = useLoader<typeof loader>();

  const navigate = useNavigate();

  const [loading, duplicate] = useAction(actions.duplicate);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Intervention.Update.FormBody>({
    defaultValues: useMemo(() => getUpdateFormBodyFromDetail(intervention), [intervention]),
    resolver: zodResolver(updateInterventionBodySchema),
  });

  const pageProps: PageProps = {
    seoTitle: `Duplication d'intervention`,
    back: {
      title: `Duplication d'intervention`,
      to: `/interventions/${intervention.id}`,
    },
    bottomBar: (
      <SharedButton btnType="create" loading={loading} onClick={handleFormSubmit}>
        Dupliquer
      </SharedButton>
    ),
  };

  const handleDuplicate = (params: Intervention.Update.FormBody) =>
    pipe(
      () => duplicate(getUpdateParamsFromFromBody(params)),
      TE.chainFirstIOK(
        ({ id }) =>
          () =>
            navigate(`/interventions/${id}`),
      ),
    );

  return (
    <Page {...pageProps} bg="background.1" p={0}>
      <InterventionDetailHeader intervention={intervention} />

      <EnhancedForm ref={formRef} form={form} onSubmit={handleDuplicate} preventLeave>
        <DuplicateInterventionForm intervention={intervention} />
      </EnhancedForm>
    </Page>
  );
};

const interventionDuplicateRoute = defineRoute({
  component: InterventionDuplicatePage,
  loader,
  actions,
});

export default interventionDuplicateRoute;

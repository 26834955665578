import { Zone } from '@modules/zones/model';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import { pipe, tuple } from 'fp-ts/function';

type ZoneGameAreasParams = Extract<Zone.Params, { type: Zone.Type.Game }>['areas'];

export function getInitialGameAreas(
  areas?: ZoneGameAreasParams,
): Record<Zone.Area.GameType, ZoneGameAreasParams[number]> {
  return pipe(
    Object.values(Zone.Area.GameType),
    A.map(type =>
      tuple(
        type,
        pipe(
          areas ?? [],
          A.findFirst(area => area.type === type),
          O.getOrElseW(() => ({ type, value: null as unknown as number })),
        ),
      ),
    ),
    R.fromEntries,
  );
}

export function getZoneInitialProps(zone: Zone): Zone.FormBody {
  if (zone.type === Zone.Type.Game) {
    return {
      type: zone.type,
      name: zone.name,
      ...getInitialGameAreas(zone.areas),
    };
  }
  return { ...zone, ...getInitialGameAreas() };
}

export function mapZoneFormBodyToParams(zone: Zone.FormBody): Zone.Params {
  if (zone.type === Zone.Type.Game) {
    const { type, name, ...areas } = zone;
    return {
      type: zone.type,
      name: zone.name,
      areas: pipe(
        Object.values(areas),
        A.filterMap(area =>
          pipe(
            O.fromNullable(area.value),
            O.map(value => ({ ...area, value })),
          ),
        ),
      ),
    };
  }
  return zone;
}

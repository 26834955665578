import React, { FC } from 'react';
import { VirtualizedListChildrenProps } from '@shared/modules/range';
import { Badge, Card, Group, Mark, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconDroplet } from '@tabler/icons-react';
import { Water } from '@modules/water/model';
import { renderOptional } from '@shared/utils/render';
import Index = Water.Index;
import { formatDate, parseDate } from '@shared/modules/dates';

const IndexListRow: FC<VirtualizedListChildrenProps<Index.Range.Item>> = ({ item }) => {
  return (
    <Card
      component={Link}
      to={item.id}
      withBorder={false}
      sx={{
        boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.03)',
      }}
    >
      <Stack spacing="xs">
        <Group spacing="xs">
          <ThemeIcon variant="light" color="blue.9" size={22}>
            <IconDroplet size={14} />
          </ThemeIcon>
          <Text size="sm">
            {item.number}
            <Mark c="blue.9" bg="transparent" sx={theme => ({ fontSize: theme.fontSizes.xs })}>
              {' '}
              m³
            </Mark>
          </Text>
        </Group>
        <Title size="h3">{item.waterMeterName}</Title>

        {renderOptional(parseDate(item.date), date => (
          <Badge color="gray" w="fit-content" fw={400} tt="none">
            {formatDate(date)}
          </Badge>
        ))}
      </Stack>
    </Card>
  );
};

export default IndexListRow;

import React, { FC } from 'react';
import { Card } from '@mantine/core';
import { AmmInput } from '@modules/inputs/amm/model';
import { formatDate, parseDate } from '@shared/modules/dates';
import { renderNullable, renderOptional } from '@shared/utils/render';
import InputSimpleInfo from '@modules/inputs/components/InputSimpleInfo';
import LineClampSpoiler from '@shared/components/spoiler/LineClampSpoiler';
import { constPlaceholder } from '@modules/inputs/amm/utils';
import { identity } from 'fp-ts/function';

interface AmmInputInfosProps {
  input: AmmInput;
}

const AmmInputInfos: FC<AmmInputInfosProps> = ({ input }) => {
  return (
    <Card py={0}>
      <InputSimpleInfo title="Type intrant">{AmmInput.typeLabel[input.ammType]}</InputSimpleInfo>
      {renderNullable(
        input.tradeLicence,
        ({ licenceNumber }) => (
          <InputSimpleInfo title="Numéro permis">{licenceNumber}</InputSimpleInfo>
        ),
        () => (
          <InputSimpleInfo title="Numéro AMM">{input.ammNumber}</InputSimpleInfo>
        ),
      )}
      <InputSimpleInfo title="État">
        {AmmInput.authorizationStateLabel[input.authorizationState]}{' '}
        {renderOptional(
          parseDate(input[AmmInput.authorizationStateDate[input.authorizationState]]),
          date => ` - ${formatDate(date)}`,
        )}
      </InputSimpleInfo>
      <InputSimpleInfo title="Substance active">
        {renderNullable(
          input.activeSubstance,
          substance => (
            <LineClampSpoiler>{substance}</LineClampSpoiler>
          ),
          constPlaceholder,
        )}
      </InputSimpleInfo>
      <InputSimpleInfo title="Délai de rentrée">{input.waitingPeriod}</InputSimpleInfo>
      <InputSimpleInfo title="Fonction">
        {renderNullable(
          input.functions,
          fct => (
            <LineClampSpoiler>{fct}</LineClampSpoiler>
          ),
          constPlaceholder,
        )}
      </InputSimpleInfo>
      <InputSimpleInfo title="Formulation">
        {renderNullable(input.physicalState, identity, constPlaceholder)}
      </InputSimpleInfo>
    </Card>
  );
};

export default AmmInputInfos;

import Page, { PageProps } from '@layout/page/Page';
import React, { FC } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputContextParams } from '@modules/inputs/components/context/model';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import * as O from 'fp-ts/Option';
import SearchForm from '@modules/inputs/search/components/form/SearchForm';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { Input } from '@modules/inputs/model';
import { sequenceS } from 'fp-ts/Apply';
import { HttpError } from '@core/http';
import { getDefaultName, getDefaultSearch, getSuccessTo } from '@modules/inputs/search/utils';
import { InputFormBody } from '@modules/inputs/search/model';
import { Button, Stack, Text } from '@mantine/core';
import { SharedLink } from '@styles/shared/Link';
import { defineLoader, useLoader } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as T from 'fp-ts/Task';
import { defineRoute } from '@core/router';

const loader = defineLoader({
  handler: ({ request }) => T.of(parseQueriesFormUrl(request.url)),
});

const SearchPage: FC = () => {
  const queries = useLoader<typeof loader>();
  const [context, setContext] = useOutletContext<InputContextParams>();

  const navigate = useNavigate();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<InputFormBody>({
    defaultValues: context ?? {
      state: Input.State.Authorized,
      params: null,
      detail: null,
    },
  });

  const handleSubmit = ({ params, detail, state }: InputFormBody) =>
    pipe(
      sequenceS(O.Apply)({ params: O.fromNullable(params), detail: O.fromNullable(detail) }),
      TE.fromOption(() => HttpError.default),
      TE.chainFirstIOK(({ params, detail }) => () => {
        setContext({ params, detail, state });
        const tradeLicenceId = detail.tradeLicence?.id ?? null;
        navigate(getSuccessTo(detail, params, { ...queries, tradeLicenceId }));
      }),
    );

  const defaultName = context ? getDefaultName(context.detail) : null;
  const defaultSearch = context ? getDefaultSearch(context.detail) : null;

  const allowSubmit = pipe(
    O.fromNullable(form.watch('params')),
    O.filter(params => params.source === Input.Source.Custom || !!params.usage.usageId),
    O.isSome,
  );

  const pageProps: PageProps = {
    seoTitle: 'Rechercher un intrant',
    back: { title: 'Rechercher un intrant', to: '/inputs' },
    bottomBar: (
      <Stack spacing="xs">
        {allowSubmit && <Button onClick={handleFormSubmit}>Voir le détail</Button>}
        <SharedLink
          to="/inputs"
          label={
            <Text color="dark.3" weight={500} size="md" td="underline" ta="center">
              Annuler
            </Text>
          }
        />
      </Stack>
    ),
  };

  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleSubmit} preventLeave>
        <SearchForm defaultName={defaultName} defaultSearch={defaultSearch} />
      </EnhancedForm>
    </Page>
  );
};

const searchRoute = defineRoute({
  component: SearchPage,
  loader,
});

export default searchRoute;

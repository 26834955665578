import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { Card, Stack } from '@mantine/core';
import { rgba } from 'polished';
import SimpleCard from '@shared/components/list/card/SimpleCard';
import { IconBorderHorizontal, IconEdit, IconPlant, IconUsers } from '@tabler/icons-react';
import { stringifyQueries } from '@shared/utils/queries';
import { InterventionFromQuery } from '@modules/interventions/model';

const InputListPage: FC = () => {
  const pageProps: PageProps = {
    seoTitle: 'Intrants',
    back: {
      to: '/',
      title: 'Intrants',
    },
  };

  return (
    <Page {...pageProps} withNav>
      <Card p="lg" withBorder={false} sx={theme => ({ background: rgba(theme.colors.blue[2], 0.15) })}>
        <Stack>
          <SimpleCard
            to={{ pathname: 'search', search: stringifyQueries({ from: InterventionFromQuery.Search }) }}
            icon={IconPlant}
            title="Rechercher un intrant"
            sx={theme => ({ background: rgba(theme.colors.blue[9], 0.85) })}
          />
          <SimpleCard
            bg="#2363a4"
            to="/zones"
            icon={IconBorderHorizontal}
            title="Zone d'entretien"
            description="Déclarez vos zones d’entretien"
          />
          <SimpleCard
            bg="blue.7"
            to="/interventions"
            icon={IconEdit}
            title="Interventions"
            description="Consultez, modifiez vos interventions"
          />
          <SimpleCard
            bg="blue.4"
            to="/operators"
            icon={IconUsers}
            title="Équipes"
            description="Déclarez vos opérateurs"
          />
        </Stack>
      </Card>
    </Page>
  );
};

export default InputListPage;

import React from 'react';
import { Route } from 'react-router-dom';
import nonMfscInputRoutes from '@modules/inputs/amm/non-mfsc/routes';
import mfscInputRoutes from '@modules/inputs/amm/mfsc/routes';

const ammInputRoutes = (
  <Route path="amm">
    {nonMfscInputRoutes}
    {mfscInputRoutes}
  </Route>
);

export default ammInputRoutes;

import { SearchFilter } from '@shared/modules/filter';
import { HttpRange, httpService, HttpTask } from '@core/http';
import { Water } from '@modules/water/model';
import Meter = Water.Meter;

export const URI = '/water/meters';

export function getWaterMetersRange(page: number, filter: SearchFilter): HttpRange<Meter.Range.Item, SearchFilter> {
  return httpService.getRange(URI, page, filter);
}

export function createWaterMeter(params: Meter.Params): HttpTask<Meter> {
  return httpService.post(URI, params);
}

export function getWaterMeter(id: Meter.Id): HttpTask<Meter> {
  return httpService.get(`${URI}/${id}`);
}

export function updateWaterMeter(id: Meter.Id, params: Meter.Params): HttpTask<Meter> {
  return httpService.post(`${URI}/${id}`, params);
}

export function deleteWaterMeter(id: Meter.Id): HttpTask {
  return httpService.delete(`${URI}/${id}`);
}

export function toggleActivationWaterMeter(id: Meter.Id, value: boolean): HttpTask {
  return httpService.post(`${URI}/${id}/activation`, { value });
}

export function searchWaterMeters(): HttpTask<Array<Meter.List.Item>> {
  return httpService.get(`${URI}/search`);
}

import { Newtype } from 'newtype-ts';
import { Filter as DefaultFilter } from '@shared/modules/filter';
import * as NEA from 'fp-ts/NonEmptyArray';
import z from 'zod';
import { indexSchema } from '@modules/water/indexes/schema';
import { meterSchema } from '@modules/water/meters/schema';
import { LocalDate } from '@shared/modules/dates';

export namespace Water {
  export namespace Meter {
    export type Id = Newtype<{ readonly ID: unique symbol }, string> & string;
    export type SerialNumber = Newtype<{ readonly SERIAL_NUMBER: unique symbol }, string> & string;

    export enum SourceType {
      PublicNetwork = 'public-network',
      UndergroundWater = 'underground-water',
      SurfaceWater = 'surface-water',
      WasteWater = 'waste-water',
    }

    export const sourceTypeLabel: Record<SourceType, string> = {
      [SourceType.PublicNetwork]: 'Réseau public',
      [SourceType.UndergroundWater]: 'Eau de forage',
      [SourceType.SurfaceWater]: 'Eau de surface',
      [SourceType.WasteWater]: 'Eaux alternatives',
    };

    export enum State {
      Disabled = 'disabled',
      Enabled = 'enabled',
    }

    export namespace Range {
      export interface Item {
        id: Id;
        name: string;
        sourceType: SourceType;
        serialNumber: SerialNumber;
        activationState: State;
      }
    }

    export namespace List {
      export interface Item {
        id: Id;
        name: string;
      }
    }

    export type Params = z.infer<typeof meterSchema>;
  }

  export interface Meter {
    id: Meter.Id;
    name: string;
    sourceType: Meter.SourceType;
    serialNumber: Meter.SerialNumber;
    activationState: Meter.State;
    description: string | null;
  }

  export namespace Index {
    export type Id = Newtype<{ readonly ID: unique symbol }, string> & string;

    export namespace Range {
      export interface Item {
        id: Id;
        waterMeterName: string;
        date: LocalDate;
        number: number;
      }

      export interface Filter extends DefaultFilter {
        startDate: LocalDate | null;
        endDate: LocalDate | null;
        meterIds: NEA.NonEmptyArray<Water.Meter.Id> | null;
      }
    }

    export type Params = z.infer<typeof indexSchema>;

    export type ErrorCode = 'index-number-integrity';

    export interface Error {
      code: ErrorCode;
      minNumber: number | null;
      maxNumber: number | null;
    }
  }

  export interface Index {
    id: Index.Id;
    meterId: Meter.Id;
    date: string;
    meterName: string;
    number: number;
    drynessLevel: DrynessLevel;
  }

  export enum DrynessLevel {
    Level0,
    Level1,
    Level2,
    Level3,
    Level4,
  }

  export const drynessLevelLabel: Record<DrynessLevel, string> = {
    [DrynessLevel.Level0]: 'Niveau 0',
    [DrynessLevel.Level1]: 'Niveau 1',
    [DrynessLevel.Level2]: 'Niveau 2',
    [DrynessLevel.Level3]: 'Niveau 3',
    [DrynessLevel.Level4]: 'Niveau 4',
  };
}

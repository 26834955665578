import { Route } from 'react-router-dom';
import React from 'react';
import InputListPage from '@modules/inputs/ListPage';
import customInputRoutes from '@modules/inputs/custom/routes';
import ammInputRoutes from '@modules/inputs/amm/routes';
import { createRoute } from '@core/router';
import inputLayoutRoute from '@modules/inputs/components/context/InputLayout';
import searchRoute from '@modules/inputs/search/SearchPage';

const inputRoutes = (
  <Route path="inputs" {...createRoute(inputLayoutRoute)}>
    <Route index element={<InputListPage />} />
    <Route path="search" {...createRoute(searchRoute)} />
    {customInputRoutes}
    {ammInputRoutes}
  </Route>
);

export default inputRoutes;

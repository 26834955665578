import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { constFalse } from 'fp-ts/function';
import * as AuthService from '@modules/auth/service';
import { defineRoute } from '@core/router';

const profileLoader = defineLoader({
  id: 'profile-loader',
  handler: () => httpTaskToResponseTask(AuthService.getProfile()),
});

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
  shouldRevalidate: constFalse,
});

export default profileLoaderRoute;

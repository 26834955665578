import { Report } from '@modules/reports/model';
import { httpService, HttpStatusCode, HttpTask } from '@core/http';
import { AxiosResponse } from 'axios';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as EI from 'fp-ts/Either';

import * as Retry from 'retry-ts';
import { retrying } from 'retry-ts/Task';
import { extractFileName } from '@shared/utils/download';
import { ReportResult } from '@modules/reports/ReportPage';

const URI = '/reports';

const RETRY_DELAY = 2000;

export function generateReport(code: Report.Code, params: Report.Params): HttpTask<ReportResult> {
  return pipe(
    httpService.get<Report.Job>(`${URI}/${code}`, { params }),
    TE.chain(({ report, jobId }) => {
      const task = () => httpService.get<Blob>(`${URI}/${report}/job/${jobId}`, { responseType: 'blob' }, true);

      const shouldRetry = EI.exists<AxiosResponse<Blob>>(({ status }) => status === HttpStatusCode.PARTIAL_CONTENT);

      return pipe(
        retrying(Retry.constantDelay(RETRY_DELAY), task, shouldRetry),
        TE.map(res => ({ name: extractFileName(res), url: window.URL.createObjectURL(res.data) })),
      );
    }),
  );
}

import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import { getStringQuery } from '@shared/modules/filter';
import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import SuccessPage from '@shared/components/success-page/SuccessPage';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return httpTaskToResponseTask(TE.fromNullable(HttpError.notFound)(getStringQuery(queries, 'name')));
  },
});
const ZoneSuccessPage: FC = () => {
  const name = useLoader<typeof loader>();

  return (
    <SuccessPage
      description={`La zone d'entretien ${name} a bien été ajoutée, vous pouvez désormais la consulter depuis la liste de
          vos zones d'entretien.`}
      to="/zones"
    >
      Voir mes zones d'entretien
    </SuccessPage>
  );
};

const zoneSuccessRoute = defineRoute({
  component: ZoneSuccessPage,
  loader,
});
export default zoneSuccessRoute;

import React, { FC, useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, useNavigation } from 'react-router-dom';

import { createRoute, defineRoute } from '@core/router';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import authenticateRoute from '@modules/auth/AuthenticatePage';
import profileLoaderRoute from '@modules/auth/loader';
import HomePage from '@modules/home/HomePage';
import Layout from '@layout/Layout';
import emergencyNumbersRoutes from '@modules/emergency-numbers/routes';
import zonesRoutes from '@modules/zones/routes';
import waterRoutes from '@modules/water/routes';
import operatorsRoutes from '@modules/operators/routes';
import inputRoutes from '@modules/inputs/routes';
import interventionRoutes from '@modules/interventions/routes';
import reportRoutes from '@modules/reports/routes';
import { treatmentUnitsloader as loader } from '@modules/referentials/loaders';
import AnalyticsTracker from '@shared/modules/analytics/components/AnalyticsTracker';

const NavigationOutlet: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return (
    <>
      <AnalyticsTracker />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationOutlet />}>
      <Route path="/authenticate/:token" {...createRoute(authenticateRoute)} />

      <Route {...createRoute(profileLoaderRoute)}>
        <Route element={<Layout />}>
          <Route index element={<HomePage />} />
          {emergencyNumbersRoutes}
          {zonesRoutes}
          {waterRoutes}
          {operatorsRoutes}
          {reportRoutes}
          <Route
            {...createRoute(
              defineRoute({
                loader,
              }),
            )}
          >
            {inputRoutes}
            {interventionRoutes}
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>,
  ),
);

export default router;

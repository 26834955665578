import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as OperatorsService from '@modules/operators/service';
import { getPageQuery } from '@shared/modules/range/utils';
import { useRange, VirtualizedList } from '@shared/modules/range';
import { identity } from 'fp-ts/function';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { SharedButtonLink } from '@styles/shared';
import { Stack, Text, ThemeIcon } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import { SearchFilter, searchFilterParser } from '@shared/modules/filter';
import { Operator } from '@modules/operators/model';
import OperatorListRow from '@modules/operators/components/list/row/OperatorListRow';
import { useHasRole } from '@modules/auth/hooks';
import { CompanyUserRole } from '@modules/auth/model';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return httpTaskToResponseTask(
      OperatorsService.getOperatorsRange(getPageQuery(queries), searchFilterParser(queries)),
    );
  },
});

const OperatorsListPage: FC = () => {
  const { range, handleLoadPage } = useRange<typeof loader, Operator.Range.Item, SearchFilter>(identity);
  const isAdmin = useHasRole(CompanyUserRole.Admin);

  const pageProps: PageProps = {
    seoTitle: 'Vos opérateurs',
    back: { title: 'Vos opérateurs', to: '/inputs' },
    bottomBar: isAdmin ? (
      <SharedButtonLink to="new" btnType="create">
        Ajouter un opérateur
      </SharedButtonLink>
    ) : undefined,
  };

  return (
    <Page {...pageProps} bg="background.1" withNav>
      <VirtualizedList
        range={range}
        loadPage={handleLoadPage}
        emptyRender={
          <Stack align="center" ta="center" spacing="lg">
            <ThemeIcon size={95} color="transparent" c="blue.2">
              <IconUsers size="100%" />
            </ThemeIcon>
            <Text weight={500}>Vous n'avez aucun opérateur actuellement</Text>
          </Stack>
        }
      >
        {OperatorListRow}
      </VirtualizedList>
    </Page>
  );
};

const operatorsListRoute = defineRoute({
  component: OperatorsListPage,
  loader,
});

export default operatorsListRoute;

import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Report } from '@modules/reports/model';
import { Controller } from 'react-hook-form';
import { Stack } from '@mantine/core';
import { YearPickerInput } from '@mantine/dates';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { newTypeWrap } from '@shared/schemas';

const ReportForm: FC = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useEnhancedFormContext<Report.Params>();

  const handleChangeYear = (date: Date | null) =>
    setValue('year', date ? formatDate(date, DateFormat.Year) : newTypeWrap(''));

  return (
    <form>
      <Stack>
        <Controller
          control={control}
          name="year"
          render={({ field }) => (
            <YearPickerInput
              {...field}
              value={parseDate(field.value, DateFormat.Year, null)}
              onChange={handleChangeYear}
              error={!!errors.year}
              placeholder="Sélectionner"
              label="Année"
              required
            />
          )}
        />
      </Stack>
    </form>
  );
};

export default ReportForm;

import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute, defineRoute } from '@core/router';
import { z } from 'zod';
import { Input } from '@modules/inputs/model';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import * as InputsService from '@modules/inputs/service';
import InputCustomDetailPage from '@modules/inputs/custom/DetailPage';
import createCustomInterventionRoute from '@modules/inputs/custom/CreatePage';

const operatorIdSchema = z.object({ id: Input.Id });

export const loader = defineLoader({
  id: 'custom-input-detail',
  params: operatorIdSchema,
  handler: ({ params }) => httpTaskToResponseTask(InputsService.getCustomInputDetail(params.id)),
});

const customInputRoutes = (
  <Route
    path="custom/:id"
    {...createRoute(
      defineRoute({
        loader,
      }),
    )}
  >
    <Route index element={<InputCustomDetailPage />} />
    <Route path="new" {...createRoute(createCustomInterventionRoute)} />
  </Route>
);

export default customInputRoutes;

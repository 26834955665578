import React, {
  createContext,
  FormEventHandler,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { SubmitHandler, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import * as IO from 'fp-ts/IO';
import { constVoid, pipe } from 'fp-ts/function';
import { useBlocker } from 'react-router-dom';
import { FormPreventLeaveModal } from '@shared/modules/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface EnhancedFormContextValue<Values extends FieldValues>
  extends Omit<UseFormReturn<Values>, 'handleSubmit'> {
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

export const EnhancedFormContext = createContext<EnhancedFormContextValue<any>>(undefined as any);

//export type EnhancedFormSubmitResult = TE.TaskEither<unknown, O.Option<To>>;

export interface EnhancedFormInnerProps<Values extends FieldValues> {
  form: UseFormReturn<Values>;
  preventLeave?: boolean;
  onSubmit: (values: Values) => TE.TaskEither<unknown, unknown>;
}

export type EnhancedFormExposedMethods = {
  handleSubmit: IO.IO<void>;
};

function EnhanceFormInner<Values extends FieldValues>(
  { form, preventLeave, onSubmit, children }: PropsWithChildren<EnhancedFormInnerProps<Values>>,
  ref: React.ForwardedRef<EnhancedFormExposedMethods>,
) {
  const isSubmitting = useRef<boolean>(false);

  const blocker = useBlocker(() => !!preventLeave && form.formState.isDirty && !isSubmitting.current);

  const handleSubmit = (values: Values) =>
    pipe(
      TE.fromIO(() => (isSubmitting.current = true)),
      TE.chain(() => onSubmit(values)),
      TE.chainFirstIOK(() => () => form.reset(undefined, { keepValues: true })),
      T.chainFirstIOK(() => () => (isSubmitting.current = false)),
    )();

  useImperativeHandle(ref, () => ({
    handleSubmit: form.handleSubmit(handleSubmit as SubmitHandler<Values>),
  }));

  const setValue = useCallback<UseFormSetValue<Values>>(
    (name, value, options = {}) =>
      form.setValue(name, value, { ...options, shouldDirty: true, shouldValidate: form.formState.isSubmitted }),
    [form],
  );

  const ctx: EnhancedFormContextValue<Values> = {
    ...form,
    setValue,
    handleSubmit: form.handleSubmit(handleSubmit as SubmitHandler<Values>),
  };

  return (
    <>
      {preventLeave && (
        <FormPreventLeaveModal
          open={blocker.state === 'blocked'}
          onLeave={blocker.proceed ?? constVoid}
          onClose={blocker.reset ?? constVoid}
        />
      )}

      <EnhancedFormContext.Provider value={ctx}>{children}</EnhancedFormContext.Provider>
    </>
  );
}

// Redecalare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const EnhancedForm = React.forwardRef(EnhanceFormInner);

export default EnhancedForm;

import React, { FC, PropsWithChildren, useState } from 'react';
import { ActionIcon, Button, Group, Stepper, Title } from '@mantine/core';
import Page, { PageProps } from '@layout/page/Page';
import { IconX } from '@tabler/icons-react';
import { NavLink, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { InputContextParams } from '@modules/inputs/components/context/model';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createInterventionSchema, triggeringFactorRequired } from '@modules/interventions/schema';
import {
  getInitialValues,
  getTreatmentIntialValue,
  mapInterventionFormBodyToParams,
} from '@modules/interventions/utils';
import { Input } from '@modules/inputs/model';
import { UseFormReturn } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as TE from 'fp-ts/TaskEither';
import * as InterventionsService from '@modules/interventions/service';
import { defineAction, useAction } from '@core/router/action';
import { z } from 'zod';
import * as IOO from 'fp-ts/IOOption';
import { Intervention } from '@modules/interventions/model';
import { useParentLoader } from '@core/router/loader';
import TreatmentUnit = Intervention.TreatmentUnit;
import { treatmentUnitsloader } from '@modules/referentials/loaders';
import * as EI from 'fp-ts/Either';
import { HttpStatusCode } from '@core/http';

const inputIdSchema = z.object({ id: Input.Id });

export const actions = {
  create: defineAction({
    type: 'create',
    params: inputIdSchema,
    payload: createInterventionSchema,
    handler: ({ payload, params }) =>
      InterventionsService.createIntervention(params.id, mapInterventionFormBodyToParams(payload)),
    redirect: ({ result }) => (EI.isRight(result) ? '/interventions/success' : null),
    flashOptions: {
      error: ({ error }) =>
        error.status === HttpStatusCode.CONFLICT
          ? {
              message:
                "Vous devez définir un décideur dans votre équipe d'opérateurs avant de pouvoir créer une intervention (Intrants -> Equipes)",
              autoClose: false,
            }
          : true,
    },
  }),
};

interface InterventionCreatePageProps {
  checks: Array<Array<FieldPath<Intervention.Create.FormBody>>>;
  type: Input.Type;
  doseUnit?: TreatmentUnit | null;
}

const InterventionCreatePage: FC<PropsWithChildren<InterventionCreatePageProps>> = ({
  children,
  checks,
  doseUnit,
  type,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const units = useParentLoader<typeof treatmentUnitsloader>('treatment-units');
  const [createLoading, createIndex] = useAction(actions.create);
  const [context] = useOutletContext<InputContextParams>();
  const [active, setActive] = useState(0);

  const treatment = getTreatmentIntialValue(units, doseUnit);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Intervention.Create.FormBody>({
    defaultValues: getInitialValues(context, treatment, doseUnit),
    resolver: zodResolver(
      createInterventionSchema.and(
        z.object(
          triggeringFactorRequired(type) ? { triggeringFactor: z.nativeEnum(Intervention.TriggeringFactor) } : {},
        ),
      ),
    ),
  });

  const handleChangeStep = (step: number) => () => setActive(step);

  function handleFormCheck<Val extends typeof form extends UseFormReturn<infer Values> ? FieldPath<Values> : never>(
    names: Array<Val>,
  ) {
    return pipe(
      () => form.trigger(names),
      T.map(O.guard),
      TO.chainIOK(() => handleChangeStep(active + 1)),
    );
  }

  const handleNextStep = pipe(
    IOO.fromOption(A.lookup(active)(checks)),
    IOO.fold(() => handleChangeStep(active + 1), handleFormCheck),
  );

  const backLink = { ...location, pathname: '..' };

  const pageProps: PageProps = {
    seoTitle: 'Nouvelle intervention',
    back: {
      title: (
        <Group position="apart" style={{ flex: '1 1 auto' }} noWrap>
          <Title size="h3" color="white">
            Nouvelle intervention
          </Title>
          <ActionIcon variant="transparent" component={NavLink} to={backLink}>
            <IconX color="white" />
          </ActionIcon>
        </Group>
      ),
      to: backLink,
    },
    bottomBar: (
      <Group spacing="xs">
        {active > 0 && (
          <Button color="green.7" variant="light" onClick={handleChangeStep(active - 1)}>
            Précédent
          </Button>
        )}
        {active < checks.length ? (
          <Button color="green.7" onClick={handleNextStep}>
            Suivant
          </Button>
        ) : (
          <Button color="green.7" onClick={handleFormSubmit} loading={createLoading}>
            Créer
          </Button>
        )}
      </Group>
    ),
  };

  const handleSubmit = (body: Intervention.Create.FormBody) =>
    pipe(
      () => createIndex(body),
      TE.chainFirstIOK(
        ({ id }) =>
          () =>
            navigate(`/interventions/${id}`),
      ),
    );

  return (
    <Page {...pageProps} p={0} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleSubmit} preventLeave>
        <Stepper active={active} onStepClick={setActive} allowNextStepsSelect={false}>
          {children}
        </Stepper>
      </EnhancedForm>
    </Page>
  );
};

export default InterventionCreatePage;

import z from 'zod';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import { Loader } from './index';
import { HttpTask } from '@core/http';
import { parseParams } from '@core/router';

export function defineLoader<
  ParamsSchema extends z.ZodType = z.ZodType<unknown>,
  R = unknown,
  ID extends string = never,
>(loader: Loader<ParamsSchema, R, ID>): Loader<ParamsSchema, R, ID> {
  return loader;
}

export function loaderHandler<ParamsSchema extends z.ZodType = z.ZodType<unknown>, R = unknown>(
  loader: Loader<ParamsSchema, R>,
): (args: LoaderFunctionArgs) => Promise<R> {
  return args => {
    const params = parseParams(args.params, loader.params);

    const loaderArgs = { params, request: args.request };

    return pipe(
      loader.handler(loaderArgs),
      T.chainFirstIOK(result => () => {
        const redirectUrl = loader.redirect ? loader.redirect({ ...loaderArgs, result }) : null;

        if (redirectUrl) {
          throw redirect(redirectUrl);
        }
      }),
    )();
  };
}

export function httpTaskToResponseTask<R>(task: HttpTask<R>): T.Task<R> {
  return pipe(
    task,
    T.chainIOK(res => () => {
      if (EI.isLeft(res)) {
        throw res.left.toResponse();
      }

      return res.right;
    }),
  );
}

import React, { FC } from 'react';
import { useParentLoader } from '@core/router/loader';
import { loader } from '@modules/inputs/amm/non-mfsc/routes';
import NonMfscInputUsage from '@modules/inputs/amm/non-mfsc/components/detail/NonMfscInputUsage';
import AmmInputContent from '@modules/inputs/amm/components/detail/AmmInputContent';

const NonMfscInputDetailPage: FC = () => {
  const input = useParentLoader<typeof loader>('non-mfsc-input-detail');

  return (
    <AmmInputContent input={input}>
      <NonMfscInputUsage input={input} />
    </AmmInputContent>
  );
};

export default NonMfscInputDetailPage;

import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as IndexesService from '@modules/water/indexes/service';
import { getPageQuery } from '@shared/modules/range/utils';
import { useRange, VirtualizedList } from '@shared/modules/range';
import { identity } from 'fp-ts/function';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { SharedButtonLink } from '@styles/shared';
import { Stack, Text, ThemeIcon } from '@mantine/core';
import { IconDroplet } from '@tabler/icons-react';
import { Water } from '@modules/water/model';
import IndexListRow from '@modules/water/indexes/components/list/row/IndexListRow';
import { indexRangeFilterParser } from '@modules/water/indexes/utils';
import IndexListFilter from '@modules/water/indexes/components/list/filter/IndexListFilter';
import Index = Water.Index;
import { isFilterEmpty } from '@shared/modules/filter';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return httpTaskToResponseTask(
      IndexesService.getWaterIndexesRange(getPageQuery(queries), indexRangeFilterParser(queries)),
    );
  },
});

const IndexesListPage: FC = () => {
  const { range, handleLoadPage, handleFilter } = useRange<typeof loader, Index.Range.Item, Index.Range.Filter>(
    identity,
  );

  const pageProps: PageProps = {
    seoTitle: "Vos relevés d'index",
    back: { title: "Vos relevés d'index", to: '/water' },
    bottomBar: (
      <SharedButtonLink to="new" btnType="create">
        Saisir un index
      </SharedButtonLink>
    ),
  };

  const isEmptyRange = range.total === 0 && isFilterEmpty(range.filter);

  return (
    <Page {...pageProps} bg="background.1" withNav>
      <Stack>
        {!isEmptyRange && <IndexListFilter filter={range.filter} onFilter={handleFilter} />}
        <VirtualizedList
          range={range}
          loadPage={handleLoadPage}
          emptyRender={
            <Stack align="center" ta="center" spacing="lg">
              <ThemeIcon size={95} color="transparent" c="blue.2">
                <IconDroplet size="100%" />
              </ThemeIcon>
              <Text weight={500}>
                {isEmptyRange ? 'Vous n’avez déclaré aucun index' : "Aucun résultat n'a été trouvé"}
              </Text>
            </Stack>
          }
        >
          {IndexListRow}
        </VirtualizedList>
      </Stack>
    </Page>
  );
};

const indexesListRoute = defineRoute({
  component: IndexesListPage,
  loader,
});

export default indexesListRoute;

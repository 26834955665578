import React, { FC, PropsWithChildren } from 'react';
import { Alert, Group, Stack, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { AlertProps } from '@mantine/core/lib/Alert/Alert';
import { renderNullable } from '@shared/utils/render';

interface CustomAlertProps extends AlertProps {
  title?: string;
}

const CustomAlert: FC<PropsWithChildren<CustomAlertProps>> = ({ title, color, children, ...rest }) => {
  return (
    <Alert color={color} {...rest}>
      <Group noWrap>
        <IconInfoCircle color={color} style={{ flex: '0 0 auto' }} />
        <Stack spacing={5}>
          {renderNullable(title, title => (
            <Text lh={1.21} weight={500} color={color}>
              {title}
            </Text>
          ))}

          <Text lh={1.21}>{children}</Text>
        </Stack>
      </Group>
    </Alert>
  );
};

export default CustomAlert;

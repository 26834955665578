import React, { FC } from 'react';
import { TextInput, Select, Stack } from '@mantine/core';
import { getSelectDataFromEnumLabels } from '@shared/utils/enum';
import { Intervention } from '@modules/interventions/model';
import InterventionRecapCard, {
  InterventionRecapCardProps,
} from '@modules/interventions/components/steps/InterventionRecapCard';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Controller } from 'react-hook-form';
import { triggeringFactorRequired } from '@modules/interventions/schema';

const CreateInterventionSecondStep: FC<InterventionRecapCardProps> = recapProps => {
  const {
    register,
    control,
    formState: { errors },
  } = useEnhancedFormContext<Intervention.Create.FormBody>();

  return (
    <>
      <InterventionRecapCard {...recapProps} />
      <Stack px="lg" pt="lg">
        <TextInput
          {...register('targetedPest')}
          error={!!errors.targetedPest}
          label="Organisme nuisible visé"
          placeholder="Saisir"
        />
        <Controller
          control={control}
          name="triggeringFactor"
          render={({ field }) => (
            <Select
              {...field}
              error={!!errors.triggeringFactor}
              label="Facteur déclenchant"
              placeholder="Sélectionner"
              data={getSelectDataFromEnumLabels(Intervention.triggeringFactorLabel)}
              required={triggeringFactorRequired(recapProps.type)}
            />
          )}
        />
      </Stack>
    </>
  );
};

export default CreateInterventionSecondStep;

import React, { FC } from 'react';
import { CustomInput } from '@modules/inputs/custom/model';
import { Stack, TextInput, Text, Group, Box } from '@mantine/core';
import { SharedLink } from '@styles/shared/Link';

interface CreateInterventionFirstStepProps {
  input: CustomInput;
}

const CreateCustomInterventionFirstStep: FC<CreateInterventionFirstStepProps> = ({ input }) => {
  return (
    <Stack px="md" pt="lg">
      <div>
        <TextInput label="Nom de l’intrant" value={input.name} required disabled />
        <Group position="right">
          <Box>
            <SharedLink
              label={
                <Text color="dark.3" weight={500} td="underline">
                  Modifier l'intrant
                </Text>
              }
              to="/inputs/search"
            />
          </Box>
        </Group>
      </div>
      <div>
        <Text size="xs" color="blue.8" weight={500} mb={5}>
          Type de l'intrant
        </Text>
        <Text>{CustomInput.typeLabel[input.customType]}</Text>
      </div>
    </Stack>
  );
};

export default CreateCustomInterventionFirstStep;

import React, { FC } from 'react';
import { Water } from '@modules/water/model';
import { Select, SelectItem, Stack, Text } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { useFetchTaskOption } from '@core/http/hooks';
import * as MetersService from '@modules/water/meters/service';
import { formatDate, parseDate } from '@shared/modules/dates';
import { sequenceT } from 'fp-ts/Apply';
import Index = Water.Index;

interface IndexListFilterProps {
  filter: Index.Range.Filter;
  onFilter: (filter: Index.Range.Filter) => void;
}

const IndexListFilter: FC<IndexListFilterProps> = ({ filter, onFilter }) => {
  const meter = pipe(filter.meterIds ? NEA.head(filter.meterIds) : null);

  const [meters] = useFetchTaskOption(MetersService.searchWaterMeters);

  const metersData: Array<SelectItem> = pipe(
    meters,
    O.fold(
      () => [],
      A.map(({ id, name }) => ({ value: id, label: name })),
    ),
  );

  const dateRange = O.toUndefined(sequenceT(O.Apply)(parseDate(filter.startDate), parseDate(filter.endDate)));

  const handleMeterChange = (meter: Water.Meter.Id | null) =>
    onFilter({
      ...filter,
      meterIds: meter ? [meter] : null,
    });

  const handleDateRangeChange = ([startDate, endDate]: DatesRangeValue) => {
    onFilter({
      ...filter,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    });
  };

  return (
    <Stack spacing="xs">
      <Text size="sm" lh={1} opacity={0.6} weight={500}>
        Filtrer par
      </Text>
      <DatePickerInput
        type="range"
        value={dateRange}
        onChange={handleDateRangeChange}
        placeholder="Date / période"
        allowSingleDateInRange
        clearable
      />
      <Select
        data={metersData}
        placeholder="Compteur"
        onChange={handleMeterChange}
        value={meter}
        nothingFound="Aucun compteur disponible"
        clearable
      />
    </Stack>
  );
};

export default IndexListFilter;

import React, { FC } from 'react';
import MfscSearchForm from '@modules/inputs/search/components/form/mfsc/MfscSearchForm';
import NonMfscSearchForm from '@modules/inputs/search/components/form/non-mfsc/NonMfscSearchForm';
import { Input } from '@modules/inputs/model';
import Source = Input.Source;
import { AmmParamsType, InputParams } from '@modules/inputs/search/model';

interface SearchUsageFormProps {
  detail: Input.View.Detail;
  params: InputParams;
}

const SearchUsageForm: FC<SearchUsageFormProps> = ({ detail, params }) => {
  switch (params.source) {
    case Source.Custom:
      return null;
    case Source.AMM:
      switch (params.type) {
        case AmmParamsType.Mfsc:
          return <MfscSearchForm crops={detail.mfsc} params={params} />;
        case AmmParamsType.NonMfsc:
          return <NonMfscSearchForm crops={detail.nonMfsc} params={params} />;
      }
  }
};

export default SearchUsageForm;

import { z } from 'zod';
import { Input } from '@modules/inputs/model';
import { OpenData } from '@shared/modules/opendata/model';

export namespace InputSchema {
  export const searchValueSchema = z.object({
    inputId: Input.Id,
    source: z.nativeEnum(Input.Source),
    nameId: Input.NameId.nullable(),
    tradeLicenceId: OpenData.TradeLicence.Id.nullable(),
  });
}

import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as TE from 'fp-ts/TaskEither';
import { getStringQuery } from '@shared/modules/filter';
import { HttpError } from '@core/http';
import SuccessPage from '@shared/components/success-page/SuccessPage';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return httpTaskToResponseTask(TE.fromNullable(HttpError.notFound)(getStringQuery(queries, 'name')));
  },
});
const MeterSuccessPage: FC = () => {
  const name = useLoader<typeof loader>();

  return (
    <SuccessPage
      description={`Le compteur ${name} a bien été ajouté, vous pouvez désormais le consulter depuis la liste de vos compteurs.`}
      to="/water/meters"
    >
      Consulter les compteurs
    </SuccessPage>
  );
};

const meterSuccessRoute = defineRoute({
  component: MeterSuccessPage,
  loader,
});
export default meterSuccessRoute;

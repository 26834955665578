import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import * as IndexesService from '@modules/water/indexes/service';
import * as EI from 'fp-ts/Either';
import { indexSchema } from '@modules/water/indexes/schema';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { Stack, Text } from '@mantine/core';
import { SharedButton } from '@styles/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { constNull, pipe } from 'fp-ts/function';
import { Water } from '@modules/water/model';
import IndexForm from '@modules/water/indexes/components/form/IndexForm';
import { stringifyQueries } from '@shared/utils/queries';
import { SharedLink } from '@styles/shared/Link';
import { formatDate } from '@shared/modules/dates';
import * as D from 'fp-ts/Date';
import { indexErrorFlashHandler } from '@modules/water/indexes/utils';
import Index = Water.Index;

const actions = {
  create: defineAction({
    type: 'create',
    payload: indexSchema,
    handler: ({ payload }) => IndexesService.createWaterIndex(payload),
    redirect: ({ result }) =>
      pipe(
        result,
        EI.fold(constNull, ({ meterName }) => ({
          pathname: '/water/indexes/success',
          search: stringifyQueries({ meterName }),
        })),
      ),
    flashOptions: {
      error: ({ error }) => indexErrorFlashHandler(error),
    },
  }),
};

const CreateIndexPage: FC = () => {
  const [createLoading, createIndex] = useAction(actions.create);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Index.Params>({
    defaultValues: {
      date: formatDate(D.create()),
    },
    resolver: zodResolver(indexSchema),
  });

  const handleCreateIndex = (params: Index.Params) => () => createIndex(params);

  const pageProps: PageProps = {
    seoTitle: 'Nouvel index',
    back: {
      title: 'Nouvel index',
      to: '/water/indexes',
    },
    bottomBar: (
      <Stack spacing={10} align="center">
        <SharedButton btnType="create" loading={createLoading} onClick={handleFormSubmit}>
          Créer
        </SharedButton>

        <SharedLink
          to="/water/indexes"
          label={
            <Text color="dark.3" weight={500} size="md" td="underline">
              Annuler
            </Text>
          }
        />
      </Stack>
    ),
  };

  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateIndex} preventLeave>
        <IndexForm />
      </EnhancedForm>
    </Page>
  );
};

const createIndexRoute = defineRoute({
  component: CreateIndexPage,
  actions,
});

export default createIndexRoute;

import React, { FC } from 'react';
import { Box } from '@mantine/core';
import CustomInterventionContextForm from '@modules/interventions/components/form/context/CustomInterventionContextForm';

const CreateCustomInterventionFourthStep: FC = () => {
  return (
    <Box px="lg" pt="lg">
      <CustomInterventionContextForm />
    </Box>
  );
};

export default CreateCustomInterventionFourthStep;

import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { constNull, pipe } from 'fp-ts/function';
import { defineAction, useAction } from '@core/router/action';
import { zodResolver } from '@hookform/resolvers/zod';
import { meterSchema } from '@modules/water/meters/schema';
import { Water } from '@modules/water/model';
import { defineRoute } from '@core/router';
import * as MetersService from '@modules/water/meters/service';
import * as EI from 'fp-ts/Either';
import { NavLink, Stack, Text } from '@mantine/core';
import { SharedButton } from '@styles/shared';
import { NavLink as RouterNavLink } from 'react-router-dom';
import MeterForm from '@modules/water/meters/components/form/MeterForm';
import { HttpStatusCode } from '@core/http';
import { stringifyQueries } from '@shared/utils/queries';
import Meter = Water.Meter;

const actions = {
  create: defineAction({
    type: 'create',
    payload: meterSchema,
    handler: ({ payload }) => MetersService.createWaterMeter(payload),
    redirect: ({ result }) =>
      pipe(
        result,
        EI.fold(constNull, ({ name }) => ({
          pathname: '/water/meters/success',
          search: stringifyQueries({ name }),
        })),
      ),
    flashOptions: {
      error: ({ error }) => (error.status === HttpStatusCode.CONFLICT ? 'Numéro de série déjà utilisé' : true),
    },
  }),
};

const CreateMeterPage: FC = () => {
  const [createLoading, createMeter] = useAction(actions.create);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Meter.Params>({
    resolver: zodResolver(meterSchema),
  });
  const handleCreateMeter = (params: Meter.Params) => () => createMeter(params);

  const pageProps: PageProps = {
    seoTitle: 'Nouveau compteur',
    back: {
      title: 'Nouveau compteur',
      to: '/water/meters',
    },
    bottomBar: (
      <Stack spacing={10} align="center">
        <SharedButton btnType="create" loading={createLoading} onClick={handleFormSubmit}>
          Créer
        </SharedButton>

        <NavLink
          component={RouterNavLink}
          to="/water/meters"
          label={
            <Text color="dark.3" weight={500} size="md" td="underline">
              Annuler
            </Text>
          }
        />
      </Stack>
    ),
  };

  return (
    <Page {...pageProps} bg="background.1">
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateMeter} preventLeave>
        <MeterForm />
      </EnhancedForm>
    </Page>
  );
};

const createMeterRoute = defineRoute({
  component: CreateMeterPage,
  actions,
});

export default createMeterRoute;

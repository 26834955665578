import { DateFormat, DateTypes } from './model';
import { format, isValid, parse } from 'date-fns';

import fr from 'date-fns/locale/fr';

import * as O from 'fp-ts/Option';
import { identity, pipe } from 'fp-ts/function';
import { filterEmptyStringToOption } from '../../utils/string';

export function parseDate<F extends string = DateFormat.LocalDate>(
  date: (F extends DateFormat ? DateTypes[F] : string) | null | undefined,
  formatStr?: F,
): O.Option<Date>;

export function parseDate<E, F extends string = DateFormat.LocalDate>(
  date: (F extends DateFormat ? DateTypes[F] : string) | null | undefined,
  formatStr: F,
  orElse: E,
): Date | E;

export function parseDate<E>(
  date: string | null | undefined,
  formatStr: string = DateFormat.LocalDate,
  orElse: E | O.Option<Date> = O.none,
): O.Option<Date> | Date | E {
  return pipe(
    filterEmptyStringToOption(date),
    O.map(date => parse(date, formatStr, new Date(), { locale: fr })),
    O.filter(isValid),
    orElse === O.none ? identity : O.getOrElseW(() => orElse),
  );
}

type FormatDateResult<F> = F extends DateFormat ? DateTypes[F] : string;

export function formatDate<D extends Date | number | null | undefined, F extends string = DateFormat.LocalDate>(
  date: D,
  formatStr?: F,
): D extends NonNullable<D> ? FormatDateResult<F> : null;

export function formatDate<E, D extends Date | number | null | undefined, F extends string = DateFormat.LocalDate>(
  date: D,
  formatStr: F,
  orElse: E,
): D extends NonNullable<D> ? FormatDateResult<F> : E;

export function formatDate<E>(
  date: Date | number | null | undefined,
  formatStr: string = DateFormat.LocalDate,
  orElse?: E,
): string | E | null {
  return pipe(
    O.fromNullable(date),
    O.map(date =>
      format(date, formatStr, {
        locale: fr,
      }),
    ),
    O.getOrElseW(() => orElse ?? null),
  );
}

import { Input } from '@modules/inputs/model';

export enum AmmParamsType {
  Mfsc = 'mfsc',
  NonMfsc = 'non-mfsc',
}

export interface MfscParams {
  type: AmmParamsType.Mfsc;
  usage: Input.View.Mfsc.Usage;
  crop: Input.View.Mfsc.Crop;
}

export interface NonMfscParams {
  type: AmmParamsType.NonMfsc;
  usage: Input.View.Usage;
  pest: Input.View.Pest;
  crop: Input.View.Crop;
}

export type AmmParams = (MfscParams | NonMfscParams) & {
  source: Input.Source.AMM;
};

export interface CustomParams {
  source: Input.Source.Custom;
}

export type InputParams = AmmParams | CustomParams;

export interface InputFormBody {
  params: InputParams | null;
  detail: Input.View.Detail | null;
  state: Input.State;
}

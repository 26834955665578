import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute, defineRoute } from '@core/router';
import { z } from 'zod';
import { createNewTypeStringSchema } from '@shared/schemas';
import { Input } from '@modules/inputs/model';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import * as InputsService from '@modules/inputs/service';
import { OpenData } from '@shared/modules/opendata/model';
import MfscInputDetailPage from '@modules/inputs/amm/mfsc/DetailPage';
import createMfscInterventionRoute from '@modules/inputs/amm/mfsc/CreatePage';

const mfscParamsSchema = z.object({
  id: Input.Id,
  usageId: createNewTypeStringSchema<OpenData.MfscUsageId>(),
});

export const loader = defineLoader({
  id: 'mfsc-input-detail',
  params: mfscParamsSchema,
  handler: ({ params }) => httpTaskToResponseTask(InputsService.getMfscInputDetail(params.id, params.usageId)),
});

const mfscInputRoutes = (
  <Route
    path="mfsc/:id/:usageId"
    {...createRoute(
      defineRoute({
        loader,
      }),
    )}
  >
    <Route index element={<MfscInputDetailPage />} />
    <Route path="new" {...createRoute(createMfscInterventionRoute)} />
  </Route>
);

export default mfscInputRoutes;

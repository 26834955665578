import { Input } from '@modules/inputs/model';
import { httpService, HttpTask } from '@core/http';
import { CustomInput } from '@modules/inputs/custom/model';
import { OpenData } from '@shared/modules/opendata/model';
import { NonMfscInput } from '@modules/inputs/amm/non-mfsc/model';
import { MfscInput } from '@modules/inputs/amm/mfsc/model';

const URI = '/inputs';

export function getCustomInputDetail(id: Input.Id): HttpTask<CustomInput> {
  return httpService.get(`${URI}/custom/${id}`);
}

export function getNonMfscInputDetail(
  inputId: Input.Id,
  usageId: OpenData.ProductUsageId,
  tradeLicenceId: OpenData.TradeLicence.Id | null,
): HttpTask<NonMfscInput> {
  return httpService.get(`${URI}/amm/${inputId}/${usageId}`, { params: { tradeLicenceId } });
}

export function getMfscInputDetail(inputId: Input.Id, usageId: OpenData.MfscUsageId): HttpTask<MfscInput> {
  return httpService.get(`${URI}/amm-mfsc/${inputId}/${usageId}`);
}

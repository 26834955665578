import { defaultAxiosInstance } from '@core/http/config';
import { HttpError, HttpRange, HttpStatusCode, HttpTask } from '@core/http/model';
import { Filter } from '@shared/modules/filter';
import { RangeCursor, RangeResult } from '@shared/modules/range';
import { logSentryHttpError } from '@shared/modules/sentry/utils';
import { removeEmptyString } from '@shared/utils/string';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Lazy, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { refreshToken } from '@modules/auth/service';

export function onError<E = unknown>(err: unknown): HttpError<E> {
  const error = HttpError.fromAxiosError<E>(err as AxiosError<E>);

  if (
    error.status >= 400 &&
    ![
      HttpStatusCode.UNAUTHORIZED,
      HttpStatusCode.FORBIDDEN,
      HttpStatusCode.NOT_FOUND,
      HttpStatusCode.CONFLICT,
    ].includes(error.status)
  ) {
    console.error(error);
    logSentryHttpError(`[http] error ${error.status} on ${O.getOrElse(() => 'unknown')(error.url)} path`, error);
  }

  return error;
}

function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E>;
function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>, raw?: true): HttpTask<AxiosResponse<R>, E>;

function transformRequest<R, E>(
  request: Lazy<Promise<AxiosResponse<R>>>,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return pipe(
    TE.tryCatch(request, onError<E>),
    TE.map(res => (raw ? res : res.data)),
  );
}

export function handleError<R, E>(
  err: HttpError<E>,
  request: Lazy<Promise<AxiosResponse<R>>>,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  if (err.status === HttpStatusCode.UNAUTHORIZED) {
    const canRefresh = pipe(
      err.url,
      O.exists(url => !url.startsWith('/authenticate')),
    );

    if (canRefresh) {
      return pipe(
        refreshToken(),
        TE.chain(() => transformRequest(request, raw)),
        // Return original error on fail
        TE.mapLeft(() => err),
      );
    }
  }

  return TE.left(err);
}

function runAxiosTask<R, E>(request: Lazy<Promise<AxiosResponse<R>>>, raw?: true): HttpTask<R | AxiosResponse<R>, E> {
  return pipe(
    transformRequest<R, E>(request, raw),
    TE.orElse(err => handleError<R, E>(err, request)),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig = {},
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => defaultAxiosInstance.get(url, config), raw);
}

function getRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  page: number,
  filter: F,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...RangeCursor.fromPage(page),
        ...filter,
      },
    }),
    TE.map(res => ({
      ...res,
      filter,
    })),
  );
}

function removeEmptyStringOnBody(body?: any) {
  if (!(body instanceof FormData)) {
    return removeEmptyString(body);
  }

  return body;
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return runAxiosTask(() => defaultAxiosInstance.post(url, removeEmptyStringOnBody(data), config), raw);
}

function put<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function put<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function put<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return runAxiosTask(() => defaultAxiosInstance.put(url, removeEmptyStringOnBody(data), config), raw);
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<R | AxiosResponse<R>, E>;

function del<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return runAxiosTask(() => defaultAxiosInstance.delete(url, config), raw);
}

export const httpService = {
  get,
  getRange,
  post,
  put,
  delete: del,
};

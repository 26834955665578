import { Newtype } from 'newtype-ts';
import { operatorSchema } from '@modules/operators/schema';
import z from 'zod';
import { LocalDate } from '@shared/modules/dates';

export namespace Operator {
  export type Id = Newtype<{ readonly ID: unique symbol }, string> & string;
  export type CertyphytoNumber = Newtype<{ readonly CERTYPHYTO_NUMBER: unique symbol }, string> & string;

  export enum Role {
    Leader = 'leader',
    Member = 'member',
  }

  export const roleLabel: Record<Role, string> = {
    [Role.Leader]: 'Décideur',
    [Role.Member]: 'Membre',
  };

  export namespace Range {
    export interface Item {
      id: Id;
      lastName: string;
      firstName: string;
      certyphytoNumber: CertyphytoNumber;
      certyphytoExpirationDate: LocalDate;
      role: Role;
    }
  }

  export type Params = z.infer<typeof operatorSchema>;

  export namespace List {
    export interface Item {
      id: Id;
      lastName: string;
      firstName: string;
    }
  }
}

export interface Operator {
  id: Operator.Id;
  lastName: string;
  firstName: string;
  certyphytoNumber: Operator.CertyphytoNumber;
  certyphytoExpirationDate: LocalDate;
  role: Operator.Role;
}

import { HttpRange, httpService, HttpTask } from '@core/http';
import { Water } from '@modules/water/model';
import Index = Water.Index;

export const URI = '/water/indexes';

export function getWaterIndexesRange(
  page: number,
  filter: Index.Range.Filter,
): HttpRange<Index.Range.Item, Index.Range.Filter> {
  return httpService.getRange(URI, page, filter);
}

export function createWaterIndex(params: Index.Params): HttpTask<Index, Water.Index.Error> {
  return httpService.post(URI, params);
}

export function getWaterIndex(id: Index.Id): HttpTask<Index> {
  return httpService.get(`${URI}/${id}`);
}

export function updateWaterIndex(id: Index.Id, params: Index.Params): HttpTask<Index, Water.Index.Error> {
  return httpService.post(`${URI}/${id}`, params);
}

export function deleteWaterIndex(id: Index.Id): HttpTask {
  return httpService.delete(`${URI}/${id}`);
}

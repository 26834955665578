import { Route } from 'react-router-dom';
import React from 'react';
import WaterListPage from '@modules/water/ListPage';
import metersRoutes from '@modules/water/meters/routes';
import indexesRoutes from '@modules/water/indexes/routes';

const waterRoutes = (
  <Route path="water">
    <Route index element={<WaterListPage />} />
    {metersRoutes}
    {indexesRoutes}
  </Route>
);

export default waterRoutes;

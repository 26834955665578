import React from 'react';
import { Route } from 'react-router-dom';
import waterReportRoutes from '@modules/reports/water/routes';
import inputReportRoutes from '@modules/reports/inputs/routes';

const reportRoutes = (
  <Route path="reports">
    {waterReportRoutes}
    {inputReportRoutes}
  </Route>
);

export default reportRoutes;

import React, { FC, ReactNode } from 'react';
import { Box, Image, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import { SharedButtonLink } from '@styles/shared';
import golfImage from '@assets/images/golf.svg';
import { To } from 'react-router-dom';
import * as S from 'fp-ts/string';

interface SuccessPageProps {
  description: string;
  to: To;
  children: ReactNode;
}

const SuccessPage: FC<SuccessPageProps> = ({ description, to, children }) => {
  return (
    <Box bg="blue.9" pos="relative" h="100%">
      <Stack
        align="center"
        spacing="lg"
        px="lg"
        pos="absolute"
        top="50%"
        style={{ zIndex: 10, transform: 'translateY(-50%)' }}
      >
        <ThemeIcon size={95} color="transparent">
          <IconCircleCheck size="100%" stroke={1.4} />
        </ThemeIcon>
        <Text ta="center" color="white" lh={1.25}>
          {description}
        </Text>
        {S.isString(children) ? (
          <SharedButtonLink to={to} color="green.7">
            {children}
          </SharedButtonLink>
        ) : (
          children
        )}
      </Stack>
      <Image src={golfImage} width={250} pos="absolute" bottom={0} right={0} />
    </Box>
  );
};

export default SuccessPage;

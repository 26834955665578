import * as NEA from 'fp-ts/NonEmptyArray';
import { Input } from '@modules/inputs/model';
import React, { FC } from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { Select } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import { useEnhancedFormContext } from '@shared/modules/form';
import { OpenData } from '@shared/modules/opendata/model';
import { InputFormBody, MfscParams } from '@modules/inputs/search/model';
import { placeholder } from '@modules/inputs/amm/utils';

interface MsfcUsageFormProps {
  params: MfscParams;
  crops: Array<Input.View.Mfsc.Crop>;
}

type FormContext = InputFormBody & {
  params: MfscParams;
};

const MsfcUsageForm: FC<MsfcUsageFormProps> = ({ crops, params }) => {
  const { setValue } = useEnhancedFormContext<FormContext>();

  const handleCropChange = (name: string | null) => {
    const crop = pipe(
      crops,
      A.findFirst(crop => crop.name === name),
      O.toNullable,
    );

    if (crop) setValue('params.crop', crop);
  };

  const handleUsageChange = (usages: Array<Input.View.Mfsc.Usage>) => (usageId: OpenData.MfscUsageId | null) => {
    const usage = pipe(
      usages,
      A.findFirst(usage => usage.usageId === usageId),
      O.toNullable,
    );

    if (usage) setValue('params.usage', usage);
  };

  return (
    <>
      <Select
        value={params.crop.name}
        onChange={handleCropChange}
        label="Culture"
        placeholder="Sélectionner"
        data={crops.map(({ name }) => ({ value: name, label: name }))}
        required
      />
      {renderOptional(NEA.fromArray(params.crop.pests), usages => (
        <Select
          value={params.usage.usageId}
          label="Unité de dosage max"
          placeholder="Sélectionner"
          data={usages.map(({ unit, usageId }) => ({ value: usageId, label: unit ?? placeholder }))}
          onChange={handleUsageChange(usages)}
          required
        />
      ))}
    </>
  );
};

export default MsfcUsageForm;

import React, { FC } from 'react';
import { Badge, Box, Title } from '@mantine/core';
import { Input } from '@modules/inputs/model';
import { Intervention } from '@modules/interventions/model';
import TreatmentUnit = Intervention.TreatmentUnit;
import InterventionRecapDoses from '@modules/interventions/components/steps/InterventionRecapDoses';

export interface InterventionRecapCardProps {
  name: string;
  type: Input.Type;
  dose?: number | null;
  doseUnit?: TreatmentUnit | null;
}

const InterventionRecapCard: FC<InterventionRecapCardProps> = ({ name, type, dose, doseUnit }) => {
  return (
    <Box bg="white" p="lg">
      <Title size="h2">{name}</Title>
      <Badge color="gray.7" bg="gray.2" size="md" tt="none" fw="normal" mt={5} radius={4} p={5}>
        {Input.typeLabel[type]}
      </Badge>
      <InterventionRecapDoses dose={dose} doseUnit={doseUnit} pt="xs" />
    </Box>
  );
};

export default InterventionRecapCard;

import React, { FC } from 'react';
import Page, { PageProps } from '@layout/page/Page';
import { Card, Stack } from '@mantine/core';
import { rgba } from 'polished';
import SimpleCard from '@shared/components/list/card/SimpleCard';
import { IconFileReport, IconNotebook } from '@tabler/icons-react';
import { Report } from '@modules/reports/model';

const WaterReportListPage: FC = () => {
  const pageProps: PageProps = {
    seoTitle: 'Rapports eau',
    back: {
      to: '/',
      title: 'Rapports eau',
    },
  };

  return (
    <Page {...pageProps} withNav>
      <Card p="lg" withBorder={false} sx={theme => ({ background: rgba(theme.colors.blue[2], 0.15) })}>
        <Stack>
          <SimpleCard
            to={Report.Code.Water}
            icon={IconFileReport}
            title={Report.pageTitle[Report.Code.Water]}
            sx={theme => ({ background: rgba(theme.colors.blue[9], 0.85) })}
          />
          <SimpleCard
            bg="#2363a4"
            to={Report.Code.WaterBook}
            icon={IconNotebook}
            title={Report.pageTitle[Report.Code.WaterBook]}
          />
        </Stack>
      </Card>
    </Page>
  );
};

export default WaterReportListPage;

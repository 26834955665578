import React, { FC } from 'react';
import { Intervention } from '@modules/interventions/model';
import { Accordion, Badge, Box, Card, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import InputSimpleInfo from '@modules/inputs/components/InputSimpleInfo';
import { Input } from '@modules/inputs/model';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { constPlaceholder } from '@modules/inputs/amm/utils';
import { identity, pipe } from 'fp-ts/function';
import { Zone } from '@modules/zones/model';
import { IconAsterisk, IconListDetails, IconMessagePlus } from '@tabler/icons-react';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as A from 'fp-ts/Array';
import Source = Input.Source;
import UpdateInterventionUsageModal from './usage/UpdateInterventionUsageModal';
import { useAction } from '@core/router/action';
import { actions } from '@modules/interventions/DetailPage';
import { getDosageParamsFromFormBody, getUpdateParamsFromDetail } from '@modules/interventions/utils';
import UpdateInterventionDosageModal from '@modules/interventions/components/form/dosage/UpdateInterventionDosageModal';
import UpdateInterventionContextModal from '@modules/interventions/components/form/context/UpdateInterventionContextModal';
import InterventionInputDetail from '@modules/interventions/components/form/input/InterventionInputDetail';

interface InterventionDosageTotalCardProps {
  title: string;
  total: number | null;
  unit: string;
}

const InterventionDosageTotalCard: FC<InterventionDosageTotalCardProps> = ({ title, total, unit }) => {
  return (
    <Card bg="gray.2" px={8} py={5} radius={4} w="fit-content" withBorder={false}>
      <Text size="xs" lh={1.25} weight={500}>
        {title}
      </Text>
      <Text>{renderNullable(total, total => `${total} ${unit}`, constPlaceholder)}</Text>
    </Card>
  );
};

interface UpdateInterventionFormProps {
  intervention: Intervention;
}

enum InterventionAccordionValue {
  Input = 'input',
  Usage = 'usage',
  Dosage = 'dosage',
  Context = 'context',
}

const UpdateInterventionForm: FC<UpdateInterventionFormProps> = ({ intervention }) => {
  const [updateLoading, updateIntervention] = useAction(actions.update);

  const handleUsageUpdate = (body: Intervention.Update.FormBody.Usage) => () =>
    updateIntervention({ ...getUpdateParamsFromDetail(intervention), ...body });

  const handleDosageUpdate = (body: Intervention.Update.FormBody.Dosage) => () =>
    updateIntervention({ ...getUpdateParamsFromDetail(intervention), ...getDosageParamsFromFormBody(body) });

  const handleContextUpdate = (body: Intervention.Update.FormBody.Context) => () =>
    updateIntervention({ ...getUpdateParamsFromDetail(intervention), ...body });

  const [leftUnit, rightUnit] = intervention.treatment.unit.split('/');

  return (
    <Accordion
      p={20}
      variant="separated"
      defaultValue={InterventionAccordionValue.Input}
      styles={{ content: { paddingBottom: 5 } }}
    >
      {intervention.type === Input.Source.AMM && (
        <Accordion.Item value={InterventionAccordionValue.Input}>
          <Accordion.Control>
            <Title size="h3">Intrant</Title>
          </Accordion.Control>
          <Accordion.Panel>
            <InterventionInputDetail input={intervention.input} />
          </Accordion.Panel>
        </Accordion.Item>
      )}
      <Accordion.Item value={InterventionAccordionValue.Usage}>
        <Accordion.Control>
          <Title size="h3">Usage</Title>
        </Accordion.Control>
        <Accordion.Panel pb={0}>
          <InputSimpleInfo title="Organisme nuisible visé" py={undefined} pb={15}>
            {renderNullable(intervention.targetedPest, identity, constPlaceholder)}
          </InputSimpleInfo>
          <InputSimpleInfo title="Facteur déclenchant">
            {Intervention.triggeringFactorLabel[intervention.triggeringFactor]}
          </InputSimpleInfo>
          <UpdateInterventionUsageModal
            intervention={intervention}
            onUpdate={handleUsageUpdate}
            loading={updateLoading}
          />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value={InterventionAccordionValue.Dosage}>
        <Accordion.Control>
          <Group>
            <Title size="h3">Dosage</Title>
            <Badge color="blue" fw={500}>
              Par {Intervention.treatmentTypeLabel[intervention.treatmentType]}
            </Badge>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          {renderNullable(intervention.zone, zone => (
            <Stack pb="xl" spacing={8}>
              <Group position="apart">
                <Text weight={500} lh={1.25}>
                  {zone.name}
                </Text>
                <Text weight={500} lh={1.25}>
                  {intervention.totalZoneAreaValue} Ha
                </Text>
              </Group>
              <Stack spacing={8}>
                {zone.type === Zone.Type.Game ? (
                  pipe(
                    zone.areas,
                    A.filter(({ type }) => intervention.zoneAreaTypes.includes(type)),
                    A.sort(Zone.Area.ord),
                    A.map(({ type }) => <Text key={type}>{Zone.Area.gameTypeLabel[type]}</Text>),
                  )
                ) : (
                  <Group position="apart">
                    <Text>{Zone.Area.noGameTypeLabel[zone.area.type]}</Text>
                    <Text>{zone.area.value} Ha</Text>
                  </Group>
                )}
              </Stack>
            </Stack>
          ))}
          <Group spacing={8}>
            <ThemeIcon color="blue.2" variant="light" bg="transparent">
              <IconAsterisk />
            </ThemeIcon>
            <Title size="h3">Traitement</Title>
          </Group>
          <Group position="apart" noWrap>
            <Box>
              <InputSimpleInfo title="Dose appliquée" sx={undefined}>
                {intervention.treatment.doseValue} {intervention.treatment.unit}
              </InputSimpleInfo>
              <InputSimpleInfo title="Volume de bouillie" sx={undefined}>
                {renderNullable(
                  intervention.treatment.mushVolumeValue,
                  volume => `${volume} ${rightUnit ? `L/${rightUnit}` : ''}`,
                  constPlaceholder,
                )}
              </InputSimpleInfo>
            </Box>
            <Stack spacing="xs">
              <InterventionDosageTotalCard
                title="Quantité / traitement"
                total={intervention.treatment.doseTotalQuantity}
                unit={leftUnit ?? ''}
              />
              <InterventionDosageTotalCard
                title="Vol bouillie / traitement"
                total={intervention.treatment.mushVolumeTotalQuantity}
                unit="L"
              />
            </Stack>
          </Group>
          <UpdateInterventionDosageModal
            intervention={intervention}
            onUpdate={handleDosageUpdate}
            loading={updateLoading}
          />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value={InterventionAccordionValue.Context}>
        <Accordion.Control>
          <Title size="h3">Contexte</Title>
        </Accordion.Control>
        <Accordion.Panel>
          <Badge color="gray" fw="normal">
            {intervention.date}
          </Badge>
          <InputSimpleInfo title="Opérateur" sx={undefined}>
            {intervention.operator.firstName} {intervention.operator.lastName}
          </InputSimpleInfo>
          {renderOptional(NEA.fromArray(intervention.equipments), equipments => (
            <Stack spacing="xs" pt={5}>
              <Group spacing={8}>
                <ThemeIcon color="blue.2" variant="light" bg="transparent">
                  <IconListDetails />
                </ThemeIcon>
                <Title size="h3">Équipements</Title>
              </Group>
              {equipments.map(({ label, maintenanceDate }, index) => (
                <Stack key={index} spacing={5}>
                  <Text>{label}</Text>
                  {renderNullable(maintenanceDate, date => (
                    <Badge color="gray" fw="normal" w="fit-content">
                      {date}
                    </Badge>
                  ))}
                </Stack>
              ))}
            </Stack>
          ))}
          <Group spacing={8} pt="lg">
            <ThemeIcon color="blue.2" variant="light" bg="transparent">
              <IconMessagePlus />
            </ThemeIcon>
            <Title size="h3">Informations supplémentaires</Title>
          </Group>
          {intervention.type === Source.AMM && (
            <InputSimpleInfo title="Autre méthode utilisées">
              {renderNullable(intervention.otherMethods, identity, constPlaceholder)}
            </InputSimpleInfo>
          )}
          <InputSimpleInfo title="Commentaire">
            {renderNullable(intervention.comments, identity, constPlaceholder)}
          </InputSimpleInfo>
          <UpdateInterventionContextModal
            intervention={intervention}
            onUpdate={handleContextUpdate}
            loading={updateLoading}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default UpdateInterventionForm;

import styled from '@emotion/styled';
import { SimpleGrid } from '@mantine/core';
import { rgba } from 'polished';

export const HomeReportCards = styled(SimpleGrid)`
  > div,
  a {
    padding: ${({ theme }) => theme.spacing.sm}px;
    border: 2px solid ${({ theme }) => rgba(theme.colors.blue[6], 0.85)} !important;
    > svg {
      stroke: ${({ theme }) => rgba(theme.colors.blue[6], 0.85)};
    }

    > p {
      font-size: 14px;
    }
  }
`;

import React, { FC } from 'react';
import { Intervention } from '@modules/interventions/model';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Box, Card, Title } from '@mantine/core';
import { Input } from '@modules/inputs/model';
import InterventionInputDetail from '@modules/interventions/components/form/input/InterventionInputDetail';
import UpdateInterventionUsageForm from '@modules/interventions/components/form/usage/UpdateInterventionUsageForm';
import UpdateInterventionDosageForm from '../form/dosage/UpdateInterventionDosageForm';
import UpdateInterventionContextForm from '@modules/interventions/components/form/context/UpdateInterventionContextForm';

interface DuplicateInterventionFormProps {
  intervention: Intervention;
}

const DuplicateInterventionForm: FC<DuplicateInterventionFormProps> = ({ intervention }) => {
  const { handleSubmit } = useEnhancedFormContext<Intervention.Update.FormBody>();

  return (
    <Box component="form" p={20} onSubmit={handleSubmit} noValidate>
      {intervention.type === Input.Source.AMM ? (
        <Box mb={20}>
          <Title size="h3">Intrant</Title>

          <Card mt={10} px={20} py={0} withBorder={false}>
            <InterventionInputDetail input={intervention.input} />
          </Card>
        </Box>
      ) : null}

      <Box>
        <Title size="h3">Usage</Title>

        <Card mt={10} p={20} withBorder={false}>
          <UpdateInterventionUsageForm />
        </Card>
      </Box>

      <Box mt={20}>
        <Title size="h3">Dosage</Title>

        <Card mt={10} p={20} withBorder={false}>
          <UpdateInterventionDosageForm intervention={intervention} />
        </Card>
      </Box>

      <Box mt={20}>
        <Title size="h3">Contexte</Title>

        <Card mt={10} p={20} withBorder={false}>
          <UpdateInterventionContextForm intervention={intervention} />
        </Card>
      </Box>
    </Box>
  );
};

export default DuplicateInterventionForm;

import React, { createElement, FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Card, CardProps, Group, Stack, Text } from '@mantine/core';
import { Icon } from '@tabler/icons-react';

interface SimpleCardProps {
  to: NavLinkProps['to'];
  icon: Icon;
  title: string;
  description?: string;
}

const SimpleCard: FC<SimpleCardProps & Partial<CardProps>> = ({ to, icon, title, description, ...rest }) => {
  return (
    <Card component={NavLink} to={to} p="sm" withBorder={false} {...rest}>
      <Stack spacing="xs">
        <Group spacing="xs">
          {createElement(icon, { size: 25, color: 'white' })}
          <Text tt="uppercase" color="white" weight={500}>
            {title}
          </Text>
        </Group>
        <Text size="sm" color="white" lh={1.21}>
          {description ?? <>&nbsp;</>}
        </Text>
      </Stack>
    </Card>
  );
};

export default SimpleCard;

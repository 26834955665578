import { Input } from '@modules/inputs/model';
import { AmmParamsType, InputParams } from '@modules/inputs/search/model';
import { ReactElement, ReactNode } from 'react';
import { InputContext, InputContextParams } from '@modules/inputs/components/context/model';
import { Navigate, useOutletContext } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

interface InputContextProviderProps<
  P extends InputParams = InputParams,
  S extends Input.Source | undefined = undefined,
  T extends AmmParamsType | undefined = undefined,
> {
  source?: S;
  type?: T;
  children: (context: InputContext & { params: P }) => ReactNode;
}

export function InputContextProvider({ children }: InputContextProviderProps): ReactElement;
export function InputContextProvider<S extends Input.Source>({
  source,
  children,
}: InputContextProviderProps<Extract<InputParams, { source: S }>, S>): ReactElement;
export function InputContextProvider<S extends Input.Source, T extends AmmParamsType>({
  source,
  type,
  children,
}: InputContextProviderProps<Extract<InputParams, { source: S; type: T }>, S, T>): ReactElement;
export function InputContextProvider<S extends Input.Source, T extends AmmParamsType>({
  source,
  type,
  children,
}: InputContextProviderProps<any, S, T>) {
  const [context] = useOutletContext<InputContextParams>();

  return pipe(
    O.fromNullable(context),
    O.filter(({ params }) => !source || params.source === source),
    O.filter(({ params }) => !type || ('type' in params && params.type === type)),
    O.foldW(
      () => <Navigate to="/inputs/search" replace />,
      context => children(context satisfies InputContext),
    ),
  );
}

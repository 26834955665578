import React, { FC, useCallback } from 'react';
import {
  ActionIcon,
  Center,
  Group,
  Image,
  Modal,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconInfoCircle, IconMessagePlus } from '@tabler/icons-react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { DateFormat, formatDate, LocalDate, parseDate } from '@shared/modules/dates';
import { Controller } from 'react-hook-form';
import { newTypeWrap } from '@shared/schemas';
import { useFetchTaskOption } from '@core/http/hooks';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import InterventionEquipments from '@modules/interventions/components/steps/4/InterventionEquipments';
import { getSelectDataFromEnumLabels } from '@shared/utils/enum';
import { Intervention } from '@modules/interventions/model';
import { Input } from '@modules/inputs/model';
import { useDisclosure } from '@mantine/hooks';
import beaufortImage from '@assets/images/beaufort.jpg';
import { renderOptional } from '@shared/utils/render';
import CustomAlert from '@shared/components/alert/CustomAlert';
import { MantineColor } from '@mantine/styles';
import { HttpError, HttpTask } from '@core/http';
import { Operator } from '@modules/operators/model';
import * as TE from 'fp-ts/TaskEither';

interface AmmInterventionContextFormProps {
  getOperators: (interventionDate: LocalDate) => HttpTask<Array<Operator>>;
}

type FormContextType = Extract<Intervention.Create.FormBody, { type: Input.Source.AMM }>;
const AmmInterventionContextForm: FC<AmmInterventionContextFormProps> = ({ getOperators }) => {
  const [modalOpen, handlers] = useDisclosure(false);
  const {
    control,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useEnhancedFormContext<FormContextType>();

  const interventionDate = watch('date');
  const windForce = watch('windForce');

  const handleDateChange = (value: Date | null) =>
    setValue('date', formatDate(value, DateFormat.LocalDate, newTypeWrap('')));

  const getOperatorsNullable = useCallback(
    (interventionDate: LocalDate | null) =>
      interventionDate ? getOperators(interventionDate) : TE.left(HttpError.notFound),
    [getOperators],
  );

  const [operators] = useFetchTaskOption(getOperatorsNullable, interventionDate);

  const operatorsData = pipe(
    operators,
    O.fold(
      () => [],
      A.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      })),
    ),
  );

  const handleWindForceChange = (value: `${Intervention.WindForce}` | null) => value && setValue('windForce', +value);

  const windForceWarning: O.Option<[string, MantineColor]> = pipe(
    O.fromNullable(windForce),
    O.filter(force => force >= 3),
    O.map(force => (force === 3 ? ['Éviter les pulvérisations', 'orange'] : ['Ne pas traiter', 'red'])),
  );

  return (
    <>
      <Stack spacing="lg">
        <Stack spacing="xs">
          <SimpleGrid cols={2}>
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePickerInput
                  {...field}
                  error={!!errors.date}
                  value={parseDate(field.value, DateFormat.LocalDate, null)}
                  placeholder="Sélectionner"
                  onChange={handleDateChange}
                  label="Date"
                  required
                  clearable={false}
                />
              )}
            />
            <Stack>
              <Controller
                control={control}
                name="windForce"
                render={({ field }) => (
                  <Select
                    {...field}
                    value={`${field.value}`}
                    onChange={handleWindForceChange}
                    error={!!errors.windForce}
                    data={getSelectDataFromEnumLabels(Intervention.windForceLabel)}
                    label={
                      <Group spacing={4} noWrap>
                        <Text lh={1}>Force du vent</Text>
                        <ActionIcon onClick={handlers.open} color="green.7" size={24}>
                          <IconInfoCircle size={24} />
                        </ActionIcon>
                      </Group>
                    }
                    styles={{ label: { paddingBottom: 4 } }}
                    placeholder="Sélectionner"
                    clearable
                  />
                )}
              />
              {renderOptional(windForceWarning, ([message, color]) => (
                <CustomAlert title="Attention" color={color}>
                  {message}
                </CustomAlert>
              ))}
            </Stack>
          </SimpleGrid>
          <Controller
            control={control}
            name="operatorId"
            render={({ field }) => (
              <Select
                {...field}
                error={!!errors.operatorId}
                data={operatorsData}
                label="Opérateur"
                placeholder="Sélectionner"
                searchable
                disabled={!interventionDate}
                required
              />
            )}
          />
        </Stack>
        <InterventionEquipments />
        <Stack spacing="xs">
          <Group spacing="xs" pb="xs">
            <ThemeIcon color="blue.2" variant="light" bg="transparent">
              <IconMessagePlus />
            </ThemeIcon>
            <Title size="h3">Informations supplémentaires</Title>
          </Group>
          <TextInput
            {...register('otherMethods')}
            error={!!errors.otherMethods}
            label="Autres méthodes utilisées"
            placeholder="Saisir"
          />
          <Textarea {...register('comments')} error={!!errors.comments} label="Commentaire" placeholder="Saisir" />
        </Stack>
      </Stack>
      <Modal opened={modalOpen} onClose={handlers.close} centered>
        <Center pb="lg">
          <Title>Force du vent</Title>
        </Center>
        <Image src={beaufortImage} />
      </Modal>
    </>
  );
};

export default AmmInterventionContextForm;

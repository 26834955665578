import React, { FC } from 'react';
import InterventionRecapCard from '@modules/interventions/components/steps/InterventionRecapCard';
import AmmInterventionDosageForm from '@modules/interventions/components/form/dosage/AmmInterventionDosageForm';
import { AmmInput } from '@modules/inputs/amm/model';
import { Box } from '@mantine/core';

interface CreateAmmInterventionThirdStepProps {
  input: AmmInput;
}

const CreateAmmInterventionThirdStep: FC<CreateAmmInterventionThirdStepProps> = ({ input }) => {
  const name = input.tradeLicence ? input.tradeLicence.name : input.name;

  return (
    <>
      <InterventionRecapCard type={input.ammType} dose={input.dose} doseUnit={input.doseUnit} name={name} />
      <Box px="lg" pt="lg">
        <AmmInterventionDosageForm dose={input.dose} doseUnit={input.doseUnit} />
      </Box>
    </>
  );
};

export default CreateAmmInterventionThirdStep;

import { createStyles } from '@mantine/styles';

// src/mantine-core/src/Spoiler/Spoiler.styles.ts
export default createStyles(theme => ({
  control: {
    padding: `${theme.spacing.md}px 0 ${theme.spacing.xs}px`,
    color: theme.colors.dark[3],
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    textDecoration: 'underline',
  },

  root: {
    position: 'relative',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    p: {
      display: 'inline',
    },
  },
}));

import React, { FC } from 'react';
import { Select, SimpleGrid, Stack, Text } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import * as O from 'fp-ts/Option';
import { formatDate, parseDate } from '@shared/modules/dates';
import { sequenceT } from 'fp-ts/Apply';
import { Intervention } from '@modules/interventions/model';
import SearchFilter from '@shared/modules/filter/components/search/SearchFilter';
import { Input } from '@modules/inputs/model';
import { getSelectDataFromEnumLabels } from '@shared/utils/enum';

interface InterventionListFilterProps {
  filter: Intervention.Range.Filter;
  onFilter: (filter: Intervention.Range.Filter) => void;
}

const InterventionListFilter: FC<InterventionListFilterProps> = ({ filter, onFilter }) => {
  const dateRange = O.toUndefined(sequenceT(O.Apply)(parseDate(filter.startDate), parseDate(filter.endDate)));
  const handleDateRangeChange = ([startDate, endDate]: DatesRangeValue) =>
    onFilter({
      ...filter,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    });

  const handleInputTypeChange = (inputType: Input.Type | null) =>
    onFilter({
      ...filter,
      inputType,
    });

  const handleNameChange = (search: string | null) => onFilter({ ...filter, search });

  return (
    <Stack spacing="xs">
      <Text size="sm" lh={1} opacity={0.6} weight={500}>
        Filtrer par
      </Text>
      <DatePickerInput
        type="range"
        value={dateRange}
        onChange={handleDateRangeChange}
        placeholder="Date / période"
        clearable
      />
      <SimpleGrid cols={2} spacing="xs">
        <SearchFilter onChange={handleNameChange} rightSection={undefined} placeholder="Nom de l'intrant" />
        <Select
          value={filter.inputType}
          data={getSelectDataFromEnumLabels(Input.typeLabel)}
          onChange={handleInputTypeChange}
          placeholder="Type de l'intrant"
          clearable
        />
      </SimpleGrid>
    </Stack>
  );
};

export default InterventionListFilter;

import { Newtype } from 'newtype-ts';

export enum DateFormat {
  LocalDate = 'dd/MM/yyyy',
  LocalDateTime = 'dd/MM/yyyy HH:mm',
  LocalTime = 'HH:mm',
  Year = 'yyyy',
}

export type LocalDate = Newtype<{ readonly LOCAL_DATE: unique symbol }, string> & string;
export type LocalDateTime = Newtype<{ readonly LOCAL_DATE_TIME: unique symbol }, string> & string;
export type LocalTime = Newtype<{ readonly LOCAL_TIME: unique symbol }, string> & string;
export type Year = Newtype<{ readonly YEAR: unique symbol }, string> & string;

export interface DateTypes {
  [DateFormat.LocalDate]: LocalDate;
  [DateFormat.LocalDateTime]: LocalDateTime;
  [DateFormat.LocalTime]: LocalTime;
  [DateFormat.Year]: Year;
}

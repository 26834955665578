import { useParentLoader } from '@core/router/loader';
import profileLoaderRoute from '@modules/auth/loader';
import { CompanyUserRole } from '@modules/auth/model';

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>('profile-loader');
}

export function useHasRole(...roles: Array<CompanyUserRole>) {
  const { role } = useProfile();
  return roles.includes(role);
}

import React, { FC } from 'react';
import { Intervention } from '@modules/interventions/model';
import { Badge, Box, Title } from '@mantine/core';
import { Input } from '@modules/inputs/model';

interface InterventionDetailHeaderProps {
  intervention: Intervention;
}

const InterventionDetailHeader: FC<InterventionDetailHeaderProps> = ({ intervention }) => {
  return (
    <Box bg="white" p={20}>
      <Title size="h2">{intervention.input.name}</Title>

      <Badge mt={10} radius={4} bg="gray.2" c="dark.8" w="fit-content" fw={400} tt="none">
        {Input.typeLabel[intervention.input.type]}
      </Badge>
    </Box>
  );
};

export default InterventionDetailHeader;

import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { parseQueriesFormUrl, stringifyQueries } from '@shared/utils/queries';
import * as InterventionsService from '@modules/interventions/service';
import { getPageQuery } from '@shared/modules/range/utils';
import { useRange, VirtualizedList } from '@shared/modules/range';
import { identity } from 'fp-ts/function';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { SharedButtonLink } from '@styles/shared';
import { Stack, Text, ThemeIcon } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { isFilterEmpty } from '@shared/modules/filter';
import { interventionRangeFilterParser } from '@modules/interventions/utils';
import { Intervention, InterventionFromQuery } from '@modules/interventions/model';
import InterventionListFilter from '@modules/interventions/components/list/filter/InterventionListFilter';
import InterventionListRow from '@modules/interventions/components/list/row/InterventionListRow';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return httpTaskToResponseTask(
      InterventionsService.getInterventionsRange(getPageQuery(queries), interventionRangeFilterParser(queries)),
    );
  },
});

const InterventionsListPage: FC = () => {
  const { range, handleLoadPage, handleFilter } = useRange<
    typeof loader,
    Intervention.Range.Item,
    Intervention.Range.Filter
  >(identity);

  const pageProps: PageProps = {
    seoTitle: 'Vos interventions',
    back: { title: 'Vos interventions', to: '/inputs' },
    bottomBar: (
      <SharedButtonLink
        to={{ pathname: '/inputs/search', search: stringifyQueries({ from: InterventionFromQuery.Create }) }}
        btnType="create"
      >
        Ajouter une intervention
      </SharedButtonLink>
    ),
  };

  const isEmptyRange = range.total === 0 && isFilterEmpty(range.filter);

  return (
    <Page {...pageProps} bg="background.1" withNav>
      <Stack>
        {!isEmptyRange && <InterventionListFilter filter={range.filter} onFilter={handleFilter} />}
        <VirtualizedList
          range={range}
          loadPage={handleLoadPage}
          emptyRender={
            <Stack align="center" ta="center" spacing="lg">
              <ThemeIcon size={95} color="transparent" c="blue.2">
                <IconEdit size="100%" />
              </ThemeIcon>
              <Text weight={500}>
                {isEmptyRange ? 'Vous n’avez aucune intervention actuellement' : "Aucun résultat n'a été trouvé"}
              </Text>
            </Stack>
          }
        >
          {InterventionListRow}
        </VirtualizedList>
      </Stack>
    </Page>
  );
};

const interventionsListRoute = defineRoute({
  component: InterventionsListPage,
  loader,
});

export default interventionsListRoute;

import React, { FC, useMemo } from 'react';
import { Center, Modal, Title } from '@mantine/core';
import { SharedButton } from '@styles/shared';
import { useDisclosure } from '@mantine/hooks';
import { Intervention } from '@modules/interventions/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateInterventionContextSchema } from '@modules/interventions/schema';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { getContextFormBodyFromDetail } from '@modules/interventions/utils';
import UpdateInterventionContextForm from '@modules/interventions/components/form/context/UpdateInterventionContextForm';
import * as TE from 'fp-ts/TaskEither';

interface UpdateInterventionContextModalProps {
  intervention: Intervention;
  onUpdate: (params: Intervention.Update.FormBody.Context) => TE.TaskEither<unknown, unknown>;
  loading: boolean;
}

const UpdateInterventionContextModal: FC<UpdateInterventionContextModalProps> = ({
  intervention,
  onUpdate,
  loading,
}) => {
  const { formRef, form, handleFormSubmit } = useEnhancedForm<Intervention.Update.FormBody.Context>({
    resolver: zodResolver(updateInterventionContextSchema),
    defaultValues: useMemo(() => getContextFormBodyFromDetail(intervention), [intervention]),
  });

  const [opened, handlers] = useDisclosure(false);

  return (
    <>
      <Center my="md">
        <SharedButton onClick={handlers.open} loading={loading}>
          Modifier
        </SharedButton>
      </Center>
      <Modal title={<Title>Contexte</Title>} opened={opened} onClose={handlers.close} centered>
        <EnhancedForm ref={formRef} form={form} onSubmit={onUpdate}>
          <UpdateInterventionContextForm intervention={intervention} />
        </EnhancedForm>

        <Center mt={15}>
          <SharedButton onClick={handleFormSubmit} loading={loading}>
            Enregistrer
          </SharedButton>
        </Center>
      </Modal>
    </>
  );
};

export default UpdateInterventionContextModal;

import { Stack } from '@mantine/core';
import styled from '@emotion/styled';

const MAX_SCREEN_WIDTH = 1080;

export const LayoutContainer = styled.div`
  position: relative;
  height: 100%;

  @media screen and (min-width: ${MAX_SCREEN_WIDTH}px) {
    display: none;
  }
`;

export const LayoutContent = styled.div`
  position: relative;
  height: 100%;
`;

export const LayoutLock = styled(Stack)`
  display: none;
  height: 100%;
  text-align: center;
  justify-content: center;

  @media screen and (min-width: ${MAX_SCREEN_WIDTH}px) {
    display: flex;
  }
`;

import { Input } from '@modules/inputs/model';
import { DeepPartial } from 'react-hook-form';
import { Intervention } from '@modules/interventions/model';
import { Zone } from '@modules/zones/model';
import { InputContext } from '@modules/inputs/components/context/model';
import { Loader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { newTypeWrap } from '@shared/schemas';
import * as S from 'fp-ts/string';
import { FilterQueryParser, getEnumQuery, getSearchQuery, getStringQuery } from '@shared/modules/filter';
import { treatmentUnitsloader } from '@modules/referentials/loaders';
import TreatmentType = Intervention.TreatmentType;
import TreatmentUnit = Intervention.TreatmentUnit;
import Type = Zone.Type;
import { formatDate } from '@shared/modules/dates';
import * as D from 'fp-ts/Date';

type UnitsType = typeof treatmentUnitsloader extends Loader<any, infer R, any> ? R : never;

export function getDoseUnitFromType(
  { areaUnits, otherUnits }: UnitsType,
  doseUnit: TreatmentUnit | null | undefined,
  type: TreatmentType,
) {
  return pipe(
    O.fromNullable(doseUnit),
    O.chain(doseUnit =>
      pipe(
        type === TreatmentType.Zone ? areaUnits : otherUnits,
        A.findFirst(unit => unit === doseUnit),
      ),
    ),
    O.getOrElse(() => newTypeWrap('')),
  );
}

export function getTreatmentIntialValue(
  { areaUnits, otherUnits }: UnitsType,
  doseUnit: TreatmentUnit | null | undefined,
) {
  return pipe(
    O.fromNullable(doseUnit),
    O.chainNullableK(doseUnit =>
      A.elem(S.Eq)(doseUnit)(areaUnits)
        ? TreatmentType.Zone
        : A.elem(S.Eq)(doseUnit)(otherUnits)
        ? TreatmentType.Volume
        : null,
    ),
    O.getOrElse(() => TreatmentType.Zone),
  );
}

export function getInitialValues(
  context: InputContext | null,
  treatmentType: TreatmentType,
  doseUnit?: TreatmentUnit | null,
): DeepPartial<Intervention.Create.FormBody> {
  if (context === null) {
    return {};
  }

  const common: ReturnType<typeof getInitialValues> = {
    targetedPest: null,
    triggeringFactor: null,
    dosage: {
      treatmentType,
      zoneId: '',
      area: {
        zoneAreaTypes: [],
      },
    },
    treatment: {
      mushVolumeValue: null,
      unit: doseUnit ?? '',
    },
    date: formatDate(D.create()),
    operatorId: '',
    equipments: [],
    comments: null,
  };

  switch (context.params.source) {
    case Input.Source.AMM:
      return {
        ...common,
        type: context.params.source,
        input: {
          inputNameId: context.detail.nameId,
          tradeLicenceId: context.detail.tradeLicence?.id ?? null,
          usageId: context.params.usage.usageId,
        },
      };

    case Input.Source.Custom:
      return {
        ...common,
        type: context.params.source,
      };
  }
}

export function mapInterventionFormBodyToParams(body: Intervention.Create.FormBody): Intervention.Create.Params {
  const { dosage, ...rest } = body;

  const dosageIntersection =
    dosage.treatmentType === TreatmentType.Zone
      ? {
          ...dosage,
          area: undefined,
          zoneAreaTypes: dosage.area.type === Type.NoGame ? [dosage.area.zoneAreaType] : dosage.area.zoneAreaTypes,
          volumeValue: null,
        }
      : {
          ...dosage,
          zoneId: null,
          zoneAreaTypes: [],
          totalZoneAreaValue: null,
        };

  return {
    ...rest,
    ...dosageIntersection,
  };
}

export const interventionRangeFilterParser: FilterQueryParser<Intervention.Range.Filter> = query => ({
  search: getSearchQuery(query),
  startDate: getStringQuery(query, 'startDate'),
  endDate: getStringQuery(query, 'endDate'),
  inputType: getEnumQuery(query, Input.Type, 'inputType'),
});

export function getUpdateParamsFromDetail(intervention: Intervention): Intervention.Update.Params {
  const common = {
    targetedPest: intervention.targetedPest,
    triggeringFactor: intervention.triggeringFactor,
    date: intervention.date,
    type: intervention.treatmentType,
    zoneId: intervention.zone?.id ?? null,
    volumeValue: intervention.volumeValue,
    treatment: intervention.treatment,
    comments: intervention.comments,
    equipments: intervention.equipments,
    operatorId: intervention.operator.id,
    totalZoneAreaValue: intervention.totalZoneAreaValue,
    zoneAreaTypes: intervention.zoneAreaTypes,
  };

  switch (intervention.type) {
    case Input.Source.Custom:
      return {
        ...common,
        otherMethods: null,
        windForce: null,
      };
    case Input.Source.AMM:
      return {
        ...common,
        otherMethods: intervention.otherMethods,
        windForce: intervention.windForce,
      };
  }
}

export function getUsageFormBodyFromDetail(intervention: Intervention): Intervention.Update.FormBody.Usage {
  return {
    triggeringFactor: intervention.triggeringFactor,
    targetedPest: intervention.targetedPest,
  };
}

export function getDosageFormBodyFromDetail(
  intervention: Intervention,
): DeepPartial<Intervention.Update.FormBody.Dosage> {
  function getDosageArea() {
    switch (intervention.zone?.type) {
      case Zone.Type.Game:
        return {
          type: intervention.zone.type,
          zoneId: intervention.zone.id,
          zoneAreaTypes: intervention.zoneAreaTypes as Array<Zone.Area.GameType>,
        };
      case Zone.Type.NoGame:
        return {
          type: intervention.zone.type,
          zoneId: intervention.zone.id,
          zoneAreaType: intervention.zone.area.type,
        };
      default:
        return {};
    }
  }

  return {
    dosage: {
      treatmentType: intervention.treatmentType,
      zoneId: intervention.zone?.id,
      volumeValue: intervention.volumeValue ?? undefined,
      totalZoneAreaValue: intervention.totalZoneAreaValue ?? undefined,
      area: getDosageArea(),
    },
    treatment: {
      unit: intervention.treatment.unit,
      mushVolumeValue: intervention.treatment.mushVolumeValue,
      doseValue: intervention.treatment.doseValue,
    },
  };
}

export function getContextFormBodyFromDetail(intervention: Intervention): Intervention.Update.FormBody.Context {
  return {
    equipments: intervention.equipments,
    operatorId: intervention.operator.id,
    comments: intervention.comments,
    date: intervention.date,
    ...(intervention.type === Input.Source.AMM
      ? {
          windForce: intervention.windForce,
          otherMethods: intervention.otherMethods,
        }
      : {
          windForce: null,
          otherMethods: null,
        }),
  };
}

export function getUpdateFormBodyFromDetail(intervention: Intervention): DeepPartial<Intervention.Update.FormBody> {
  return {
    ...getUsageFormBodyFromDetail(intervention),
    ...getDosageFormBodyFromDetail(intervention),
    ...getContextFormBodyFromDetail(intervention),
  };
}

export function getDosageParamsFromFormBody(
  body: Intervention.Update.FormBody.Dosage,
): Pick<
  Intervention.Update.Params,
  'treatment' | 'type' | 'zoneId' | 'zoneAreaTypes' | 'totalZoneAreaValue' | 'volumeValue'
> {
  if (body.dosage.treatmentType === TreatmentType.Zone) {
    return {
      treatment: body.treatment,
      type: body.dosage.treatmentType,
      zoneId: body.dosage.zoneId,
      zoneAreaTypes:
        body.dosage.area.type === Type.Game ? body.dosage.area.zoneAreaTypes : [body.dosage.area.zoneAreaType],
      totalZoneAreaValue: body.dosage.totalZoneAreaValue,
      volumeValue: null,
    };
  } else {
    return {
      treatment: body.treatment,
      type: body.dosage.treatmentType,
      zoneId: null,
      volumeValue: body.dosage.volumeValue,
      zoneAreaTypes: [],
      totalZoneAreaValue: null,
    };
  }
}

export function getUpdateParamsFromFromBody({
  targetedPest,
  triggeringFactor,
  date,
  windForce,
  operatorId,
  equipments,
  otherMethods,
  comments,
  ...body
}: Intervention.Update.FormBody): Intervention.Update.Params {
  return {
    ...getDosageParamsFromFormBody(body),
    targetedPest,
    triggeringFactor,
    date,
    windForce,
    operatorId,
    equipments,
    otherMethods,
    comments,
  };
}

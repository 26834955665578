import { HttpRange, httpService, HttpTask } from '@core/http';
import { Input } from '@modules/inputs/model';
import { Intervention } from '@modules/interventions/model';
import { LocalDate } from '@shared/modules/dates';
import { Operator } from '@modules/operators/model';

const URI = '/interventions';

export function createIntervention(id: Input.Id, params: Intervention.Create.Params): HttpTask<Intervention> {
  return httpService.post(`${URI}/new/${id}`, params);
}

export function simulateInterventionTreatment(
  params: Intervention.SimulateTreatment.Params,
): HttpTask<Intervention.SimulateTreatment.Result> {
  return httpService.post(`${URI}/simulate-treatment`, params);
}

export function getInterventionOperators(id: Intervention.Id, interventionDate: LocalDate): HttpTask<Array<Operator>> {
  return httpService.get(`${URI}/${id}/operators`, { params: { interventionDate } });
}

export function getInterventionInputOperators(
  inputId: Input.Id,
  interventionDate: LocalDate,
): HttpTask<Array<Operator>> {
  return httpService.get(`${URI}/operators`, { params: { inputId, interventionDate } });
}

export function getInterventionsRange(
  page: number,
  filter: Intervention.Range.Filter,
): HttpRange<Intervention.Range.Item, Intervention.Range.Filter> {
  return httpService.getRange(URI, page, filter);
}

export function getIntervention(id: Intervention.Id): HttpTask<Intervention> {
  return httpService.get(`${URI}/${id}`);
}

export function updateIntervention(id: Intervention.Id, params: Intervention.Update.Params): HttpTask<Intervention> {
  return httpService.post(`${URI}/${id}`, params);
}

export function duplicateIntervention(id: Intervention.Id, params: Intervention.Update.Params): HttpTask<Intervention> {
  return httpService.post(`${URI}/${id}/duplicate`, params);
}

export function deleteIntervention(id: Intervention.Id): HttpTask<Intervention> {
  return httpService.delete(`${URI}/${id}`);
}

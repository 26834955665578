import React, { FC } from 'react';
import { Group, Select, Stack, Textarea, ThemeIcon, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconMessagePlus } from '@tabler/icons-react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { Controller } from 'react-hook-form';
import { newTypeWrap } from '@shared/schemas';
import * as S from 'fp-ts/string';
import { useFetchTaskOption } from '@core/http/hooks';
import * as OperatorsService from '@modules/operators/service';
import { useDebouncedState } from '@mantine/hooks';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import InterventionEquipments from '@modules/interventions/components/steps/4/InterventionEquipments';
import { Intervention } from '@modules/interventions/model';

const CustomInterventionContextForm: FC = () => {
  const [search, setSearch] = useDebouncedState<string | null>(null, 400);
  const {
    control,
    formState: { errors },
    setValue,
    register,
  } = useEnhancedFormContext<Intervention.Create.FormBody>();

  const handleDateChange = (value: Date | null) =>
    setValue('date', formatDate(value, DateFormat.LocalDate, newTypeWrap('')));

  const handleSetSearch = (query: string) => setSearch(S.isEmpty(query) ? null : query);

  const [operators] = useFetchTaskOption(OperatorsService.searchOperator, search);

  const operatorsData = pipe(
    operators,
    O.fold(
      () => [],
      A.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      })),
    ),
  );

  return (
    <Stack spacing="lg">
      <Stack spacing="xs">
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DatePickerInput
              {...field}
              error={!!errors.date}
              value={parseDate(field.value, DateFormat.LocalDate, null)}
              placeholder="Sélectionner"
              onChange={handleDateChange}
              label="Date"
              required
            />
          )}
        />
        <Controller
          control={control}
          name="operatorId"
          render={({ field }) => (
            <Select
              {...field}
              error={!!errors.operatorId}
              data={operatorsData}
              label="Opérateur"
              placeholder="Sélectionner"
              onSearchChange={handleSetSearch}
              searchable
              required
            />
          )}
        />
      </Stack>
      <InterventionEquipments />
      <Stack spacing="xs">
        <Group spacing="xs" pb="xs">
          <ThemeIcon color="blue.2" variant="light" bg="transparent">
            <IconMessagePlus />
          </ThemeIcon>
          <Title size="h3">Informations supplémentaires</Title>
        </Group>
        <Textarea {...register('comments')} error={!!errors.comments} label="Commentaire" placeholder="Saisir" />
      </Stack>
    </Stack>
  );
};

export default CustomInterventionContextForm;

import { Intervention } from '@modules/interventions/model';
import React, { FC, useCallback } from 'react';
import { Input } from '@modules/inputs/model';
import AmmInterventionContextForm from '@modules/interventions/components/form/context/AmmInterventionContextForm';
import CustomInterventionContextForm from '@modules/interventions/components/form/context/CustomInterventionContextForm';
import { Stack } from '@mantine/core';
import { LocalDate } from '@shared/modules/dates';
import * as InterventionsService from '@modules/interventions/service';

interface UpdateInterventionContextFormProps {
  intervention: Intervention;
}

const UpdateInterventionContextForm: FC<UpdateInterventionContextFormProps> = ({ intervention }) => {
  const getOperators = useCallback(
    (interventionDate: LocalDate) => InterventionsService.getInterventionOperators(intervention.id, interventionDate),
    [intervention.id],
  );

  return (
    <Stack>
      {intervention.type === Input.Source.AMM ? (
        <AmmInterventionContextForm getOperators={getOperators} />
      ) : (
        <CustomInterventionContextForm />
      )}
    </Stack>
  );
};

export default UpdateInterventionContextForm;

import React, { FC } from 'react';
import { VirtualizedListChildrenProps } from '@shared/modules/range';
import { Badge, Card, DefaultMantineColor, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconDashboard } from '@tabler/icons-react';
import { Water } from '@modules/water/model';
import { Link } from 'react-router-dom';
import MeterStateLabel from '@modules/water/meters/components/state/MeterStateLabel';
import Meter = Water.Meter;

const iconColor: Record<Meter.State, DefaultMantineColor> = {
  [Meter.State.Disabled]: 'gray.6',
  [Meter.State.Enabled]: 'blue.9',
};

const MeterListRow: FC<VirtualizedListChildrenProps<Meter.Range.Item>> = ({ item }) => {
  return (
    <Card component={Link} to={item.id} withBorder={false} shadow="0 5px 5px 0 rgba(0, 0, 0, 0.03)">
      <Stack spacing="xs">
        <Group position="apart">
          <Group spacing="xs">
            <ThemeIcon variant="light" color={iconColor[item.activationState]} size={22}>
              <IconDashboard size={14} />
            </ThemeIcon>
            <Text size="sm">{item.serialNumber}</Text>
          </Group>
          <MeterStateLabel activationState={item.activationState} />
        </Group>
        <Title size="h3">{item.name}</Title>
        <Badge color="gray" w="fit-content" fw={400} tt="none">
          {Meter.sourceTypeLabel[item.sourceType]}
        </Badge>
      </Stack>
    </Card>
  );
};

export default MeterListRow;

import React, { FC, Fragment, PropsWithChildren } from 'react';
import Page from '@layout/page/Page';
import { getDetailPageProps } from '@modules/inputs/utils';
import { Button, Card, Group, Text, Title } from '@mantine/core';
import { renderNullable, renderOptional } from '@shared/utils/render';
import AmmInputInfos from '@modules/inputs/amm/components/detail/AmmInputInfos';
import AmmInputDanger from '@modules/inputs/amm/components/detail/AmmInputDanger';
import * as NEA from 'fp-ts/NonEmptyArray';
import AmmInputRisk from '@modules/inputs/amm/components/detail/AmmInputRisk';
import { IconLink } from '@tabler/icons-react';
import LineClampSpoiler from '@shared/components/spoiler/LineClampSpoiler';
import { AmmInput } from '@modules/inputs/amm/model';
import { useParentLoader } from '@core/router/loader';
import { queryFromloader } from '@modules/inputs/components/context/InputLayout';

interface InputAmmContentProps {
  input: AmmInput;
}

const AmmInputContent: FC<PropsWithChildren<InputAmmContentProps>> = ({ input: baseInput, children }) => {
  const from = useParentLoader<typeof queryFromloader>('query-from');

  const input = baseInput.tradeLicence
    ? {
        ...baseInput,
        secondaryNames: null,
        name: baseInput.tradeLicence.name,
        owner: baseInput.tradeLicence.owner,
        firstAuthorizationDate: null,
      }
    : baseInput;

  return (
    <Page {...getDetailPageProps(input, from)} bg="background.1">
      {renderNullable(input.secondaryNames, names => (
        <Card p="xs" bg="background.1" radius={4} mb={20}>
          <Text size="xs" weight={500} opacity={0.6}>
            Commercialisé sous les noms de
          </Text>
          <Text size="xs" opacity={0.6}>
            {names}
          </Text>
        </Card>
      ))}
      <AmmInputInfos input={input} />
      {children}
      {renderOptional(NEA.fromArray(input.hazardPhrases), hazardPhrases => (
        <AmmInputDanger hazardPhrases={hazardPhrases} />
      ))}

      {renderOptional(NEA.fromArray(input.riskPhrases), riskPhrases => (
        <AmmInputRisk riskPhrases={riskPhrases} />
      ))}

      {renderOptional(NEA.fromArray(input.epis), epis => (
        <>
          <Title size="h3" pt={30} pb="xs">
            Protection de l'opérateur
          </Title>
          <Card>
            <LineClampSpoiler lineClamp={9}>
              {epis.map((epi, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  <Text>{epi}</Text>
                </Fragment>
              ))}
            </LineClampSpoiler>
          </Card>
        </>
      ))}

      {renderNullable(input.quickFdsLink, quickFdsLink => (
        <Group pt="lg" position="left">
          <Button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={quickFdsLink}
            leftIcon={<IconLink size={20} />}
            variant="light"
            color="blue.8"
          >
            Lire la fiche FDS
          </Button>
        </Group>
      ))}
    </Page>
  );
};

export default AmmInputContent;

import { httpService, HttpTask } from '@core/http';
import { EmergencyNumber } from '@modules/referentials/model';
import { Zone } from '@modules/zones/model';
import { Intervention } from '@modules/interventions/model';

const URI = '/referentials';

export function getEmergencyNumbers(): HttpTask<Array<EmergencyNumber>> {
  return httpService.get(`${URI}/emergency-numbers`);
}

export function getZoneTypes(): HttpTask<Array<Zone.Type>> {
  return httpService.get(`${URI}/zone/types`);
}

export function getZoneAreasTypes(): HttpTask<Array<Zone.Area.Type>> {
  return httpService.get(`${URI}/zone-areas/types`);
}

export function getAreaTreatmentUnits(): HttpTask<Array<Intervention.TreatmentUnit>> {
  return httpService.get(`${URI}/treatment/units/area`);
}

export function getOtherTreatmentUnits(): HttpTask<Array<Intervention.TreatmentUnit>> {
  return httpService.get(`${URI}/treatment/units/other`);
}

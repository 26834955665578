import React, { FC, PropsWithChildren } from 'react';
import { Group, Select, Stack, Tabs, Text, ThemeIcon, Title } from '@mantine/core';
import { Intervention } from '@modules/interventions/model';
import { IconAsterisk, IconBorderHorizontal } from '@tabler/icons-react';
import InterventionZoneTreatment from '@modules/interventions/components/form/dosage/InterventionZoneTreatment';
import InterventionVolumeTreatment from '@modules/interventions/components/form/dosage/InterventionVolumeTreatment';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Controller } from 'react-hook-form';
import { useParentLoader } from '@core/router/loader';
import TreatmentType = Intervention.TreatmentType;
import { getDoseUnitFromType } from '@modules/interventions/utils';
import { treatmentUnitsloader } from '@modules/referentials/loaders';

interface InterventionDosageFormProps {
  doseUnit?: Intervention.TreatmentUnit | null;
  oldTotalZoneAreaValue?: number | null;
}

export const InputUnitDescription: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text color="dark.3" display="flex" sx={{ justifyContent: 'end' }} pt={2}>
      {children}
    </Text>
  );
};

const InterventionDosageForm: FC<PropsWithChildren<InterventionDosageFormProps>> = ({
  children,
  doseUnit,
  oldTotalZoneAreaValue,
}) => {
  const units = useParentLoader<typeof treatmentUnitsloader>('treatment-units');

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useEnhancedFormContext<Intervention.Update.FormBody.Dosage>();

  const type = watch('dosage.treatmentType');

  const handleTabChange = (type: TreatmentType) => {
    setValue('treatment.unit', getDoseUnitFromType(units, doseUnit, type));
    setValue('dosage.treatmentType', type);
  };

  return (
    <Stack spacing="xl">
      <Tabs value={type} onTabChange={handleTabChange}>
        <Tabs.List mb={20}>
          <Tabs.Tab value={TreatmentType.Zone}>{Intervention.treatmentTypeLabel[TreatmentType.Zone]}</Tabs.Tab>
          <Tabs.Tab value={TreatmentType.Volume}>{Intervention.treatmentTypeLabel[TreatmentType.Volume]}</Tabs.Tab>
        </Tabs.List>
        <Stack spacing="xs">
          <Group spacing="xs" pb="xs">
            <ThemeIcon color="blue.2" variant="light" bg="transparent">
              <IconBorderHorizontal />
            </ThemeIcon>
            <Title size="h3">Zone d'entretien</Title>
          </Group>
          <Tabs.Panel value={TreatmentType.Zone}>
            <InterventionZoneTreatment oldTotalZoneAreaValue={oldTotalZoneAreaValue} />
          </Tabs.Panel>
          <Tabs.Panel value={TreatmentType.Volume}>
            <InterventionVolumeTreatment />
          </Tabs.Panel>
        </Stack>
      </Tabs>
      <Stack spacing="xs">
        <Group spacing="xs" pb="xs">
          <ThemeIcon color="blue.2" variant="light" bg="transparent">
            <IconAsterisk />
          </ThemeIcon>
          <Title size="h3">Traitement</Title>
        </Group>
        <Controller
          control={control}
          name="treatment.unit"
          render={({ field }) => (
            <Select
              {...field}
              error={!!errors.treatment?.unit}
              data={(type === TreatmentType.Zone ? units.areaUnits : units.otherUnits).map(unit => ({
                value: unit,
                label: unit,
              }))}
              label="Unité de dose"
              placeholder="Sélectionner"
              required
            />
          )}
        />
        {children}
      </Stack>
    </Stack>
  );
};

export default InterventionDosageForm;

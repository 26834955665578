import React, { FC } from 'react';
import { Intervention } from '@modules/interventions/model';
import InputSimpleInfo from '@modules/inputs/components/InputSimpleInfo';
import { renderNullable } from '@shared/utils/render';
import { identity } from 'fp-ts/function';
import { constPlaceholder } from '@modules/inputs/amm/utils';

interface InterventionInputDetailProps {
  input: Intervention.AmmInput;
}

const InterventionInputDetail: FC<InterventionInputDetailProps> = ({ input }) => {
  return (
    <>
      <InputSimpleInfo title="Culture">{input.crop}</InputSimpleInfo>
      <InputSimpleInfo title="Bioagresseur">{renderNullable(input.pest, identity, constPlaceholder)}</InputSimpleInfo>
      <InputSimpleInfo title="Unité de dosage max">
        {renderNullable(input.unit, identity, constPlaceholder)}
      </InputSimpleInfo>
      <InputSimpleInfo title="Délai de rentrée">{input.waitingPeriod}</InputSimpleInfo>
      <InputSimpleInfo title="Fonction">{renderNullable(input.functions, identity, constPlaceholder)}</InputSimpleInfo>
    </>
  );
};

export default InterventionInputDetail;

import * as NEA from 'fp-ts/NonEmptyArray';
import { Input } from '@modules/inputs/model';
import React, { FC } from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { OpenData } from '@shared/modules/opendata/model';
import { Select } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import { useEnhancedFormContext } from '@shared/modules/form';
import { InputFormBody, NonMfscParams } from '@modules/inputs/search/model';
import { placeholder } from '@modules/inputs/amm/utils';

interface NonMsfcUsageFormProps {
  crops: Array<Input.View.Crop>;
  params: NonMfscParams;
}

type FormContext = InputFormBody & {
  params: NonMfscParams;
};

const NonMsfcUsageForm: FC<NonMsfcUsageFormProps> = ({ crops, params }) => {
  const { setValue } = useEnhancedFormContext<FormContext>();

  const handleCropChange = (name: string | null) => {
    const crop = pipe(
      crops,
      A.findFirst(crop => crop.name === name),
      O.toNullable,
    );

    if (crop) setValue('params.crop', crop);
  };

  const handlePestChange = (pests: Array<Input.View.Pest>) => (name: string | null) => {
    const pest = pipe(
      pests,
      A.findFirst(pest => pest.name === name),
      O.toNullable,
    );

    if (pest) setValue('params.pest', pest);
  };

  const handleUsageChange = (usages: Array<Input.View.Usage>) => (usageId: OpenData.ProductUsageId | null) => {
    const usage = pipe(
      usages,
      A.findFirst(usage => usage.usageId === usageId),
      O.toNullable,
    );

    if (usage) setValue('params.usage', usage);
  };

  return (
    <>
      <Select
        value={params.crop.name}
        onChange={handleCropChange}
        label="Culture"
        placeholder="Sélectionner"
        data={crops.map(({ name }) => ({ value: name, label: name }))}
        required
      />
      {renderOptional(NEA.fromArray(params.crop.pests), pests => (
        <Select
          value={params.pest.name}
          label="Bioagresseur"
          placeholder="Sélectionner"
          data={pests.map(({ name }) => ({ value: name, label: name }))}
          onChange={handlePestChange(pests)}
          required
        />
      ))}
      {renderOptional(NEA.fromArray(params.pest.usages), usages => (
        <Select
          value={params.usage.usageId}
          label="Unité de dosage max"
          placeholder="Sélectionner"
          data={usages.map(({ unit, usageId }) => ({ value: usageId, label: unit ?? placeholder }))}
          onChange={handleUsageChange(usages)}
          required
        />
      ))}
    </>
  );
};

export default NonMsfcUsageForm;

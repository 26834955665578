import { Route } from 'react-router-dom';
import React from 'react';
import { createRoute } from '@core/router';
import interventionsListRoute from '@modules/interventions/ListPage';
import interventionDetailRoute from '@modules/interventions/DetailPage';
import interventionDuplicateRoute from '@modules/interventions/DuplicatePage';
import InterventionSuccessPage from '@modules/interventions/SuccessPage';

const interventionRoutes = (
  <Route path="interventions">
    <Route index {...createRoute(interventionsListRoute)} />
    <Route path=":id" {...createRoute(interventionDetailRoute)} />
    <Route path=":id/duplicate" {...createRoute(interventionDuplicateRoute)} />
    <Route path="success" element={<InterventionSuccessPage />} />
  </Route>
);

export default interventionRoutes;

import React, { FC } from 'react';
import { VirtualizedListChildrenProps } from '@shared/modules/range';
import { Badge, Card, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconEdit } from '@tabler/icons-react';
import { renderOptional } from '@shared/utils/render';
import { formatDate, parseDate } from '@shared/modules/dates';
import { Intervention } from '@modules/interventions/model';
import { Input } from '@modules/inputs/model';

const InterventionListRow: FC<VirtualizedListChildrenProps<Intervention.Range.Item>> = ({ item }) => {
  return (
    <Card component={Link} to={item.id} withBorder={false} shadow="0 5px 5px 0 rgba(0, 0, 0, 0.03)">
      <Stack spacing="xs">
        <Group spacing="xs">
          <ThemeIcon variant="light" color="blue.9" size={22}>
            <IconEdit size={14} />
          </ThemeIcon>
          <Text size="sm">{Input.typeLabel[item.inputType]}</Text>
        </Group>
        <Title size="h3">{item.name}</Title>

        {renderOptional(parseDate(item.date), date => (
          <Badge color="gray" w="fit-content" fw={400} tt="none">
            {formatDate(date)}
          </Badge>
        ))}
      </Stack>
    </Card>
  );
};

export default InterventionListRow;

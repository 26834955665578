import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import { getQueryValue } from '@shared/modules/filter';
import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import SuccessPage from '@shared/components/success-page/SuccessPage';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);
    return httpTaskToResponseTask(
      TE.fromOption(() => HttpError.notFound)(
        sequenceS(O.Apply)({
          firstName: getQueryValue<string>(queries, 'firstName'),
          lastName: getQueryValue<string>(queries, 'lastName'),
        }),
      ),
    );
  },
});
const OperatorSuccessPage: FC = () => {
  const { firstName, lastName } = useLoader<typeof loader>();

  return (
    <SuccessPage
      description={` L’opérateur ${lastName} ${firstName} a bien été ajouté, vous pouvez désormais le consulter depuis la liste de
          vos opérateurs.`}
      to="/operators"
    >
      Consulter les opérateurs
    </SuccessPage>
  );
};

const operatorSuccessRoute = defineRoute({
  component: OperatorSuccessPage,
  loader,
});
export default operatorSuccessRoute;
